/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-this-in-sfc */
import React from "react";
import { useTheme } from "react-jss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import highchartsMore from "highcharts/highcharts-more";
import { InfoOutlined } from "@material-ui/icons";
import useOptions from "../../../../hooks/options";
import useStyles from "../styles";
import CustomTooltip from "../../../CustomTooltip";

highchartsMore(Highcharts);

export const generateColor = (index, total) => {
  const baseColor = [0, 155, 255];
  const factor = (total - index) / total;

  const minLightenFactor = 0.2;
  const adjustedFactor = Math.max(minLightenFactor, factor);

  const lighten = (color) => Math.floor(color + (255 - color) * adjustedFactor);

  return `rgb(${lighten(baseColor[0])}, ${lighten(baseColor[1])}, ${lighten(
    baseColor[2]
  )})`;
};

/**
 * This component is a stylized chart to display stacked area data.
 */
export default function LineWithDifferentThickness({
  title,
  info,
  data,
  legendInitialTime,
  legendEndTime,
}) {
  LineWithDifferentThickness.defaultProps = {
    data: undefined,
  };

  LineWithDifferentThickness.propTypes = {
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    legendInitialTime: PropTypes.number.isRequired,
    legendEndTime: PropTypes.number.isRequired,
    data: PropTypes.shape(),
  };

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const defaultOptions = useOptions();
  const { t } = useTranslation();
  const classes = useStyles();

  const seriesData = data.series
    .map((yearData, index) => ({
      name: `Superfície d'água mensal de ${yearData.year}`,
      data: yearData.data.map((d) => d.area),
      marker: {
        enabled: false,
        symbol: "circle",
      },
      color:
        index === 0
          ? "rgb(202, 233, 252)"
          : index === data.series.length - 1
          ? "rgba(0,108,255,1)"
          : generateColor(index, data.series.length),

      lineWidth: index === data.series.length - 1 ? 4 : 0.8,
      showInLegend: index === data.series.length - 1,
    }))

    .concat({
      name: `Superfície d'água média mensal de ${legendInitialTime} a ${legendEndTime}`,
      data: data.average.map((d) => d.area),
      marker: {
        enabled: false,
        symbol: "circle",
      },
      color: "#ffca1d",
      lineWidth: 4,
      showInLegend: true,
    })
    .concat({
      name: `Superfície d'água mensal de ${data.lastYear.year}`,
      data: data.lastYear.data.map((d) => d.area),
      marker: {
        enabled: false,
        symbol: "circle",
      },
      color: "#371F76",
      lineWidth: 4,
      showInLegend: true,
    });

  const options = {
    chart: {
      type: "line",
      ...defaultOptions.chart,
      height: 360,
    },
    credits: defaultOptions.credits,
    title: {
      text: title,
      ...defaultOptions.title,
    },
    xAxis: {
      ...defaultOptions.xAxis,
      labels: {
        style: {
          fontSize: "10px",
          color: theme.text.secondary,
          ...defaultOptions.yAxis.labels.style,
        },
        formatter() {
          return t("general.date.month", {
            date: new Date(this.value),
          });
        },
      },
      categories: data.average.map((m) => m.month.toString()),
    },
    yAxis: defaultOptions.yAxis,

    tooltip: {
      useHTML: true,
      formatter() {
        return `<b>${t("general.date.month", {
          date: new Date(this.point.category),
        })}</b> </br>
          <tr><td style="color: ${this.series.color}">
          ${this.series.name}: </td>
            <td style="text-align: right"><b>${t("general.roundNumber", {
              value: this.point.y,
            })} ha
          </b></td></tr>`;
      },
    },
    legend: {
      ...defaultOptions.legend,
      enabled: true,
      x: -40,
      symbolHeight: 0,
      symbolWidth: 0,
      symbolPadding: 0,
      labelFormatter() {
        return `<div style="display: flex; margin-top: 10px;">
                  <span style="background-color: ${this.color}; width: 40px; height: 4px; "></span>
                  <span style="margin-left: -40px; padding-top: 6px;" >${this.name}</span>
                </div>`;
      },
      useHTML: true,
    },
    series: seriesData,
    navigation: defaultOptions.navigation,
    lang: defaultOptions.lang,
    exporting: {
      ...defaultOptions.exporting,
      chartOptions: {
        chart: {
          events: null,
          style: {
            backgroundColor: theme.background.primary,
          },
        },
      },
    },
  };

  return (
    <div id="container" className={classes.wrapper}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className={classes.tooltip}>
        <CustomTooltip title={info} placement="bottom">
          <InfoOutlined
            style={{
              color: theme.text.primary,
              fontSize: "15px",
            }}
          />
        </CustomTooltip>
      </div>
      <div className={classes.legenda}>
        <p className={classes.texto}>{legendInitialTime}</p>
        <p className={classes.texto}>{legendEndTime}</p>
      </div>
    </div>
  );
}
