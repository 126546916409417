import axios from "axios";

/**
 * Create an Axios instance.
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

/**
 * Create an Axios instance to GE api.
 */
export const geApi = axios.create({
  baseURL: process.env.REACT_APP_GE_API_URL,
});

export default api;
