import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useMediaQuery, Fade, Paper } from "@material-ui/core";
import CustomTooltip from "../../../CustomTooltip";
import SvgSettings from "../../../Icons/Settings";
import useStyles from "./styles";
import { breakpoints } from "../../../../constants/constraints";

export default function OptionsButton({
  parentClasses,
  actived,
  activedIcon,
  onClick,
  options,
  children,
  open,
  setOpen,
  closeAllSuboptions,
}) {
  OptionsButton.defaultProps = {
    activedIcon: undefined,
    options: undefined,
    children: undefined,
  };

  OptionsButton.propTypes = {
    parentClasses: PropTypes.shape().isRequired,
    actived: PropTypes.bool.isRequired,
    activedIcon: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    closeAllSuboptions: PropTypes.func.isRequired,
  };

  const { t } = useTranslation();
  const classes = useStyles();
  const isSmd = useMediaQuery(breakpoints.max.smd);

  function handleClick() {
    if (isSmd) {
      onClick();
      if (!actived) {
        setOpen(!open);
        closeAllSuboptions();
      }
    } else if (actived) {
      onClick();
    } else {
      setOpen(!open);
      closeAllSuboptions();
    }
  }

  return (
    <div className={classes.container}>
      <Fade in={open}>
        <Paper elevation={0}>
          <div className={classes.wrapper}>{options}</div>
        </Paper>
      </Fade>
      {children}
      <CustomTooltip
        title={
          open
            ? ""
            : actived
            ? t("dashboard.map.cancelSelection")
            : t("dashboard.map.interactionOptionsTooltip")
        }
        placement="right"
      >
        <div
          role="button"
          className={classNames(
            parentClasses.selectionButton,
            parentClasses.clickableButton,
            actived
              ? parentClasses.clickButtonContainerActived
              : parentClasses.clickButtonContainer
          )}
          onClick={handleClick}
          onKeyDown={handleClick}
          tabIndex={0}
        >
          {activedIcon}
          {!actived && (
            <SvgSettings
              className={parentClasses.navigationIcon}
              style={{
                transform: "scale(0.9)",
                display: "flex",
              }}
            />
          )}
        </div>
      </CustomTooltip>
    </div>
  );
}
