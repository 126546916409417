import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  wrapper: {
    width: "385px",
    position: "relative",
    marginBottom: 32,

    [breakpoints.max.smd]: {
      width: "100%",
    },
  },
  tooltip: { position: "absolute", right: 30, top: 5, cursor: "pointer" },
  pagination: {
    display: "flex",
    justifyContent: "center",
    "& .MuiPaginationItem-root": {
      color: theme.primary,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: theme.primary,
      color: theme.background.primary,
    },
    "& .MuiPaginationItem-page.Mui-selected:hover": {
      backgroundColor: theme.primary,
    },
  },
  legenda: {
    position: "absolute",
    bottom: "34px",
    right: "40px",
    width: "14px",
    height: "40px",
    background:
      "linear-gradient(to bottom, rgb(0, 255, 255), rgb(0, 155, 255))",
    border: "1px solid #aaa",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
    color: theme.text.secondary,
    marginLeft: "25px",
    marginTop: "-10px",
  },
  texto: {
    marginLeft: "16px",
    marginTop: "-3px",
  },
}));

export default useStyles;
