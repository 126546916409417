import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "@material-ui/core";
import classNames from "classnames";
import { useTheme } from "react-jss";
import useStyles from "./styles";
import CustomTypography from "../../../CustomTypography";
import SvgNext from "../../../Icons/Next";
import { breakpoints } from "../../../../constants/constraints";

export default function OptionButton({
  title,
  icon,
  onClick,
  actived,
  options,
  accordionMode,
  checked,
  checkable,
}) {
  OptionButton.defaultProps = {
    actived: false,
    options: undefined,
    accordionMode: false,
    checkable: false,
    checked: false,
  };

  OptionButton.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    actived: PropTypes.bool,
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    accordionMode: PropTypes.bool,
    checkable: PropTypes.bool,
    checked: PropTypes.bool,
  };

  const classes = useStyles();
  const isSmd = useMediaQuery(breakpoints.max.smd);
  const theme = useTheme();

  return (
    <>
      <div
        role="button"
        onClick={onClick}
        onKeyDown={onClick}
        tabIndex={0}
        className={
          actived
            ? classNames(classes.container, classes.actived)
            : classes.container
        }
      >
        {isSmd ? (
          <>
            <div className={classes.titleWrapper}>
              {options && (
                <SvgNext
                  className={classes.icon}
                  style={isSmd && actived ? { transform: "rotate(90deg)" } : {}}
                />
              )}
              <CustomTypography
                variant="body"
                style={{ marginLeft: options ? 10 : 0, minWidth: 140 }}
              >
                {title}
              </CustomTypography>
            </div>
            <div className={classes.iconWrapper}>{icon}</div>
          </>
        ) : (
          <>
            <div className={classes.iconWrapper}>{icon}</div>
            <div className={classes.titleWrapper}>
              <CustomTypography variant="body" style={{ marginRight: 10 }}>
                {title}
              </CustomTypography>
              {checkable && (
                <div
                  className={classes.checkContainer}
                  style={
                    checked
                      ? {
                          justifyContent: "flex-end",
                        }
                      : {
                          backgroundColor: theme.text.tertiary,
                        }
                  }
                >
                  <div className={classes.checkMark} />
                </div>
              )}
              {options && (
                <SvgNext
                  className={classes.icon}
                  style={
                    accordionMode && actived
                      ? { transform: "rotate(90deg)" }
                      : {}
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
      <div
        className={accordionMode ? "" : classes.optionsWrapper}
        style={
          actived
            ? { maxHeight: 400 }
            : { transform: "scaleY(0)", maxHeight: 0 }
        }
      >
        {options}
      </div>
    </>
  );
}
