import { useMemo } from "react";

/* eslint-disable prefer-destructuring */
export default function useMonths(initialTime, endTime) {
  return useMemo(() => {
    if (initialTime && endTime) {
      let monthInitialTime = initialTime;
      let monthEndTime = endTime;

      if (initialTime.includes("-")) {
        monthInitialTime = initialTime.split("-")[0];
      }

      if (endTime.includes("-")) {
        monthEndTime = endTime.split("-")[0];

        if (
          initialTime.split("-")[1] === endTime.split("-")[1] &&
          monthInitialTime === monthEndTime
        ) {
          return [monthInitialTime];
        }
        return [monthInitialTime, monthEndTime];
      }
    }
    return null;
  }, [initialTime, endTime]);
}
