import * as React from "react";

function SvgHouse(props) {
  return (
    <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="14.5" y="4" width="1" height="2.5" rx="0.5" fill="black" />
      <path
        d="M3.5 7.5V15.5C3.5 16 3.825 17 5.125 17C6.425 17 12.1667 17 14.875 17C15.4167 17 16.5 16.7 16.5 15.5C16.5 14.3 16.5 9.5 16.5 7.5"
        stroke="black"
      />
      <path
        d="M2.5 8L8.3359 4.1094C9.3436 3.4376 10.6564 3.4376 11.6641 4.1094L17.5 8"
        stroke="black"
      />
      <circle cx="17.5" cy="8" r="0.5" fill="black" />
      <circle cx="2.5" cy="8" r="0.5" fill="black" />
      <path
        d="M8 17V13C8 11.8954 8.89543 11 10 11V11C11.1046 11 12 11.8954 12 13V17"
        stroke="black"
      />
    </svg>
  );
}

export default SvgHouse;
