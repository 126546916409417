/* eslint-disable consistent-return */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTheme } from "react-jss";
import { Button, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";
import useStyles from "./styles";
import CustomTypography from "../../CustomTypography";
import SvgNext from "../../Icons/Next";
import { breakpoints } from "../../../constants/constraints";
import FiltersContext from "../../../contexts/filtering";

function StatisticsContainer({ children, refFilters, refContainer }) {
  StatisticsContainer.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape()),
      PropTypes.shape(),
    ]).isRequired,
    refFilters: PropTypes.shape().isRequired,
    refContainer: PropTypes.shape().isRequired,
  };

  const {
    values: { statisticsOpened, filtersDesktopOpened },
    setters: { setStatisticsOpened },
  } = useContext(FiltersContext);
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();
  const isSmd = useMediaQuery(breakpoints.max.smd);
  const refStatistics = useRef();
  const [onCollision, setOnCollision] = useState(false);

  /**
   * This function handle colision with filters component.
   */
  function handleColision() {
    const rect1 =
      refFilters.current instanceof Element
        ? refFilters.current.getBoundingClientRect()
        : false;
    const rect2 =
      refStatistics.current instanceof Element
        ? refStatistics.current.getBoundingClientRect()
        : false;

    if (!rect1 || !rect2) {
      return;
    }

    const collision = !(
      rect1.right + 20 < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom + 20 < rect2.top ||
      rect1.top > rect2.bottom
    );

    if (filtersDesktopOpened) {
      setOnCollision(collision);
    } else {
      setOnCollision(false);
    }
  }

  /**
   * This useEffect call handle colision when the page is resized.
   */
  useEffect(() => {
    window.addEventListener("resize", handleColision);
  }, []);

  /**
   * This useEffect call handle colision when collapsable elements are modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    setTimeout(() => {
      if (isSubscribed) handleColision();
    }, 300);

    return () => {
      isSubscribed = false;
    };
  }, [statisticsOpened, filtersDesktopOpened]);

  /**
   * This function handle when statistics bar is opened or closed.
   */
  const handleOpenBar = () => {
    setStatisticsOpened(!statisticsOpened);
    if (isSmd) {
      if (statisticsOpened) {
        const y =
          refStatistics.current.getBoundingClientRect().top +
          refContainer.current.scrollTop -
          240;
        refContainer.current.scrollTo({ top: y, behavior: "smooth" });
      } else {
        refContainer.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  };

  /**
   * This useEffect changes state of statistics bar when page is scrolled.
   */
  useEffect(() => {
    refContainer.current.addEventListener("scroll", () => {
      if (isSmd) {
        setStatisticsOpened(!refContainer.current.scrollTop > 0);
      }
    });
  }, [refContainer.current]);

  return (
    <div
      ref={refStatistics}
      className={
        isSmd
          ? statisticsOpened
            ? classNames(classes.container)
            : classNames(classes.container)
          : statisticsOpened
          ? onCollision
            ? classNames(classes.container, classes.collide)
            : classNames(classes.container)
          : onCollision
          ? classNames(classes.container, classes.colapse, classes.collide)
          : classNames(classes.container, classes.colapse)
      }
    >
      <div
        role="button"
        onClick={handleOpenBar}
        onKeyDown={handleOpenBar}
        tabIndex={0}
        className={classes.statisticsHeader}
      >
        <CustomTypography variant="title">
          {t("dashboard.statistics.title")}
        </CustomTypography>{" "}
        <Button className={classes.nextButton} onClick={handleOpenBar}>
          <SvgNext
            className={classes.nextIcon}
            style={
              isSmd
                ? {
                    transform: statisticsOpened
                      ? "rotate(270deg)"
                      : "rotate(90deg)",
                  }
                : {
                    transform: statisticsOpened
                      ? "rotate(0deg)"
                      : "rotate(180deg)",
                  }
            }
          />
        </Button>
      </div>

      <div
        className={
          isSmd
            ? statisticsOpened
              ? classNames(classes.childrenContainer)
              : classNames(classes.childrenContainer)
            : statisticsOpened
            ? classNames(classes.childrenContainer)
            : classNames(classes.childrenContainer, classes.hideBorder)
        }
      >
        <div className={classes.children}>{children}</div>
      </div>
    </div>
  );
}

export default StatisticsContainer;
