import { createUseStyles } from "react-jss";
import {
  breakpoints,
  sizes,
  transitions,
  styles,
} from "../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    padding: "12px 24px",
    borderBottom: `1px solid ${theme.stroke}`,
    backgroundColor: theme.background.primary,
    transition: transitions.background,
    display: "flex",
    alignItems: "center",
    height: sizes.header.height,
    maxWidth: "100%",
    zIndex: 100,

    [breakpoints.max.smd]: {
      zIndex: 0,
    },
  },
  logo: {
    height: 32,
    width: 154,
    marginRight: 26,
    userSelect: "none",

    "@media (max-width: 270px)": {
      height: 32,
      width: 50,
      objectFit: "cover",
      objectPosition: 0,
    },
  },
  buttonsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",

    [breakpoints.max.sm]: {
      justifyContent: "flex-end",
    },
  },
  buttonsSection: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    ...styles(theme).button,
  },
  activedButton: {
    ...styles(theme).button,

    "&::after": {
      position: "absolute",
      bottom: "-12px",
      content: '""',
      width: "100%",
      height: "1px",
      backgroundColor: theme.primary,
    },
  },
  buttonText: {
    textTransform: "none",
  },
  separator: {
    width: 1,
    height: 24,
    backgroundColor: theme.header.stroke,
    margin: "0px 16px",
  },
  icon: {
    height: 16,
    marginTop: -16,
  },
}));

export default useStyles;
