/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  wrapper: {
    position: "absolute",
    pointerEvents: "all",
    backgroundColor: theme.background.primary,
    left: 266,
    bottom: 0,
    borderRadius: "4px 4px 4px 0px",
    boxShadow: theme.floatingShadow,
    zIndex: 31000,

    [breakpoints.max.smd]: {
      left: "initial",
      right: 45,
      bottom: 16,
      display: "none",
    },
  },
  title: {
    padding: 12,
    textTransform: "uppercase",
  },
}));

export default useStyles;
