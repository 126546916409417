/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import ChartLegend from "../../../../../components/Dashboard/Charts/ChartLegend";
import Pie from "../../../../../components/Dashboard/Charts/Pie";
import Ranking from "../../../../../components/Dashboard/Charts/Ranking";
import StackedArea from "../../../../../components/Dashboard/Charts/StackedArea";
import FiltersContext from "../../../../../contexts/filtering";
import api from "../../../../../services/api";

export default function WaterSurfaceClassificationStatistics() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { values } = useContext(FiltersContext);

  const [pieData, setPieData] = useState();
  const [stackedAreaData, setStackedAreaData] = useState();
  const [rankingData, setRankingData] = useState();
  const [rankingTotalPages, setRankingTotalPages] = useState();
  const [rankingPage, setRankingPage] = useState(1);
  const [rankingOrder, setRankingOrder] = useState(true);

  useEffect(() => {
    if (pieData) {
      setPieData((prev) =>
        prev.map((obj) => ({
          ...obj,
          visible: values.filtersWaterClassification[obj.id],
        }))
      );
    }
    if (stackedAreaData) {
      setStackedAreaData((prev) => {
        prev.series = prev.series.map((obj) => ({
          ...obj,
          visible: values.filtersWaterClassification[obj.id],
        }));
        return prev;
      });
    }
  }, [values.filtersWaterClassification]);

  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/classification/areas`, {
        params: {
          territory: values.territory.type,
          territoryCode: values.territory.code,
          years: values.years,
          grouping: values.grouping,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          data.map((obj) => {
            obj.visible = values.filtersWaterClassification[obj.name];
            obj.color = theme.waterClassification[obj.name];
            obj.y = obj.data;
            delete obj.data;
            obj.id = obj.name;
            obj.name = t(
              `dashboard.statistics.charts.waterClassification.${obj.name}`
            );
            return obj;
          });
          setPieData(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.years,
    values.grouping,
    t,
  ]);

  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/classification/time-series`, {
        params: {
          territory: values.territory.type,
          territoryCode: values.territory.code,
          years: values.years,
          grouping: values.grouping,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          data.series = data.series.map((obj) => {
            obj.visible = values.filtersWaterClassification[obj.name];
            obj.color = theme.waterClassification[obj.name];
            obj.id = obj.name;
            obj.name = t(
              `dashboard.statistics.charts.waterClassification.${obj.name}`
            );
            return obj;
          });
          setStackedAreaData(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.years,
    values.grouping,
    t,
  ]);

  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/classification/ranking`, {
        params: {
          territory: values.territory.type,
          territoryCode: values.territory.code,
          grouping: values.grouping,
          years: values.years,
          order: rankingOrder ? "desc" : "asc",
          activeElements: Object.keys(values.filtersWaterClassification).filter(
            (key) => values.filtersWaterClassification[key] === true
          ),
          page: 1,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          setRankingTotalPages(data.pageAmount);
          data.series = data.series.map((obj) => {
            obj.visible = values.filtersWaterClassification[obj.name];
            obj.color = theme.waterClassification[obj.name];
            obj.id = obj.name;
            obj.name = t(
              `dashboard.statistics.charts.waterClassification.${obj.name}`
            );
            return obj;
          });
          setRankingData(data);
          setRankingPage(1);
          if (data.territoryPage > 0) setRankingPage(data.territoryPage);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.code,
    values.territory.type,
    values.grouping,
    values.timeMode,
    values.years,
    values.months,
    values.filtersWaterClassification,
    rankingOrder,
    t,
  ]);

  /**
   * This userEffect gets new ranking data every time the ranking page is modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/classification/ranking`, {
        params: {
          territory: values.territory.type,
          territoryCode: values.territory.code,
          grouping: values.grouping,
          years: values.years,
          order: rankingOrder ? "desc" : "asc",
          activeElements: Object.keys(values.filtersWaterClassification).filter(
            (key) => values.filtersWaterClassification[key] === true
          ),
          page: rankingPage,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          data.series = data.series.map((obj) => {
            obj.visible = values.filtersWaterClassification[obj.name];
            obj.color = theme.waterClassification[obj.name];
            obj.id = obj.name;
            obj.name = t(
              `dashboard.statistics.charts.waterClassification.${obj.name}`
            );
            return obj;
          });
          setRankingData(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [rankingPage]);

  return useMemo(
    () => (
      <>
        {pieData && (
          <Pie
            data={pieData}
            analysis={t("dashboard.statistics.charts.waterSurface.title")}
            title={`${t(
              "dashboard.statistics.charts.waterClassification.generalStatistics.title"
            )} - ${values.territory.name}`}
            info={t(
              "dashboard.statistics.charts.waterClassification.generalStatistics.info"
            )}
          />
        )}
        {pieData && <ChartLegend data={pieData} />}
        {stackedAreaData && (
          <StackedArea
            data={stackedAreaData}
            title={`${t(
              "dashboard.statistics.charts.waterClassification.series.title"
            )} - ${values.territory.name}`}
            info={t(
              "dashboard.statistics.charts.waterClassification.series.info"
            )}
          />
        )}
        {rankingData && (
          <Ranking
            title={t(
              "dashboard.statistics.charts.waterClassification.ranking.title"
            )}
            info={t(
              "dashboard.statistics.charts.waterClassification.ranking.info"
            )}
            data={rankingData}
            totalPages={rankingTotalPages}
            page={rankingPage}
            setRankingPage={setRankingPage}
            rankingOrder={rankingOrder}
            setRankingOrder={setRankingOrder}
          />
        )}
      </>
    ),
    [
      pieData,
      stackedAreaData,
      rankingData,
      rankingPage,
      values.filtersWaterClassification,
      values.territory.name,
      t,
    ]
  );
}
