import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { easeCubic } from "d3-ease";
import { FlyToInterpolator } from "react-map-gl";
import useStyles from "./styles";
import useStylesParent from "../styles";
import SvgSearch from "../../../Icons/Search";
import SvgTarget from "../../../Icons/Target";

/**
 * This function renders a component that handles the map coordinates.
 * @returns The location component.
 */
function Location({ lat, lng, setValues }) {
  Location.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    setValues: PropTypes.func.isRequired,
  };

  const classes = useStyles();
  const classesParent = useStylesParent();
  const collapseRef = useRef();
  const wrapperRef = useRef();
  const [open, setOpen] = useState(true);

  /**
   * This function switches the collapse bar.
   */
  function handleOpenBar() {
    setOpen(!open);
  }

  /**
   * This function gets the current user location.
   */
  function handleLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setValues({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          transitionDuration: 1500,
          transitionInterpolator: new FlyToInterpolator(),
          transitionEasing: easeCubic,
          zoom: 9,
        });
      });
    }
  }

  return (
    <div
      className={classNames(classesParent.navigationWrapper, classes.container)}
    >
      <div
        role="button"
        className={classNames(
          classesParent.navigationButton,
          classes.locationButton
        )}
        onClick={handleOpenBar}
        onKeyDown={() => {}}
        style={{ borderRadius: open ? "4px 0px 0px 4px" : 4 }}
        tabIndex={-1}
      >
        <SvgSearch className={classesParent.navigationIcon} />
      </div>
      <div
        ref={collapseRef}
        style={{ width: open ? 152 : 0 }}
        className={classes.collapse}
      >
        <div ref={wrapperRef} className={classes.wrapper}>
          {lat && lng && (
            <div className={classes.values}>{`${lat.toFixed(3)}, ${lng.toFixed(
              3
            )}`}</div>
          )}
          <div
            role="button"
            className={classNames(
              classesParent.navigationButton,
              classes.locationButton,
              classes.targetButton
            )}
            onClick={handleLocation}
            onKeyDown={handleLocation}
            style={{ borderRadius: open ? "0px 4px 4px 0px" : 4 }}
            tabIndex={-1}
          >
            <SvgTarget
              className={classesParent.navigationIcon}
              style={{ transform: "scale(1.1)" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Location;
