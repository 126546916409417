import * as React from "react";

function SvgBack(props) {
  return (
    <svg
      width={8}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.566 1.224a1.06 1.06 0 01-.199 1.224l-4.46 4.46 4.46 4.46a1.06 1.06 0 11-1.5 1.5L.615 7.614a1 1 0 010-1.414L5.867.948a1.06 1.06 0 011.699.276z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgBack;
