import { createUseStyles } from "react-jss";
import { styles } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    padding: "8px 0px",
    borderBottom: `1px solid ${theme.stroke}`,
  },
  button: {
    "&.MuiButton-root": {
      minWidth: "100%",
      padding: "12px 0px",
      ...styles(theme).button,
    },
  },
  arrowIcon: {
    transform: "scale(1.3)",
    marginRight: 15,

    "& path": {
      fill: theme.grey,
    },
  },
  wrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
}));

export default useStyles;
