import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../CustomTypography";
import SvgDownArrow from "../../Icons/DownArrow";
import SvgLanguage from "../../Icons/Language";
import useStyles from "./styles";

/* Gets all the available languages at the platform */
const availableLanguages = i18next.options.resources;

/**
 * This functional component renders a button that allows the platform language changing.
 * @returns The translation button.
 */
function TranslationButton() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [translationMenuAnchor, setTranslationMenuAnchor] = useState(null);

  /**
   * This function opens the translation menu.
   * @param {Click} event
   */
  function handleTranslationMenuClick(event) {
    setTranslationMenuAnchor(event.currentTarget);
  }

  /**
   * This function closes the translation menu
   */
  function handleTranslationMenuClose() {
    setTranslationMenuAnchor(null);
  }

  return (
    <>
      <Menu
        id="translation-menu"
        anchorEl={translationMenuAnchor}
        open={Boolean(translationMenuAnchor)}
        onClose={handleTranslationMenuClose}
        PopoverClasses={{ root: classes.menuPopOver }}
      >
        {Object.keys(availableLanguages).map((language) => (
          <MenuItem
            key={language}
            onClick={() => {
              i18next.changeLanguage(language);
              handleTranslationMenuClose();
            }}
          >
            {availableLanguages[language].translation.general.language}
          </MenuItem>
        ))}
      </Menu>
      <Button
        startIcon={<SvgLanguage className={classes.languageIcon} />}
        endIcon={<SvgDownArrow className={classes.downIcon} />}
        onClick={handleTranslationMenuClick}
        className={classes.button}
      >
        <CustomTypography weight="bold" className={classes.buttonText}>
          {t("general.language")}
        </CustomTypography>
      </Button>
    </>
  );
}

export default TranslationButton;
