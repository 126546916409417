import * as React from "react";

function SvgMapCursor(props) {
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="5" y="1" width="2" height="10" fill="#898989" />
      <rect
        x="4.5"
        y="0.5"
        width="3"
        height="11"
        stroke="white"
        strokeOpacity="0.3"
      />
      <rect
        x="11"
        y="5"
        width="2"
        height="10"
        transform="rotate(90 11 5)"
        fill="#898989"
      />
      <rect
        x="11.5"
        y="4.5"
        width="3"
        height="11"
        transform="rotate(90 11.5 4.5)"
        stroke="white"
        strokeOpacity="0.3"
      />
      <rect x="5" y="4" width="2" height="4" fill="#898989" />
      <rect x="5" y="5" width="2" height="2" fill="#5B5B5B" />
    </svg>
  );
}

export default SvgMapCursor;
