import React, { createContext, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import { FlyToInterpolator } from "react-map-gl";
import { easeCubic } from "d3-ease";
import { defaults } from "../constants/general";

import FiltersContext from "./filtering";

const MapContext = createContext({});

/**
 * The MapProvider is context to provide options to map.
 * */
export function MapProvider({ children }) {
  MapProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  const mapRef = useRef();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [onClick, setOnClick] = useState(null);
  const [onHover, setOnHover] = useState(null);
  const [pointedColor, setPointedColor] = useState({ r: 0, g: 0, b: 0, a: 0 });
  const [cursor, setCursor] = useState("pointer");
  const [hoveringFeatures, setHoveringFeatures] = useState(null);
  const [baseMap, setBaseMap] = useState("default");
  const {
    values: { dataType },
  } = useContext(FiltersContext);
  const [viewport, setViewport] = useState({
    latitude:
      window.innerWidth < 800 ? -13.255 : defaults[dataType].viewport.latitude,
    longitude:
      window.innerWidth < 800 ? -51.664 : defaults[dataType].viewport.longitude,
    zoom: window.innerWidth < 800 ? 2.4 : defaults[dataType].viewport.zoom,
    transitionDuration: 300,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCubic,
  });
  const [opacity, setOpacity] = useState(100);
  const [infoContent, setInfoContent] = useState(null);
  const [animation, setAnimation] = useState(false);
  const [layers, setLayers] = useState({
    irrigation: { enabled: false },
    intermittentWater: { enabled: false },
  });
  const [transparentBackground, setTransparentBackground] = useState(true);

  function resetMapping() {
    setBaseMap("default");
  }

  function updateLayer(layerName, key, value) {
    setLayers((oldLayers) => ({
      ...oldLayers,
      [layerName]: { ...oldLayers[layerName], [key]: value },
    }));
  }

  function addLayers(newLayers) {
    setLayers((oldLayers) => ({
      ...oldLayers,
      ...newLayers,
    }));
  }

  function resetLayers() {
    setLayers({
      irrigation: { enabled: false },
      intermittentWater: { enabled: false },
    });
  }

  return (
    <MapContext.Provider
      value={{
        mapRef,
        mapLoaded,
        setMapLoaded,
        onClick,
        setOnClick,
        onHover,
        setOnHover,
        pointedColor,
        setPointedColor,
        cursor,
        setCursor,
        hoveringFeatures,
        setHoveringFeatures,
        baseMap,
        setBaseMap,
        resetMapping,
        viewport,
        setViewport,
        opacity,
        setOpacity,
        infoContent,
        setInfoContent,
        animation,
        setAnimation,
        layers,
        setLayers,
        updateLayer,
        addLayers,
        resetLayers,
        transparentBackground,
        setTransparentBackground,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

export default MapContext;
