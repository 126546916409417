import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((/* theme */) => ({
  innerWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  line: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    width: "80%",
  },
  infoItem: {
    marginTop: "20px",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  gainOrLossArea: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  subInfoItem: {
    marginTop: "6px",
  },
  arrow: {
    marginRight: 5,
    width: "20px",
    height: "20px",
    objectFit: "cover",
    objectPosition: 5,
  },
}));

export default useStyles;
