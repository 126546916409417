/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@material-ui/core";
import { useTheme } from "react-jss";
import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import useStyles from "./styles";
import CustomTypography from "../../../CustomTypography";
import SvgClose from "../../../Icons/Close";
import { Facebook, X, Whatsapp } from "../../../Icons";

/**
 * This function renders a component that handles the map share page.
 * @returns The share page.
 */
function Share({ close }) {
  Share.propTypes = {
    close: PropTypes.func.isRequired,
  };

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  function handleTextCopy() {
    const el = document.createElement("textarea");
    el.value = window.location.href;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  return (
    <div className={classes.background}>
      <div
        className={classes.exit}
        role="button"
        onClick={() => close()}
        onKeyDown={() => {}}
        tabIndex={0}
      />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.header}>
            <CustomTypography variant="title">
              {t("dashboard.share.title")}
            </CustomTypography>
            <Button className={classes.closeButton} onClick={() => close()}>
              <SvgClose className={classes.closeIcon} />
            </Button>
          </div>
          <div className={classes.content}>
            <CustomTypography variant="body" color={theme.text.gray}>
              {`${t("dashboard.share.description2")}:`}
            </CustomTypography>
          </div>
          <div className={classes.socialNetworksWrapper}>
            <TwitterShareButton
              title={t("dashboard.share.message")}
              url={window.location.href}
            >
              <Button
                className={classes.shareButton}
                style={{ marginRight: 5 }}
              >
                <X className={classes.twitterIcon} />
              </Button>
            </TwitterShareButton>
            <FacebookShareButton
              title={t("dashboard.share.message")}
              url={window.location.href}
              style={{ marginRight: 5 }}
            >
              <Button className={classes.shareButton}>
                <Facebook className={classes.facebookIcon} />
              </Button>
            </FacebookShareButton>
            <WhatsappShareButton
              title={t("dashboard.share.message")}
              url={window.location.href}
            >
              <Button className={classes.shareButton}>
                <Whatsapp className={classes.whatsappIcon} />
              </Button>
            </WhatsappShareButton>
          </div>
          <div className={classes.content}>
            <CustomTypography
              variant="body"
              color={theme.text.gray}
              style={{
                marginTop: 10,
                marginBottom: 30,
              }}
            >
              {`${t("dashboard.share.description")}:`}
            </CustomTypography>
            <TextField
              variant="outlined"
              classes={{ root: classes.textfieldRoot }}
              size="small"
              label={t("dashboard.share.linkTitle")}
              value={window.location.href}
              inputProps={{
                readOnly: true,
              }}
              onFocus={(event) => {
                event.target.select();
              }}
            />
            <div className={classes.copyButtonWrapper}>
              {copied && (
                <CustomTypography
                  variant="body"
                  color={theme.primary}
                  style={{
                    marginBottom: 32,
                  }}
                  className={classes.copiedText}
                >
                  {t("dashboard.share.copied")}
                </CustomTypography>
              )}
              <Button
                className={classes.copyButton}
                variant="contained"
                disableElevation
                color="primary"
                onClick={handleTextCopy}
              >
                <CustomTypography variant="contrast" color={theme.white}>
                  {t("dashboard.share.copyLink")}
                </CustomTypography>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Share;
