import React from "react";
import useStyles from "./styles";
import SvgDownBigArrow from "../../../../Icons/DownBigArrow";

/**
 * The thumb icon to the timeline.
 * @returns The location component.
 */
function Thumb(props) {
  const classes = useStyles();

  return (
    <span className={classes.container} {...props}>
      <SvgDownBigArrow className={classes.icon} />
    </span>
  );
}

export default Thumb;
