import * as React from "react";

function SvgWhatsapp(props) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.403 2.633C14.5714 1.79588 13.5819 1.13211 12.4919 0.680147C11.4019 0.22818 10.233 -0.00300075 9.053 -1.70845e-06C4.105 -1.70845e-06 0.077 4.027 0.075 8.977C0.075 10.559 0.488 12.103 1.273 13.465L0 18.116L4.759 16.867C6.07539 17.5836 7.5502 17.9593 9.049 17.96H9.053C14 17.96 18.028 13.933 18.03 8.983C18.0334 7.80348 17.803 6.63499 17.3521 5.54506C16.9012 4.45513 16.2387 3.46537 15.403 2.633ZM9.053 16.445H9.05C7.71352 16.4451 6.40163 16.0856 5.252 15.404L4.98 15.242L2.156 15.983L2.909 13.23L2.732 12.948C1.98479 11.7584 1.58923 10.3818 1.591 8.977C1.593 4.863 4.94 1.516 9.056 1.516C10.0363 1.51382 11.0072 1.70608 11.9127 2.08165C12.8181 2.45722 13.6401 3.00864 14.331 3.704C15.0256 4.39604 15.5762 5.21889 15.9509 6.125C16.3256 7.0311 16.517 8.00248 16.514 8.983C16.512 13.097 13.165 16.445 9.053 16.445ZM13.146 10.856C12.921 10.743 11.819 10.201 11.613 10.126C11.408 10.051 11.259 10.014 11.109 10.238C10.959 10.462 10.529 10.967 10.398 11.117C10.267 11.267 10.136 11.285 9.912 11.173C9.688 11.061 8.965 10.824 8.108 10.06C7.441 9.465 6.991 8.731 6.86 8.506C6.729 8.281 6.846 8.16 6.959 8.048C7.06 7.948 7.183 7.786 7.295 7.655C7.407 7.524 7.444 7.431 7.519 7.281C7.594 7.131 7.557 7 7.5 6.888C7.444 6.775 6.995 5.671 6.808 5.222C6.627 4.787 6.442 4.845 6.304 4.839C6.16108 4.83315 6.01804 4.83048 5.875 4.831C5.76126 4.8339 5.64934 4.86026 5.54626 4.90845C5.44318 4.95663 5.35117 5.02559 5.276 5.111C5.07 5.336 4.491 5.878 4.491 6.982C4.491 8.086 5.295 9.153 5.407 9.303C5.519 9.453 6.989 11.718 9.239 12.69C9.775 12.921 10.193 13.059 10.518 13.163C11.055 13.334 11.544 13.309 11.931 13.252C12.362 13.188 13.258 12.71 13.445 12.186C13.632 11.662 13.632 11.213 13.576 11.119C13.52 11.025 13.369 10.968 13.146 10.856Z"
        fill="black"
      />
    </svg>
  );
}

export default SvgWhatsapp;
