import { createUseStyles } from "react-jss";
import { breakpoints, sizes } from "../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "fixed",
    width: "100%",
    height: `calc(100% - ${sizes.header.height}px)`,
    overflow: "auto",
  },
  mapContainer: {
    position: "relative",
    width: "100%",
    height: "100%",

    [breakpoints.max.smd]: {
      paddingBottom: sizes.statistiscs.header.height,
    },
  },
  separator: {
    width: 1,
    height: 24,
    margin: "0px 16px",
    backgroundColor: theme.filters.timeline.separator,
  },
}));

export default useStyles;
