import React, { useContext, useEffect, useRef } from "react";
import { FiltersProvider } from "../../contexts/filtering";
import { LocationProvider } from "../../contexts/location";
import Map from "./Content/map";
import FilteringContainer from "../../components/Dashboard/FilteringContainer";
import MapView from "../../components/Dashboard/MapView";
import useStyles from "./styles";
import Legend from "./Content/legend";
import Loader from "./Content/loader";
import { MapProvider } from "../../contexts/mapping";
import Timeline from "./Content/timeline";
import StatisticsContainer from "../../components/Dashboard/StatisticsContainer";
import Statistics from "./Content/Statistics";
import ThemeContext from "../../contexts/theming";

function Glacier() {
  const classes = useStyles();
  const refFilters = useRef();
  const refContainer = useRef();
  const { setThemeMod } = useContext(ThemeContext);

  useEffect(() => {
    setThemeMod("glacier");
  }, []);

  return (
    <LocationProvider dataType="glacier">
      <FiltersProvider>
        <MapProvider>
          <div ref={refContainer} className={classes.container}>
            <FilteringContainer ref={refFilters} />
            <div className={classes.mapContainer}>
              <MapView
                route="glacier"
                legend={<Legend />}
                postLocationChild={<Timeline />}
                loader={<Loader />}
              >
                <Map />
              </MapView>
            </div>
            <StatisticsContainer
              refFilters={refFilters}
              refContainer={refContainer}
            >
              <Statistics />
            </StatisticsContainer>
          </div>
        </MapProvider>
      </FiltersProvider>
    </LocationProvider>
  );
}

export default Glacier;
