import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useStyles from "./styles";
import SvgDownArrow from "../../Icons/DownArrow";
import CustomTypography from "../../CustomTypography";

/**
 * This component is a styled selector to receive the portal filters.
 * @param {Names the filter label} title
 * @param {A list containing the selection options} options
 * @param {Disable the filter} disabled
 * @param {The selected value} value
 * @param {Change the selected value} setValue
 */
function Selector({
  title,
  options,
  disabled,
  value,
  setValue,
  className,
  paperClassName,
  sort,
}) {
  Selector.defaultProps = {
    title: undefined,
    options: undefined,
    value: undefined,
    disabled: false,
    className: "",
    paperClassName: "",
    sort: false,
  };

  Selector.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.any,
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    className: PropTypes.string,
    paperClassName: PropTypes.string,
    sort: PropTypes.bool,
  };

  const classes = useStyles();
  const { t } = useTranslation();

  const sortedOptions = useMemo(() => {
    if (sort && options) {
      const optionsArray = [];

      Object.keys(options).forEach((option) => {
        optionsArray.push({ key: option, value: options[option] });
      });

      optionsArray.sort((a, b) => {
        if (t(a.value.translation) < t(b.value.translation)) {
          return -1;
        }
        if (t(a.value.translation) > t(b.value.translation)) {
          return 1;
        }
        return 0;
      });

      const optionsAux = {};

      optionsArray.forEach((option) => {
        optionsAux[option.key] = option.value;
      });

      return optionsAux;
    }

    return options;
  }, [options, sort, t]);

  if (!value || !options) return null;

  return (
    <FormControl
      size="small"
      variant="outlined"
      className={classNames(classes.formControl, className)}
    >
      {title && (
        <InputLabel className={classes.label} id="select-outlined-label">
          {title}
        </InputLabel>
      )}
      <Select
        labelId="select-outlined-label"
        id="select-outlined"
        value={value}
        onChange={({ target }) => setValue(target.value)}
        label={title}
        className={classes.select}
        MenuProps={{
          PopoverClasses: { root: classes.menuPopOver },
          classes: { paper: paperClassName },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        disabled={disabled}
        IconComponent={() => <SvgDownArrow className={classes.downIcon} />}
      >
        {sortedOptions &&
          Object.keys(sortedOptions).map((option) => (
            <MenuItem
              style={{
                whiteSpace: "normal",
              }}
              key={option}
              value={option}
            >
              {option === "waterClassification" ? (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  {sortedOptions[option].translation
                    ? t(sortedOptions[option].translation)
                    : sortedOptions[option].label}
                  <CustomTypography
                    style={{ color: "red", marginLeft: "5px", fontSize: 10 }}
                    variant="legend"
                  >
                    BETA
                  </CustomTypography>
                </div>
              ) : sortedOptions[option].translation ? (
                <div style={{ display: "flex" }}>
                  {t(sortedOptions[option].translation)}
                  {sortedOptions[option].beta && (
                    <CustomTypography
                      style={{ color: "red", marginLeft: "5px", fontSize: 10 }}
                      variant="legend"
                    >
                      BETA
                    </CustomTypography>
                  )}
                </div>
              ) : (
                sortedOptions[option].label
              )}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default Selector;
