import * as React from "react";

function SvgSearch(props) {
  return (
    <svg
      width={15}
      height={15}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89 1.8a5.09 5.09 0 100 10.18 5.09 5.09 0 000-10.18zm5.265 9.219a6.69 6.69 0 10-1.13 1.132l2.575 2.58a.8.8 0 001.133-1.13l-2.578-2.582z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgSearch;
