import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/constraints";

const useStyles = createUseStyles((/* theme */) => ({
  innerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column nowrap",
  },
  line: {
    display: "flex",
    flexFlow: "row wrap",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  legendItem: {
    cursor: "pointer",
    padding: 10,
    width: 180,
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "space-between",

    [breakpoints.max.sm]: {
      width: 170,
    },
  },
  columnLegendItem: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  circle: {
    minWidth: 20,
    height: 20,
    marginRight: 10,
    borderRadius: "100%",
  },
}));

export default useStyles;
