import * as React from "react";

function SvgFilter(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.167.857V9.36a2.263 2.263 0 000 4.423v1.36a.476.476 0 00.952 0v-1.36a2.263 2.263 0 000-4.423V.857a.476.476 0 00-.952 0zm-.834 10.714a1.31 1.31 0 102.62 0 1.31 1.31 0 00-2.62 0zM13.357.381c.263 0 .476.213.476.476v1.36a2.263 2.263 0 010 4.423v8.503a.476.476 0 01-.952 0V6.64a2.263 2.263 0 010-4.423V.857c0-.263.213-.476.476-.476zm0 5.357a1.31 1.31 0 110-2.619 1.31 1.31 0 010 2.62zM8.477.858a.476.476 0 00-.953 0v4.93a2.263 2.263 0 000 4.424v4.93a.476.476 0 00.952 0v-4.93a2.263 2.263 0 000-4.424V.858zM9.31 8a1.31 1.31 0 10-2.62 0 1.31 1.31 0 002.62 0z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgFilter;
