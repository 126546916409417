/* eslint-disable no-unused-vars */
import React, { createContext } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { defaults } from "../constants/general";

const LocationContext = createContext({});

/**
 * The LoationProvider is a context to generate and process the route params.
 * */
export function LocationProvider({ dataType, children }) {
  LocationProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    dataType: PropTypes.string.isRequired,
  };

  const history = useHistory();
  const params = useParams();

  /**
   * Handles viewport route changing.
   * @param {New map latitude value} lat
   * @param {New map longitude value} lng
   * @param {New map zoom value} zoom
   */
  function handleMapRoute(route, lat, lng, zoom) {
    if (
      params.territoryType &&
      params.territoryCode &&
      params.grouping &&
      params.analysis &&
      params.initialTime &&
      params.endTime
    ) {
      if (params.initiative) {
        history.replace(
          `/${route}/${params.initiative}/${lat}/${lng}/${zoom}/${params.territoryType}/${params.territoryCode}/${params.grouping}/${params.analysis}/${params.initialTime}/${params.endTime}`
        );
      } else {
        history.replace(
          `/${route}/${lat}/${lng}/${zoom}/${params.territoryType}/${params.territoryCode}/${params.grouping}/${params.analysis}/${params.initialTime}/${params.endTime}`
        );
      }
    } else if (params.initiative) {
      history.replace(
        `/${route}/${params.initiative}/${lat}/${lng}/${zoom}/${defaults[dataType].territory.type}/${defaults[dataType].territory.code}/${defaults[dataType].grouping}/${defaults[dataType].analysis}/${defaults[dataType].initialTime}/${defaults[dataType].endTime}`
      );
    } else {
      history.replace(
        `/${route}/${defaults[dataType].initiative}/${lat}/${lng}/${zoom}/${defaults[dataType].territory.type}/${defaults[dataType].territory.code}/${defaults[dataType].grouping}/${defaults[dataType].analysis}/${defaults[dataType].initialTime}/${defaults[dataType].endTime}`
      );
    }
  }

  /**
   * Handles the filtering route changing.
   * @param {New initiative value} initiative
   * @param {New territory value} territory
   * @param {New grouping value} grouping
   * @param {New analysis value} analysis
   * @param {New initial time value} initialTime
   * @param {New end time value} endTime
   */
  function handleFiltersRoute(
    route,
    initiative,
    territory,
    grouping,
    analysis,
    initialTime,
    endTime
  ) {
    if (initiative) {
      if (params.lat && params.lng && params.zoom) {
        history.replace(
          `/${route}/${initiative}/${params.lat}/${params.lng}/${params.zoom}/${territory.type}/${territory.code}/${grouping}/${analysis}/${initialTime}/${endTime}`
        );
      } else {
        history.replace(
          `/${route}/${initiative}/${defaults[dataType].viewport.latitude}/${defaults[dataType].viewport.longitude}/${defaults[dataType].viewport.zoom}/${territory.type}/${territory.code}/${grouping}/${analysis}/${initialTime}/${endTime}`
        );
      }
    } else if (params.lat && params.lng && params.zoom) {
      history.replace(
        `/${route}/${defaults[dataType].initiative}/${params.lat}/${params.lng}/${params.zoom}/${territory.type}/${territory.code}/${grouping}/${analysis}/${initialTime}/${endTime}`
      );
    } else {
      history.replace(
        `/${route}/${defaults[dataType].initiative}/${defaults[dataType].viewport.latitude}/${defaults[dataType].viewport.longitude}/${defaults[dataType].viewport.zoom}/${territory.type}/${territory.code}/${grouping}/${analysis}/${initialTime}/${endTime}`
      );
    }
  }

  return (
    <LocationContext.Provider
      value={{
        handleMapRoute,
        handleFiltersRoute,
        dataType,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
}

export default LocationContext;
