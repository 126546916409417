/* eslint-disable no-unused-vars */
import { useTheme } from "react-jss";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Lines from "../../../../../components/Dashboard/Charts/Lines";
import Column from "../../../../../components/Dashboard/Charts/Column";
import FiltersContext from "../../../../../contexts/filtering";
import api from "../../../../../services/api";
import Scatter from "../../../../../components/Dashboard/Charts/Scatter";

export default function TrendStatistics() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { values } = useContext(FiltersContext);
  const [linesData, setLinesData] = useState();

  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/trend/time-series`, {
        params: {
          territory: values.territory.type,
          territoryCode: values.territory.code,
          years: values.years,
          grouping: values.grouping,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          data.series = data.series.map((obj) => {
            obj.color = theme.trend[obj.name];
            obj.name = t(`dashboard.statistics.charts.trend.lines.${obj.name}`);
            return obj;
          });
          setLinesData(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.years,
    values.grouping,
    t,
  ]);

  const [scatterData, setScatterData] = useState();

  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/trend/full-balance`, {
        params: {
          territory: values.territory.type,
          territoryCode: values.territory.code,
          years: values.years,
          grouping: values.grouping,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          data.series = data.series.map((obj) => {
            obj.color = theme.trend[obj.name];
            obj.name = t(
              `dashboard.statistics.charts.trend.scatter.${obj.name}`
            );
            return obj;
          });
          setScatterData(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.years,
    values.grouping,
    t,
  ]);

  const [columnData, setColumnData] = useState();

  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/trend/balance`, {
        params: {
          territory: values.territory.type,
          territoryCode: values.territory.code,
          years: values.years,
          grouping: values.grouping,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          data.series = data.series.map((obj) => {
            obj.color = theme.trend[obj.name];
            obj.name = t(
              `dashboard.statistics.charts.trend.column.${obj.name}`
            );
            return obj;
          });
          setColumnData(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.grouping,
    values.years,
    t,
  ]);

  return useMemo(
    () => (
      <>
        {linesData && (
          <Lines
            data={linesData}
            title={`${t("dashboard.statistics.charts.trend.lines.title")} - ${
              values.territory.name
            }`}
            info={t("dashboard.statistics.charts.trend.lines.info")}
          />
        )}
        {scatterData && (
          <Scatter
            data={scatterData}
            title={t("dashboard.statistics.charts.trend.scatter.title")}
            info={t("dashboard.statistics.charts.trend.scatter.info")}
          />
        )}

        {columnData && (
          <Column
            data={columnData}
            title={t("dashboard.statistics.charts.trend.column.title")}
            info={t("dashboard.statistics.charts.trend.column.info")}
          />
        )}
      </>
    ),
    [linesData, scatterData, columnData, values.territory.name, t]
  );
}
