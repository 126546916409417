/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useGeneralStyles from "../styles";
import useStyles from "./styles";
import CustomTypography from "../../../CustomTypography";
import FiltersContext from "../../../../contexts/filtering";

export default function ChartLegend({ data }) {
  ChartLegend.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  const {
    setters: { setFiltersWaterClassification },
  } = useContext(FiltersContext);

  const theme = useTheme();
  const generalClasses = useGeneralStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const handleFilters = (id) => {
    setFiltersWaterClassification((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const legendItem = (id, name, value, color, visible) => (
    <div
      key={id}
      role="button"
      tabIndex={0}
      onClick={() => handleFilters(id)}
      onKeyDown={() => handleFilters(id)}
      className={classes.legendItem}
    >
      <div
        className={classes.circle}
        style={{
          backgroundColor: visible ? color : theme.text.legend,
        }}
      />
      <div className={classes.columnLegendItem}>
        <CustomTypography
          style={{
            color: visible ? theme.text.primary : theme.text.legend,
          }}
          variant="substats"
        >
          {name}
        </CustomTypography>
        <CustomTypography
          style={{
            whiteSpace: "nowrap",
            color: visible ? theme.text.primary : theme.text.legend,
          }}
          variant="stats"
        >
          {t("general.roundNumber", { value })} ha
        </CustomTypography>
      </div>
    </div>
  );

  return (
    <div className={generalClasses.wrapper}>
      <div className={classes.innerWrapper}>
        <div className={classes.line}>
          {data.map((item) =>
            legendItem(item.id, item.name, item.y, item.color, item.visible)
          )}
        </div>
      </div>
    </div>
  );
}
