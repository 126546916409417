import { createUseStyles } from "react-jss";
import { styles } from "../../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    padding: "24px 0px",

    "&:not(:last-of-type)": {
      "&::after": {
        content: "''",
        height: 1,
        backgroundColor: theme.stroke,
        position: "absolute",
        left: -16,
        right: -16,
        bottom: 0,
      },
    },
  },
  title: {
    marginBottom: 20,
  },
  wrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: {
    transform: "scale(1.5)",
    marginBottom: 2,
    marginLeft: 5,
    width: 20,

    "& path": {
      fill: theme.text.primary,
    },
  },
  firstWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  dateWrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
    justifyContent: "space-between",
  },
  periodSeparator: {
    color: theme.filters.timeline.calendarSeparator,
    fontWeight: 700,
    fontSize: 20,
    textAlign: "center",
    margin: "0px 5px",
  },
  editButtonWrapper: {
    display: "flex",
  },
  button: {
    "&.MuiButton-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 30,
      minHeight: 40,
      ...styles(theme).button,
    },
  },
  yearContainer: {
    top: 150,
    left: 0,
    right: 0,
    backgroundColor: theme.background.primary,
    zIndex: 10,
    paddingTop: 11,

    "&> *:not(:first-child)": {
      marginTop: 16,
    },
  },
  selectButtonWrapper: {
    position: "fixed",
    width: "100%",
    padding: "16px 32px",
    left: 0,
    bottom: 0,
  },
  selectButton: {
    "&.MuiButton-root": {
      minWidth: "100%",
      minHeight: 48,
      marginRight: 0,
      textTransform: "none",

      "&:hover": {
        backgroundColor: theme.primaryHard,
      },
      "&:active": {
        backgroundColor: theme.primaryHarder,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
  selectedButton: {
    "&.MuiButton-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 30,
      minHeight: 40,
      backgroundColor: theme.hover,
      textTransform: "none",

      "&:hover": {
        backgroundColor: theme.hover,
      },
      "&:active": {
        backgroundColor: theme.hover,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
  monthsWrapper: {
    marginTop: 24,
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    columnGap: 8,
    rowGap: 8,
  },
  monthButton: {
    "&.MuiButton-root": {
      height: 48,
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      borderColor: theme.stroke,
      color: theme.text.primary,
      fontSize: 16,

      "&:hover": {
        transition: "background-color 0.15s ease",
        backgroundColor: theme.background.secondary,
        borderColor: theme.text.primary,
      },
      "&:active": {
        backgroundColor: theme.hover,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
  monthActiveButton: {
    "&.MuiButton-root": {
      height: 48,
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      fontWeight: 800,
      fontSize: 16,

      "&:hover": {
        backgroundColor: theme.primaryHard,
      },
      "&:active": {
        backgroundColor: theme.primaryHarder,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
}));

export default useStyles;
