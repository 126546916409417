import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import useStyles from "./styles";
import CustomTypography from "../CustomTypography";

function Document({ children }) {
  Document.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
      .isRequired,
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.wrapper}>
      <div className={classes.childrenWrapper}>{children}</div>
    </div>
  );
}

/**
 * A componnt to format title.
 * @param {Text} children
 */
function SectionTitle({ children }) {
  SectionTitle.propTypes = {
    children: PropTypes.string.isRequired,
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <CustomTypography variant="title">{children}</CustomTypography>
    </div>
  );
}

/**
 * A componnt to format Paragraph.
 * @param {Paragraph text} children
 */
function Paragraph({ children }) {
  Paragraph.propTypes = {
    children: PropTypes.string.isRequired,
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <CustomTypography style={{ textAlign: "justify" }} variant="body">
        {children}
      </CustomTypography>
    </div>
  );
}

Document.SectionTitle = SectionTitle;
Document.Paragraph = Paragraph;

export default Document;
