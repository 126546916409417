/* eslint-disable no-unused-vars */
import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useGeneralStyles from "../styles";
import useStyles from "./styles";
import CustomTypography from "../../../CustomTypography";
import arrowGreen from "../../../../assets/icons/arrowGreen.svg";
import arrowRed from "../../../../assets/icons/arrowRed.svg";

export default function InfoPanel({ title, data }) {
  InfoPanel.propTypes = {
    data: PropTypes.shape().isRequired,
    title: PropTypes.string.isRequired,
  };

  const theme = useTheme();
  const generalClasses = useGeneralStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <div className={generalClasses.wrapper}>
      <CustomTypography variant="title">{title}</CustomTypography>
      <div className={classes.innerWrapper}>
        <div className={classes.line}>
          <div className={classes.infoItem}>
            <div className={classes.gainOrLossArea}>
              {data.area > 0 ? (
                <img
                  src={arrowGreen}
                  alt="arrowGain"
                  className={classes.arrow}
                />
              ) : (
                <img src={arrowRed} alt="arrowLoss" className={classes.arrow} />
              )}

              <CustomTypography variant="stats">
                {t("general.roundNumber", { value: data.area })} ha
              </CustomTypography>
            </div>
            <div className={classes.subInfoItem}>
              <CustomTypography color={theme.text.legend} variant="subStats">
                {t(
                  `dashboard.statistics.charts.waterTransitions.infoPanel.${
                    data.area > 0 ? "gainArea" : "lossArea"
                  }`
                )}
              </CustomTypography>
            </div>
          </div>
          <div className={classes.infoItem}>
            <div>
              <CustomTypography variant="stats">
                {t("general.number", { value: data.percentage })}%
              </CustomTypography>
            </div>
            <div className={classes.subInfoItem}>
              <CustomTypography color={theme.text.legend} variant="subStats">
                {t(
                  "dashboard.statistics.charts.waterTransitions.infoPanel.percentage"
                )}
              </CustomTypography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
