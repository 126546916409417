import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { Radio } from "@material-ui/core";
import useStyles from "./styles";
import CustomTypography from "../../../../CustomTypography";

/**
 * This functional component renders a option list to be used on filtering container.
 * @returns A options list.
 */
function OptionList({ title, options, value, setValue, sort }) {
  OptionList.defaultProps = {
    sort: false,
  };

  OptionList.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.shape().isRequired,
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    sort: PropTypes.bool,
  };

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const sortedOptions = useMemo(() => {
    if (sort && options) {
      const optionsArray = [];

      Object.keys(options).forEach((option) => {
        optionsArray.push({ key: option, value: options[option] });
      });

      optionsArray.sort((a, b) => {
        if (t(a.value.translation) < t(b.value.translation)) {
          return -1;
        }
        if (t(a.value.translation) > t(b.value.translation)) {
          return 1;
        }
        return 0;
      });

      const optionsAux = {};

      optionsArray.forEach((option) => {
        optionsAux[option.key] = option.value;
      });

      return optionsAux;
    }

    return options;
  }, [options, sort, t]);

  if (!options || Object.keys(options).length === 0) {
    return null;
  }

  return (
    <div className={classes.container}>
      <CustomTypography
        variant="body"
        weight="bold"
        className={classes.title}
        color={theme.text.soft}
      >
        {title.toUpperCase()}
      </CustomTypography>
      {sortedOptions &&
        Object.keys(sortedOptions).map((option) => (
          <div
            role="button"
            tabIndex={0}
            key={option}
            className={classes.optionWrapper}
            onClick={() => setValue(option)}
            onKeyDown={() => setValue(option)}
          >
            {option === "waterClassification" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomTypography variant="contrast">
                  {t(sortedOptions[option].translation)}
                </CustomTypography>
                <CustomTypography
                  style={{ color: "red", marginLeft: "5px", fontSize: 10 }}
                  variant="legend"
                >
                  BETA
                </CustomTypography>
              </div>
            ) : (
              <CustomTypography variant="contrast">
                {t(sortedOptions[option].translation)}
              </CustomTypography>
            )}

            <Radio
              checked={value === option}
              value={option}
              classes={{ root: classes.radio }}
              onChange={() => setValue(option)}
              size="medium"
              color="primary"
            />
          </div>
        ))}
    </div>
  );
}

export default OptionList;
