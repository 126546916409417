import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import CustomTypography from "../../../CustomTypography";

export default function BaseMapOptions({ options }) {
  BaseMapOptions.defaultProps = {
    options: undefined,
  };

  BaseMapOptions.propTypes = {
    options: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <CustomTypography variant="paragraph" weight="bold">
          {t("dashboard.map.baseMap.title")}
        </CustomTypography>
      </div>
      {options}
    </div>
  );
}
