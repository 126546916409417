import * as React from "react";

function SvgMinus(props) {
  return (
    <svg
      width={12}
      height={2}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.167 1C.167.54.54.167 1 .167h10a.833.833 0 010 1.666H1A.833.833 0 01.167 1z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgMinus;
