import * as React from "react";

function SvgPlace(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.554 12.808L11.02 16.56a1.401 1.401 0 01-2.04 0l-3.534-3.752h.014l-.006-.007-.008-.009a5.955 5.955 0 119.107 0l-.007.009-.006.007h.014zm-.68-.567a5.08 5.08 0 10-7.746 0l.107.127 3.382 3.59a.526.526 0 00.765 0l3.383-3.59.108-.127z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8 9c0 .66.54 1.2 1.2 1.2.66 0 1.2-.54 1.2-1.2 0-.66-.54-1.2-1.2-1.2-.66 0-1.2.54-1.2 1.2zm1.2 2c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgPlace;
