import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import ThemeContext from "../../../contexts/theming";
import useStyles from "./styles";
import SvgDarkMode from "../../Icons/DarkMode";
import SvgLightMode from "../../Icons/LightMode";

/**
 * This functional component renders a button that allows the platform theme changing.
 * @returns A button that changes the platform theme.
 */
function ThemeButton() {
  const classes = useStyles();
  const { themeName, switchTheme } = useContext(ThemeContext);

  /**
   * This function switch the platform theme between dark or light mode.
   * @param {Click} event
   */
  function handleSwitchTheme() {
    if (themeName === "light") {
      switchTheme("dark");
    } else {
      switchTheme("light");
    }
  }

  return (
    <Button className={classes.button} onClick={handleSwitchTheme}>
      {themeName === "light" ? (
        <SvgDarkMode className={classes.darkIcon} />
      ) : (
        <SvgLightMode className={classes.lightIcon} />
      )}
    </Button>
  );
}

export default ThemeButton;
