import { Slider } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import FiltersContext from "../../../../contexts/filtering";
import MapContext from "../../../../contexts/mapping";
import useStyles from "./styles";

export default function MapOpacity() {
  const classes = useStyles();

  const { setOpacity, opacity } = useContext(MapContext);
  const {
    values: { analysis },
  } = useContext(FiltersContext);

  /**
   * Reset Map opacity.
   */
  useEffect(() => {
    setOpacity(100);
  }, [analysis]);

  return (
    <>
      <div role="button" tabIndex={0} className={classes.container}>
        <Slider
          value={opacity}
          classes={{ root: classes.slider }}
          onChange={(e, newValue) => setOpacity(newValue)}
        />
      </div>
    </>
  );
}
