import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import CustomTypography from "../../../../CustomTypography";
import SvgCalendar from "../../../../Icons/Calendar";
import FiltersContext from "../../../../../contexts/filtering";
import Selector from "./Selector";

/**
 * This functional component renders a period selector.
 * @returns A period selector.
 */
function PeriodSelector({
  setOpened,
  initialTime,
  endTime,
  setInitialTime,
  setEndTime,
  timeMode,
  setTimeMode,
  analysis,
}) {
  PeriodSelector.propTypes = {
    setOpened: PropTypes.func.isRequired,
    initialTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    setInitialTime: PropTypes.func.isRequired,
    setEndTime: PropTypes.func.isRequired,
    timeMode: PropTypes.string.isRequired,
    setTimeMode: PropTypes.func.isRequired,
    analysis: PropTypes.string.isRequired,
  };

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    options: { timeOptions, analysisOptions },
  } = useContext(FiltersContext);
  const [initialSelectionOpened, setInitialSelectionOpened] = useState(false);
  const [endSelectionOpened, setEndSelectionOpened] = useState(false);
  const [timeModeAux, setTimeModeAux] = useState(timeMode);
  const [initialTimeAux, setInitialTimeAux] = useState(initialTime);
  const [endTimeAux, setEndTimeAux] = useState(endTime);

  useEffect(() => {
    setTimeModeAux(timeMode);
  }, [timeMode]);

  useEffect(() => {
    setInitialTimeAux(initialTime);
  }, [initialTime]);

  useEffect(() => {
    setEndTimeAux(endTime);
  }, [endTime]);

  function handleOpenInitialTime() {
    setInitialTimeAux(initialTime);
    setEndTimeAux(endTime);
    setTimeModeAux(timeMode);
    setOpened(!initialSelectionOpened);
    setInitialSelectionOpened(!initialSelectionOpened);
    setEndSelectionOpened(false);
  }

  function handleOpenEndTime() {
    setInitialTimeAux(initialTime);
    setEndTimeAux(endTime);
    setTimeModeAux(timeMode);
    setOpened(!endSelectionOpened);
    setEndSelectionOpened(!endSelectionOpened);
    setInitialSelectionOpened(false);
  }

  function handleEditButton() {
    if (initialSelectionOpened || endSelectionOpened) {
      setEndSelectionOpened(false);
      setInitialSelectionOpened(false);
      setOpened(false);
    } else {
      handleOpenInitialTime();
    }
  }

  function handleUpdateYear() {
    if (timeModeAux === "annual") {
      setInitialTime(initialTimeAux);
      setEndTime(endTimeAux);
    } else if (analysis === analysisOptions.surface?.key) {
      setInitialTime(initialTimeAux);
      setEndTime(initialTimeAux);
    }
    setTimeMode(timeModeAux);
    setEndSelectionOpened(false);
    setInitialSelectionOpened(false);
    setOpened(false);
  }

  useEffect(() => {
    if (analysis === analysisOptions.surface?.key) {
      if (timeModeAux === "annual") {
        if (parseInt(initialTimeAux, 10) > parseInt(endTimeAux, 10))
          setEndTimeAux(initialTimeAux);
      } else {
        const initialYear = initialTimeAux.substring(2).replace("-", "");
        const lastYear = endTimeAux.substring(2).replace("-", "");
        const initialMonth = initialTimeAux.substring(0, 2).replace("-", "");
        const lastMonth = endTimeAux.substring(0, 2).replace("-", "");

        if (parseInt(initialYear, 10) > parseInt(lastYear, 10)) {
          setEndTimeAux(initialTimeAux);
        } else if (
          lastYear === initialYear &&
          parseInt(initialMonth, 10) > parseInt(lastMonth, 10)
        ) {
          setEndTimeAux(initialTimeAux);
        }
      }
    } else if (analysis === analysisOptions.transition?.key) {
      if (timeModeAux === "annual") {
        if (parseInt(initialTimeAux, 10) >= parseInt(endTimeAux, 10)) {
          setEndTimeAux((parseInt(initialTimeAux, 10) + 1).toString());
        }
      }
    }
  }, [initialTimeAux]);

  useEffect(() => {
    if (analysis === analysisOptions.surface?.key) {
      if (timeModeAux === "annual") {
        if (parseInt(endTimeAux, 10) < parseInt(initialTimeAux, 10))
          setInitialTimeAux(endTimeAux);
      } else {
        const initialYear = initialTimeAux.substring(2).replace("-", "");
        const lastYear = endTimeAux.substring(2).replace("-", "");
        const initialMonth = initialTimeAux.substring(0, 2).replace("-", "");
        const lastMonth = endTimeAux.substring(0, 2).replace("-", "");

        if (parseInt(lastYear, 10) < parseInt(initialYear, 10)) {
          setInitialTimeAux(endTimeAux);
        } else if (
          lastYear === initialYear &&
          parseInt(lastMonth, 10) < parseInt(initialMonth, 10)
        ) {
          setInitialTimeAux(endTimeAux);
        }
      }
    } else if (analysis === analysisOptions.transition?.key) {
      if (timeModeAux === "annual") {
        if (parseInt(endTimeAux, 10) <= parseInt(initialTimeAux, 10)) {
          setInitialTimeAux((parseInt(endTimeAux, 10) + 1).toString());
        }
      }
    }
  }, [endTimeAux]);

  /**
   * Generates months options from an array.
   */
  const initialMonthsOptions = useMemo(() => {
    if (!timeOptions || timeModeAux === "annual") return null;

    const months = [];
    const yearAux = initialTimeAux.substring(2).replace("-", "");
    const monthAux = initialTimeAux.substring(0, 2).replace("-", "");
    const yearMonths = timeOptions.yearsWithMonths[yearAux];

    if (yearMonths) {
      yearMonths.forEach((month) => {
        months.push(
          <Button
            key={month}
            className={
              monthAux === month.toString()
                ? classes.monthActiveButton
                : classes.monthButton
            }
            variant={monthAux === month.toString() ? "contained" : "outlined"}
            color="primary"
            disableElevation
            onClick={() => setInitialTimeAux(`${month}-${yearAux}`)}
          >
            {t("dashboard.filters.timeline.month", {
              date: new Date(2020, month - 1, 1),
            }).replace(".", "")}
          </Button>
        );
      });
    }

    return months;
  }, [timeModeAux, timeOptions, classes, t, initialTimeAux]);

  /**
   * Generates months options from an array.
   */
  const endMonthsOptions = useMemo(() => {
    if (!timeOptions || timeModeAux === "annual") return null;

    const months = [];
    const yearAux = endTimeAux.substring(2).replace("-", "");
    const monthAux = endTimeAux.substring(0, 2).replace("-", "");
    const yearMonths = timeOptions.yearsWithMonths[yearAux];

    if (yearMonths) {
      yearMonths.forEach((month) => {
        months.push(
          <Button
            key={month}
            className={
              monthAux === month.toString()
                ? classes.monthActiveButton
                : classes.monthButton
            }
            variant={monthAux === month.toString() ? "contained" : "outlined"}
            color="primary"
            disableElevation
            onClick={() => setEndTimeAux(`${month}-${yearAux}`)}
          >
            {t("dashboard.filters.timeline.month", {
              date: new Date(2020, month - 1, 1),
            }).replace(".", "")}
          </Button>
        );
      });
    }

    return months;
  }, [timeModeAux, timeOptions, classes, t, initialTimeAux]);

  function handleSetTimeModeAux(newTimeMode) {
    if (newTimeMode === timeModeAux) return;

    if (newTimeMode === "annual") {
      if (initialTimeAux.includes("-")) {
        setInitialTimeAux(initialTimeAux.substring(2).replace("-", ""));
        setEndTimeAux(endTimeAux.substring(2).replace("-", ""));
      }
    } else if (!initialTimeAux.includes("-")) {
      setInitialTimeAux(`1-${initialTimeAux}`);
      setEndTimeAux(`1-${endTimeAux}`);
    }
    setTimeModeAux(newTimeMode);
  }

  function setMonthInitialYearAux(newYear) {
    const stringInit = initialTimeAux.substring(0, 2);

    if (stringInit.includes("-")) {
      setInitialTimeAux(stringInit + newYear);
    } else {
      setInitialTimeAux(`${stringInit}-${newYear}`);
    }
  }

  function setMonthEndYearAux(newYear) {
    const stringInit = endTimeAux.substring(0, 2);

    if (stringInit.includes("-")) {
      setEndTimeAux(stringInit + newYear);
    } else {
      setEndTimeAux(`${stringInit}-${newYear}`);
    }
  }

  const initialYearOptions = useMemo(() => {
    if (analysis === analysisOptions.transition?.key) {
      return timeOptions.years.slice(0, timeOptions.years.length - 1);
    }
    if (analysis === analysisOptions.trend?.key) {
      return timeOptions.years.slice(0, timeOptions.years.length - 4);
    }
    return timeOptions.years;
  }, [analysis]);

  const lastYearOptions = useMemo(() => {
    if (analysis === analysisOptions.transition?.key) {
      return timeOptions.years.slice(1);
    }
    if (analysis === analysisOptions.trend?.key) {
      return timeOptions.years.slice(4);
    }
    return timeOptions.years;
  }, [analysis]);

  /**
   * Updates the selected time based on analysis.
   */
  useEffect(() => {
    if (analysis === analysisOptions.transition?.key) {
      if (initialTime === endTime) {
        if (
          endTime === timeOptions.years[timeOptions.years.length - 1].toString()
        ) {
          setInitialTimeAux((parseInt(initialTime, 10) - 1).toString());
          setInitialTime((parseInt(initialTime, 10) - 1).toString());
        } else {
          setEndTimeAux((parseInt(endTime, 10) + 1).toString());
          setEndTime((parseInt(endTime, 10) + 1).toString());
        }
      }
    } else if (analysis === analysisOptions.trend?.key) {
      if (parseInt(endTime, 10) - parseInt(initialTime, 10) < 5) {
        if (
          endTime >= timeOptions.years[timeOptions.years.length - 4].toString()
        ) {
          setInitialTimeAux((parseInt(initialTime, 10) - 4).toString());
          setInitialTime((parseInt(initialTime, 10) - 4).toString());
        } else {
          setEndTimeAux((parseInt(endTime, 10) + 4).toString());
          setEndTime((parseInt(endTime, 10) + 4).toString());
        }
      }
    } else if (analysis === analysisOptions.waterClassification?.key) {
      setInitialTimeAux(endTime);
      setInitialTime(endTime);
    }
  }, [analysis]);

  function handleInitialTimeAux(newValue) {
    if (analysis === analysisOptions.transition?.key) {
      if (
        newValue === endTimeAux ||
        parseInt(newValue, 10) > parseInt(endTimeAux, 10)
      ) {
        setInitialTimeAux(newValue);
        setEndTimeAux((parseInt(newValue, 10) + 1).toString());
      } else {
        setInitialTimeAux(newValue);
      }
    } else if (analysis === analysisOptions.trend?.key) {
      if (parseInt(endTimeAux, 10) - parseInt(newValue, 10) < 5) {
        setInitialTimeAux(newValue);
        setEndTimeAux((parseInt(newValue, 10) + 4).toString());
      } else {
        setInitialTimeAux(newValue);
      }
    } else if (analysis === analysisOptions.waterClassification?.key) {
      setInitialTimeAux(newValue);
      setEndTimeAux(newValue);
    } else {
      setInitialTimeAux(newValue);
    }
  }

  function handleEndTimeAux(newValue) {
    if (analysis === analysisOptions.transition?.key) {
      if (
        newValue === initialTimeAux ||
        parseInt(newValue, 10) < parseInt(initialTimeAux, 10)
      ) {
        setInitialTimeAux((parseInt(newValue, 10) - 1).toString());
        setEndTimeAux(newValue);
      } else {
        setEndTimeAux(newValue);
      }
    } else if (analysis === analysisOptions.trend?.key) {
      if (parseInt(newValue, 10) - parseInt(initialTimeAux, 10) < 5) {
        setInitialTimeAux((parseInt(newValue, 10) - 4).toString());
        setEndTimeAux(newValue);
      } else {
        setEndTimeAux(newValue);
      }
    } else if (analysis === analysisOptions.waterClassification?.key) {
      setInitialTimeAux(newValue);
      setEndTimeAux(newValue);
    } else {
      setEndTimeAux(newValue);
    }
  }

  return (
    <>
      <div className={classes.container}>
        <CustomTypography
          variant="body"
          weight="bold"
          className={classes.title}
          color={theme.text.soft}
        >
          {t("dashboard.filters.mobile.period.title")}
        </CustomTypography>
        <div className={classes.wrapper}>
          <div className={classes.firstWrapper}>
            <SvgCalendar className={classes.icon} />
            <div className={classes.dateWrapper}>
              <Button
                className={
                  timeModeAux === "annual" || !initialSelectionOpened
                    ? classes.button
                    : classes.selectedButton
                }
                onClick={handleOpenInitialTime}
              >
                <CustomTypography
                  variant="contrast"
                  weight="bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {timeModeAux === "annual"
                    ? initialTimeAux
                    : t("dashboard.filters.timeline.monthlyDate", {
                        date: new Date(
                          initialTimeAux.substring(2).replace("-", ""),
                          parseInt(
                            initialTimeAux.substring(0, 2).replace("-", ""),
                            10
                          ) - 1,
                          1
                        ), // Just the month is important here.
                      }).replace(".", "")}
                </CustomTypography>
              </Button>
              {((analysis === analysisOptions.surface?.key &&
                timeModeAux === "annual") ||
                (analysis !== analysisOptions.surface?.key &&
                  analysis !== analysisOptions.waterClassification?.key)) && (
                <>
                  <span className={classes.periodSeparator}>-</span>
                  <Button
                    className={
                      timeModeAux === "annual" || !endSelectionOpened
                        ? classes.button
                        : classes.selectedButton
                    }
                    onClick={handleOpenEndTime}
                  >
                    <CustomTypography
                      variant="contrast"
                      weight="bold"
                      style={{ textTransform: "capitalize" }}
                    >
                      {timeModeAux === "annual"
                        ? endTimeAux
                        : t("dashboard.filters.timeline.monthlyDate", {
                            date: new Date(
                              endTimeAux.substring(2).replace("-", ""),
                              parseInt(
                                endTimeAux.substring(0, 2).replace("-", ""),
                                10
                              ) - 1,
                              1
                            ), // Just the month is important here.
                          }).replace(".", "")}
                    </CustomTypography>
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className={classes.editButtonWrapper}>
            <Button className={classes.button} onClick={handleEditButton}>
              <CustomTypography
                variant="contrast"
                weight="bold"
                color={
                  initialSelectionOpened || endSelectionOpened
                    ? theme.text.tertiary
                    : theme.primary
                }
                onClick={() => {
                  if (initialSelectionOpened || endSelectionOpened) {
                    setInitialTimeAux(initialTime);
                    setEndTimeAux(endTime);
                    setTimeModeAux(timeMode);
                  }
                }}
              >
                {initialSelectionOpened || endSelectionOpened
                  ? t("dashboard.filters.mobile.period.cancel")
                  : t("dashboard.filters.mobile.period.edit")}
              </CustomTypography>
            </Button>
          </div>
        </div>
      </div>
      {timeModeAux === "annual" &&
        (initialSelectionOpened || endSelectionOpened) && (
          <div className={classes.yearContainer}>
            <Selector
              title={t("dashboard.filters.mobile.period.yearly.type")}
              value={{
                value: timeModeAux,
                translation: "dashboard.filters.timeline.years",
              }}
              setValue={handleSetTimeModeAux}
              options={
                analysisOptions[analysis].time === "annual"
                  ? [
                      {
                        value: "annual",
                        translation: "dashboard.filters.timeline.years",
                        beta: false,
                      },
                    ]
                  : analysisOptions[analysis].time === "monthly"
                  ? [
                      {
                        value: "monthly",
                        translation: "dashboard.filters.timeline.months",
                        beta: true,
                      },
                    ]
                  : [
                      {
                        value: "annual",
                        translation: "dashboard.filters.timeline.years",
                        beta: false,
                      },
                      {
                        value: "monthly",
                        translation: "dashboard.filters.timeline.months",
                        beta: true,
                      },
                    ]
              }
            />
            <Selector
              title={t("dashboard.filters.mobile.period.yearly.initialYear")}
              value={initialTimeAux}
              setValue={handleInitialTimeAux}
              options={initialYearOptions}
            />
            {analysis !== analysisOptions.waterClassification?.key && (
              <Selector
                title={
                  analysis === analysisOptions.waterClassification?.key
                    ? t("dashboard.filters.mobile.period.yearly.uniqueYear")
                    : t("dashboard.filters.mobile.period.yearly.endYear")
                }
                value={endTimeAux}
                setValue={handleEndTimeAux}
                options={lastYearOptions}
              />
            )}
            <div className={classes.selectButtonWrapper}>
              <Button
                className={classes.selectButton}
                variant="contained"
                disableElevation
                color="primary"
                onClick={handleUpdateYear}
              >
                <CustomTypography variant="contrast" color={theme.white}>
                  {t("dashboard.filters.mobile.selectDates")}
                </CustomTypography>
              </Button>
            </div>
          </div>
        )}
      {timeModeAux === "monthly" && initialSelectionOpened && (
        <div className={classes.yearContainer}>
          <Selector
            title={t("dashboard.filters.mobile.period.yearly.type")}
            value={{
              value: timeModeAux,
              translation: "dashboard.filters.timeline.months",
            }}
            setValue={handleSetTimeModeAux}
            options={[
              {
                value: "annual",
                translation: "dashboard.filters.timeline.years",
              },
              /* {
                value: "monthly",
                translation: "dashboard.filters.timeline.months",
              }, */
            ]}
          />
          <Selector
            title={t("dashboard.filters.mobile.period.yearly.initialYear")}
            value={initialTimeAux.substring(2).replace("-", "")}
            setValue={setMonthInitialYearAux}
            options={timeOptions.years}
          />
          <div className={classes.monthsWrapper}>{initialMonthsOptions}</div>
          <div className={classes.selectButtonWrapper}>
            <Button
              className={classes.selectButton}
              variant="contained"
              disableElevation
              color="primary"
              onClick={handleUpdateYear}
            >
              <CustomTypography variant="contrast" color={theme.white}>
                {t("dashboard.filters.mobile.selectInitialDate")}
              </CustomTypography>
            </Button>
          </div>
        </div>
      )}
      {timeModeAux === "monthly" && endSelectionOpened && (
        <div className={classes.yearContainer}>
          <Selector
            title={t("dashboard.filters.mobile.period.yearly.type")}
            value={{
              value: timeModeAux,
              translation: "dashboard.filters.timeline.months",
            }}
            setValue={handleSetTimeModeAux}
            options={[
              {
                value: "annual",
                translation: "dashboard.filters.timeline.years",
              },
              /* {
                value: "monthly",
                translation: "dashboard.filters.timeline.months",
              }, */
            ]}
          />
          <Selector
            title={t("dashboard.filters.mobile.period.yearly.endYear")}
            value={endTimeAux.substring(2).replace("-", "")}
            setValue={setMonthEndYearAux}
            options={timeOptions.years}
          />
          <div className={classes.monthsWrapper}>{endMonthsOptions}</div>
          <div className={classes.selectButtonWrapper}>
            <Button
              className={classes.selectButton}
              variant="contained"
              disableElevation
              color="primary"
              onClick={handleUpdateYear}
            >
              <CustomTypography variant="contrast" color={theme.white}>
                {t("dashboard.filters.mobile.selectFinalDate")}
              </CustomTypography>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default PeriodSelector;
