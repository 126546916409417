import * as React from "react";

function SvgCalendar(props) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 .5a.5.5 0 00-1 0V1H1.974A1.974 1.974 0 000 2.973v9.054C0 13.114.881 14 1.974 14h10.052A1.974 1.974 0 0014 12.027V2.973A1.974 1.974 0 0012.026 1H11V.5a.5.5 0 00-1 0V1H4V.5zM13.067 4V2.973c0-.58-.469-1.044-1.041-1.044H1.974c-.572 0-1.04.465-1.04 1.044V4h12.133zM.933 5h12.134v7.027c0 .58-.469 1.044-1.041 1.044H1.974a1.043 1.043 0 01-1.04-1.044V5z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgCalendar;
