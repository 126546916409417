import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "absolute",
    minWidth: 80,
    maxWidth: 250,
    bottom: 96,
    zIndex: 30000,
    backgroundColor: theme.background.primary,
    borderRadius: 4,
    padding: 10,
    transition: "all 0.3s ease",

    [breakpoints.max.smd]: {
      bottom: 41,
      right: 57,
    },
  },
  separator: {
    width: "100%",
    height: 1,
    margin: "5px 0px",
    backgroundColor: theme.stroke,
  },
}));

export default useStyles;
