import * as React from "react";

function SvgLightMode(props) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 3.797a3.19 3.19 0 00-3.2 3.201A3.19 3.19 0 007 10.197a3.189 3.189 0 003.199-3.199A3.19 3.19 0 007 3.797zm0 5.25a2.05 2.05 0 110-4.1 2.05 2.05 0 010 4.1zM12 2c-.312-.312-.883-.248-1.273.142-.39.391-.453.959-.141 1.272.312.313.882.25 1.273-.141.39-.39.453-.961.141-1.273zm-9.858 8.729c-.391.39-.454.959-.142 1.271.312.312.882.25 1.273-.141.391-.391.454-.961.142-1.273-.312-.312-.883-.248-1.273.143zM2 2c-.312.312-.249.883.141 1.273.391.391.961.453 1.273.141.312-.312.249-.883-.142-1.273C2.883 1.752 2.312 1.688 2 2zm8.727 9.857c.39.391.96.455 1.273.143.313-.312.249-.883-.142-1.274s-.96-.453-1.273-.141c-.313.312-.248.882.142 1.272zM7 1.998c.441 0 .8-.447.8-1C7.799.445 7.441 0 7 0c-.442 0-.801.445-.801.998 0 .553.358 1 .801 1zM7 14c.441 0 .8-.447.8-1 0-.553-.358-.998-.799-.998-.442 0-.801.445-.801.998-.001.553.357 1 .8 1zM2 7c0-.441-.45-.8-1.003-.8C.444 6.2 0 6.559 0 7c0 .442.444.8.997.8C1.55 7.8 2 7.442 2 7zm12 0c0-.441-.448-.8-1.001-.8-.553 0-.999.359-.999.8 0 .442.446.8.999.8C13.552 7.8 14 7.442 14 7z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgLightMode;
