/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import { Popup } from "react-map-gl";
import { Button } from "@material-ui/core";
import { Sparklines, SparklinesLine } from "react-sparklines";
import CustomTypography from "../../../CustomTypography";

import useStyles from "./styles";
import FiltersContext from "../../../../contexts/filtering";
import AnaLogo from "../../../../assets/images/ana_logo.svg";

/**
 * This component encapsulates the custom popup.
 */
export default function CustomPopup({
  feature,
  lng,
  lat,
  accessTerritory,
  closePopup,
}) {
  CustomPopup.defaultProps = {
    feature: null,
    lng: null,
    lat: null,
    accessTerritory: null,
    closePopup: null,
  };

  CustomPopup.propTypes = {
    feature: PropTypes.shape(),
    lng: PropTypes.number,
    lat: PropTypes.number,
    accessTerritory: PropTypes.func,
    closePopup: PropTypes.func,
  };

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    values: { territory, grouping },
  } = useContext(FiltersContext);

  const propsItems = useMemo(() => {
    if (feature?.properties) {
      return Object.keys(feature.properties).map((key) => {
        if (key.toUpperCase().includes("CODE")) return null;
        if (
          key === "name" ||
          key === "acronymUf" ||
          key === "acronym" ||
          key === "areaKm2"
        )
          return null;
        if (!feature.properties[key] || feature.properties[key] === "null")
          return null;

        let unit = "";

        if (key === "lastQuota") unit = " m";
        if (key === "lastUsefulVolumePercentage") unit = "%";
        if (key === "lastVolumePercentage") unit = "%";

        const item = [];

        if (feature.properties[key]) {
          item.push(
            <CustomTypography
              key={`${key}-propTitle`}
              style={{ color: theme.text.tertiary, fontSize: 11 }}
              variant="caption"
            >
              {t(`dashboard.customPopup.${key}`)}
            </CustomTypography>
          );

          if (
            key === "usefulVolumePercentageSeries" ||
            key === "volumePercentageSeries"
          ) {
            const stringArray = feature.properties[key];

            item.push(
              <Sparklines data={JSON.parse(stringArray).reverse()}>
                <SparklinesLine color={theme.primary} />
              </Sparklines>
            );
          } else if (key === "source") {
            item.push(<img alt="source" src={AnaLogo} style={{ width: 50 }} />);
          } else {
            item.push(
              <CustomTypography
                key={`${key}-propValue`}
                style={{ color: theme.text.primary }}
                variant="body1"
              >
                {feature.properties[key] + unit}
              </CustomTypography>
            );
          }
        }

        return item;
      });
    }

    return null;
  }, [feature, t]);

  return (
    <Popup
      className={classes.popup}
      latitude={lat}
      longitude={lng}
      closeButton={false}
    >
      <CustomTypography
        style={{ color: theme.text.primary, fontSize: 12 }}
        variant="caption"
        align="center"
        weight="bold"
      >
        {feature?.properties?.name}
      </CustomTypography>

      {propsItems.filter((item) => !!item).length > 0 && (
        <>
          <div className={classes.separator} />
          {propsItems}
        </>
      )}
      {closePopup && (
        <div
          tabIndex={0}
          role="button"
          onClick={() => closePopup()}
          onKeyDown={() => closePopup()}
        >
          <CustomTypography
            className={classes.close}
            style={{ color: theme.text.primary, fontSize: 14 }}
          >
            x
          </CustomTypography>
        </div>
      )}
      {accessTerritory && territory?.type !== grouping && (
        <>
          <div className={classes.separator} />
          <Button
            variant="contained"
            disableElevation
            color="primary"
            className={classes.accessButton}
            onClick={() => accessTerritory()}
          >
            <CustomTypography variant="body" color={theme.white}>
              {t("dashboard.customPopup.accessButton")}
            </CustomTypography>
          </Button>
        </>
      )}
    </Popup>
  );
}
