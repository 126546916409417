import * as React from "react";

function SvgNorth(props) {
  return (
    <svg
      width={11}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.578 4.5c.259 0 .469-.21.469-.469V1.979l1.55 2.244a.515.515 0 00.582.214.532.532 0 00.364-.526v-.005L7.509.464A.469.469 0 007.041 0h-.005a.469.469 0 00-.464.473l.02 2.095L4.964.209a.469.469 0 00-.855.266v3.556c0 .259.21.469.469.469zM10.71 15.328l-4.47-9.27a.469.469 0 00-.845 0l-4.47 9.27a.469.469 0 00.675.598l4.218-2.707 4.217 2.707a.468.468 0 00.675-.598zM5.35 8.313v4.093l-2.86 1.835 2.86-5.928zm.937 4.093V8.313l2.86 5.928-2.86-1.835z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgNorth;
