import React, { useContext } from "react";
import MapTimeline from "../../../components/Dashboard/MapView/Timeline";
import FiltersContext from "../../../contexts/filtering";

function Timeline() {
  const {
    options: { timeOptions },
    values: { timeMode },
  } = useContext(FiltersContext);

  if (!timeOptions) {
    return null;
  }

  return (
    <MapTimeline
      values={timeMode === "annual" ? timeOptions.years : timeOptions.months}
      availableTimeOptions="annual"
    />
  );
}

export default Timeline;
