import React from "react";
import PropTypes from "prop-types";
import GARoute from "../GARoute";
import Header from "../../Header";

/**
 * This functional component renders a route that contains a header component.
 * @returns A route containing a header.
 */
function HeaderRoute({ exact, path, component: Component }) {
  HeaderRoute.defaultProps = {
    exact: false,
  };

  HeaderRoute.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    component: PropTypes.elementType.isRequired,
  };

  return (
    <GARoute
      exact={exact}
      path={path}
      render={(props) => (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <Header />
          <Component {...props} />
        </div>
      )}
    />
  );
}

export default HeaderRoute;
