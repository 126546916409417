import { createUseStyles } from "react-jss";
import { breakpoints, sizes, styles } from "../../../../constants/constraints";

const useStyles = () =>
  createUseStyles((theme) => ({
    container: {
      position: "relative",
      width: "100%",
      marginLeft: sizes.map.navigation.itemsMargin,
      display: "flex",
      alignItems: "center",
      padding: "0px 16px",

      [breakpoints.max.smd]: {
        display: "none",
      },
    },
    slider: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    sliderRoot: {
      opacity: 1,

      "& .MuiSlider-root": {
        width: "100%",
        marginTop: 5,
      },
      "& .MuiSlider-thumb": {
        opacity: 1,
        boxShadow: "none",
        height: 0,
        width: 13,

        "&:focus, &:hover, &$active": {
          boxShadow: "none",
        },
      },
      "& .MuiSlider-track": {
        opacity: 1,
        backgroundColor: theme.filters.timeline.mark.selected,
      },
      "& .MuiSlider-rail": {
        opacity: 1,
        backgroundColor: theme.filters.timeline.mark.unactive,
      },
      "& .MuiSlider-mark": {
        height: 6,
        width: 2,
        top: 11,
        borderRadius: "30%",
        backgroundColor: theme.filters.timeline.mark.unactive,

        "&:nth-child(5n -1)": {
          height: 12,
          top: 8,
        },
      },
      "& .MuiSlider-markActive": {
        width: 2,
        backgroundColor: theme.filters.timeline.mark.selected,
        borderRadius: "30%",
      },
    },
    marksContainer: {
      paddingTop: 5,
      left: 0,
      right: 0,
      display: "flex",
      position: "absolute",
      justifyContent: "space-between",
      alignItems: "center",
      pointerEvents: "none",
    },
    separator: {
      width: 1,
      height: 24,
      marginRight: 16,
      marginLeft: 5,
      backgroundColor: theme.filters.timeline.separator,
    },
    calendarIcon: {
      marginRight: 5,
      "& path": {
        fill: theme.primary,
      },
    },
    markWrapper: {
      position: "relative",
      height: 12,
      display: "flex",
      alignItems: "center",
    },
    markerArrow: {
      "& path": {
        fill: theme.primary,
      },
    },
    mark: {
      width: 2,
      borderRadius: "30%",
      position: "absolute",
      left: 0,
      transform: "translateX(-50%)",
    },
    calendarContainer: {
      position: "relative",
      display: "flex",
      alignItems: "center",
    },
    calendarButtonAnnual: {
      "&.MuiButton-root": {
        minWidth: 60,
        minHeight: 33,
        textTransform: "capitalize",
        whiteSpace: "nowrap",

        ...styles(theme).button,
      },
    },
    calendarButtonMonthly: {
      "&.MuiButton-root": {
        minWidth: 92,
        minHeight: 33,
        textTransform: "capitalize",
        whiteSpace: "nowrap",

        ...styles(theme).button,
      },
    },
    calendarSeparator: {
      margin: "0px 5px",
      color: theme.filters.timeline.calendarSeparator,
    },
    downIcon: {
      marginLeft: 5,
      transform: "scale(0.6)",

      "& path": {
        fill: theme.text.primary,
      },
    },
    closeContainer: {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      userSelect: "none",
      zIndex: 700,
    },
  }))();

export default useStyles;
