import * as React from "react";

function SvgOpacity(props) {
  return (
    <svg
      width={12}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.238 3.881L6.525.215a.75.75 0 00-1.05 0L1.763 3.88A5.888 5.888 0 000 8.075C0 11.35 2.685 14 6 14s6-2.65 6-5.925a5.888 5.888 0 00-1.762-4.194zM2.813 4.958L6 1.81l3.188 3.147c.66.655 1.53 1.95 1.252 3.772H1.553c-.278-1.822.6-3.125 1.26-3.772z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgOpacity;
