import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import Selector from "../../../Selector";
import FiltersContext from "../../../../../contexts/filtering";

/**
 * This function renders a popup containing the time selection popup.
 * @returns The time selection popup.
 */
function TimeSelection({
  style,
  className,
  time,
  setTime,
  onClose,
  isFirst,
  availableOptions,
}) {
  TimeSelection.defaultProps = {
    className: "",
    style: {},
    isFirst: false,
  };

  TimeSelection.propTypes = {
    className: PropTypes.string,
    style: PropTypes.shape(),
    time: PropTypes.string.isRequired,
    setTime: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    minYear: PropTypes.string,
    minMonth: PropTypes.string,
    isFirst: PropTypes.bool,
    availableOptions: PropTypes.string.isRequired,
  };

  const classes = useStyles();
  const { t } = useTranslation();
  const {
    options: { timeOptions, analysisOptions },
    values: { timeMode, analysis },
    setters: { setTimeMode },
  } = useContext(FiltersContext);
  const [timeModeAux, setTimeModeAux] = useState(timeMode);
  const [yearAux, setYearAux] = useState(
    timeMode === "annual" ? time : time.substring(2).replace("-", "")
  );
  const [monthAux, setMonthAux] = useState(
    timeMode === "annual" ? "1" : time.substring(0, 2).replace("-", "")
  );

  /**
   * Handles the year change.
   */
  function handleConfirm() {
    if (timeModeAux === "annual") {
      setTime(yearAux);
    } else {
      setTime(`${monthAux}-${yearAux}`);
    }
    setTimeMode(timeModeAux);
    onClose();
  }

  /**
   * Generates years options from an array.
   */
  const yearsOptions = useMemo(() => {
    if (!timeOptions) return null;

    const years = {};

    if (analysis === analysisOptions.surface?.key) {
      timeOptions.years.forEach((year) => {
        years[year] = { label: year.toString() };
      });
    } else if (analysis === analysisOptions.transition?.key) {
      if (timeMode === "annual") {
        timeOptions.years.forEach((year, index) => {
          if (isFirst && index !== timeOptions.years.length - 1) {
            years[year] = { label: year.toString() };
          } else if (!isFirst && index !== 0) {
            years[year] = { label: year.toString() };
          }
        });
      } else {
        timeOptions.years.forEach((year) => {
          years[year] = { label: year.toString() };
        });
      }
    } else if (analysis === analysisOptions.trend?.key) {
      timeOptions.years.forEach((year, index) => {
        if (isFirst && index < timeOptions.years.length - 4) {
          years[year] = { label: year.toString() };
        } else if (!isFirst && index >= 4) {
          years[year] = { label: year.toString() };
        }
      });
    } else if (analysis === analysisOptions.waterClassification?.key) {
      timeOptions.years.forEach((year) => {
        years[year] = { label: year.toString() };
      });
    }

    return years;
  }, [timeOptions]);

  /**
   * Generates months options from an array.
   */
  const monthsOptions = useMemo(() => {
    if (!timeOptions || timeModeAux === "annual") return null;

    const months = [];
    const yearMonths = timeOptions.yearsWithMonths[yearAux];

    if (yearMonths) {
      yearMonths.forEach((month) => {
        months.push(
          <Button
            key={month}
            className={
              monthAux === month.toString()
                ? classes.monthButton
                : classes.monthActiveButton
            }
            variant={monthAux === month.toString() ? "contained" : "outlined"}
            color="primary"
            disableElevation
            onClick={() => setMonthAux(month.toString())}
          >
            {t("dashboard.filters.timeline.month", {
              date: new Date(2020, month - 1, 1),
            }).replace(".", "")}
          </Button>
        );
      });
    }

    return months;
  }, [timeModeAux, timeOptions, yearAux, classes, t, monthAux]);

  /**
   * Generate years options to monthly selecion
   */
  const monthlyYearsOptions = useMemo(() => {
    if (timeOptions) {
      const newYearsOptions = {};

      Object.keys(timeOptions.yearsWithMonths).forEach((key) => {
        newYearsOptions[key] = { label: key };
      });

      return newYearsOptions;
    }

    return yearsOptions;
  }, [timeOptions]);

  /**
   * Set the year selection to the last option when the change comes from the monthly timeMode.
   */
  function handleTimeMode(e) {
    if (timeModeAux === "monthly" && !yearsOptions[yearAux]) {
      const yearsArray = Object.keys(yearsOptions);
      const lastYear = yearsArray[yearsArray.length - 1];
      setYearAux(lastYear);
    }

    setTimeModeAux(e);
  }

  return useMemo(
    () => (
      <Paper classes={{ root: classes.paper }} style={style} elevation={0}>
        <div className={classNames(classes.container, className)}>
          <div className={classes.selectorsWrapper}>
            <Selector
              value={timeModeAux}
              setValue={handleTimeMode}
              options={
                availableOptions === "annual"
                  ? {
                      annual: {
                        translation: "dashboard.filters.timeline.years",
                        beta: false,
                      },
                    }
                  : availableOptions === "monthly"
                  ? {
                      monthly: {
                        translation: "dashboard.filters.timeline.months",
                        beta: true,
                      },
                    }
                  : {
                      annual: {
                        translation: "dashboard.filters.timeline.years",
                        beta: false,
                      },
                      monthly: {
                        translation: "dashboard.filters.timeline.months",
                        beta: true,
                      },
                    }
              }
              className={classes.select}
            />
            <span style={{ width: 16 }} />
            {timeModeAux === "annual" && (
              <Selector
                value={yearAux}
                setValue={setYearAux}
                options={yearsOptions}
                className={classes.select}
                paperClassName={classes.selectPaper}
              />
            )}
            {timeModeAux === "monthly" && (
              <Selector
                value={yearAux}
                setValue={setYearAux}
                options={monthlyYearsOptions}
                className={classes.select}
                paperClassName={classes.selectPaper}
              />
            )}
          </div>
          {timeModeAux === "monthly" && (
            <div className={classes.monthsWrapper}>{monthsOptions}</div>
          )}

          <Button
            disableElevation
            className={classes.selectButton}
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            {t("dashboard.filters.timeline.select")}
          </Button>
        </div>
      </Paper>
    ),
    [
      yearsOptions,
      monthsOptions,
      timeMode,
      setTimeMode,
      time,
      yearAux,
      style,
      className,
      classes,
    ]
  );
}

export default TimeSelection;
