import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  paper: {
    "&.MuiPaper-root": {
      position: "absolute",
      left: 0,
      bottom: 45,
      backgroundColor: "transparent",
    },
  },
  container: {
    position: "relative",
    zIndex: 710,
    borderRadius: 4,
    width: 264,
    backgroundColor: theme.background.primary,
    padding: 16,
    boxShadow: theme.floatingShadow,
  },
  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px",
      color: theme.text.primary,
    },
  },
  selectPaper: {
    "&.MuiPaper-root, .MuiPopover-paper": {
      maxHeight: 150,
    },
  },
  selectButton: {
    "&.MuiButton-root": {
      width: "100%",
      height: 40,
      marginTop: 24,
      textTransform: "capitalize",
      whiteSpace: "nowrap",

      "&:hover": {
        backgroundColor: theme.primaryHard,
      },
      "&:active": {
        backgroundColor: theme.primaryHarder,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
  selectorsWrapper: {
    display: "flex",
  },
  monthsWrapper: {
    marginTop: 24,
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    columnGap: 8,
    rowGap: 8,
  },
  monthButton: {
    "&.MuiButton-root": {
      height: 32,
      textTransform: "capitalize",
      whiteSpace: "nowrap",

      "&:hover": {
        backgroundColor: theme.primaryHard,
      },
      "&:active": {
        backgroundColor: theme.primaryHarder,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
  monthActiveButton: {
    "&.MuiButton-root": {
      height: 32,
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      borderColor: theme.stroke,
      color: theme.text.primary,

      "&:hover": {
        transition: "background-color 0.15s ease",
        backgroundColor: theme.background.secondary,
        borderColor: theme.text.primary,
      },
      "&:active": {
        backgroundColor: theme.hover,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
}));

export default useStyles;
