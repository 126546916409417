import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  popup: {
    "& .mapboxgl-popup-content": {
      backgroundColor: theme.background.primary,
      borderRadius: 10,
      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.15)",
      color: theme.text.primary,
      minWidth: 120,
      maxWidth: 250,

      "& .mapboxgl-popup-close-button": {
        marginRight: 5,
      },
    },
    "& .mapboxgl-popup-tip": {
      borderTopColor: theme.background.primary,
      borderBottomColor: theme.background.primary,
    },
  },
  separator: {
    width: "100%",
    height: 1,
    margin: "5px 0px",
    backgroundColor: theme.stroke,
  },
  close: {
    position: "absolute",
    top: 5,
    right: 8,
  },
  accessButton: {
    "&.MuiButton-root": {
      minWidth: "100%",
      minHeight: 28,
      marginRight: 0,
      textTransform: "none",

      "&:hover": {
        backgroundColor: theme.primaryHard,
      },
      "&:active": {
        backgroundColor: theme.primaryHarder,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
}));

export default useStyles;
