import React, { createContext, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { ThemeProvider as JssThemeProvider } from "react-jss";
import { MuiThemeProvider, useMediaQuery } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import {
  lightScheme,
  darkScheme,
  glacierLightScheme,
  glacierDarkScheme,
} from "../constants/schemes";

const ThemeContext = createContext({});

/**
 * The ThemeProvider is a wrapper to JssThemeProvider that allows switch themes.
 * */
export function ThemeProvider({ children }) {
  ThemeProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [selectedTheme, setSelectedTheme] = useState(lightScheme);
  const [themeName, setThemeName] = useState("light");
  const [themeMod, setThemeMod] = useState();

  /**
   * Load last selected theme or the prefered color scheme.
   */
  useEffect(() => {
    const savedTheme = localStorage.getItem("@mapbiomasAgua/lastTheme");
    let moddedLightScheme = lightScheme;
    let moddedDarkScheme = darkScheme;

    if (themeMod) {
      if (themeMod === "glacier") {
        moddedLightScheme = glacierLightScheme;
        moddedDarkScheme = glacierDarkScheme;
      }
    }

    if (savedTheme) {
      setSelectedTheme(
        savedTheme === "light" ? moddedLightScheme : moddedDarkScheme
      );
      setThemeName(savedTheme === "light" ? "light" : "dark");
    } else {
      setSelectedTheme(prefersDarkMode ? moddedDarkScheme : moddedLightScheme);
      setThemeName(prefersDarkMode ? "dark" : "light");
    }
  }, [prefersDarkMode, themeMod]);

  /**
   * Switch the theme mode between light, dark or an auto value based on css prefers-color-scheme.
   */
  function switchTheme(themingMode) {
    let moddedLightScheme = lightScheme;
    let moddedDarkScheme = darkScheme;

    if (themeMod) {
      if (themeMod === "glacier") {
        moddedLightScheme = glacierLightScheme;
        moddedDarkScheme = glacierDarkScheme;
      }
    }

    if (themingMode === "light" || themingMode === "dark") {
      setSelectedTheme(
        themingMode === "light" ? moddedLightScheme : moddedDarkScheme
      );
      setThemeName(themingMode === "light" ? "light" : "dark");
      localStorage.setItem("@mapbiomasAgua/lastTheme", themingMode);
    } else {
      setSelectedTheme(prefersDarkMode ? moddedDarkScheme : moddedLightScheme);
      setThemeName(prefersDarkMode ? "dark" : "light");
      localStorage.removeItem("@mapbiomasAgua/lastTheme");
    }
  }

  /**
   * Generate a theme to be used on Material-ui components.
   */
  const muiTheme = useMemo(
    () =>
      createMuiTheme({
        typography: {
          fontFamily: `"Manrope", "Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
          fontWeight: 500,
          fontSize: 12,
          lineHeight: "16.93px",
          letterSpacing: "-0.00833em",
        },
        palette: {
          background: {
            default: selectedTheme.background.primary,
          },
          primary: {
            main: selectedTheme.primary,
          },
          secondary: {
            main: selectedTheme.secondary,
          },
          text: {
            primary: selectedTheme.text.primary,
            secondary: selectedTheme.text.secondary,
          },
        },
      }),
    [selectedTheme]
  );

  return (
    <ThemeContext.Provider
      value={{
        selectedTheme,
        switchTheme,
        themeMod,
        setThemeMod,
        themeName,
        setThemeName,
      }}
    >
      <JssThemeProvider theme={selectedTheme}>
        <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
      </JssThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
