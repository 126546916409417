/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import InfoPanel from "../../../../../components/Dashboard/Charts/InfoPanel";
import Ranking from "../../../../../components/Dashboard/Charts/Ranking";
import Sankey from "../../../../../components/Dashboard/Charts/Sankey";
import FiltersContext from "../../../../../contexts/filtering";
import api from "../../../../../services/api";
import TransitionsRectangle from "../../../../../components/Dashboard/Charts/TransitionsRectangle";

export default function TransitionStatistics() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { values } = useContext(FiltersContext);
  const [sankeyData, setSankeyData] = useState();
  const [infoPanelData, setInfoPanelData] = useState();
  const [doubleRankingData, setDoubleRankingData] = useState();
  const [rankingTotalPages, setRankingTotalPages] = useState(6);
  const [rankingPage, setRankingPage] = useState(1);
  const [rankingOrder, setRankingOrder] = useState(true);

  /**
   * This userEffect updates sankey chart data.
   */
  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/transitions/sankey`, {
        params: {
          timeMode: values.timeMode,
          territory: values.territory.type,
          territoryCode: values.territory.code,
          grouping: values.grouping,
          years: values.years,
          months: values.months,
          dataType: values.dataType,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          data.map((obj) => {
            obj.from = t(
              `dashboard.statistics.charts.glacierTransitions.sankey.${obj.from}`
            );
            obj.to = t(
              `dashboard.statistics.charts.glacierTransitions.sankey.${obj.to}`
            );
            return obj;
          });
          setSankeyData(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.timeMode,
    values.years,
    values.months,
    values.grouping,
    values.dataType,
    t,
  ]);

  /**
   * This userEffect updates general statistics chart data.
   */
  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/transitions/statistics`, {
        params: {
          timeMode: values.timeMode,
          territory: values.territory.type,
          territoryCode: values.territory.code,
          grouping: values.grouping,
          years: values.years,
          months: values.months,
          dataType: values.dataType,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          setInfoPanelData(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.timeMode,
    values.years,
    values.months,
    values.grouping,
    values.dataType,
    t,
  ]);

  /**
   * This userEffect gets new ranking data every time the filter values are modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (values.grouping) {
      api
        .get(`/transitions/ranking`, {
          params: {
            timeMode: values.timeMode,
            territory: values.territory.type,
            territoryCode: values.territory.code,
            grouping: values.grouping,
            years: values.years,
            months: values.months,
            dataType: values.dataType,
            order: rankingOrder ? "desc" : "asc",
            page: 1,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setRankingTotalPages(data.pageAmount);
            data.series = data.series.map((obj) => {
              obj.color = theme.transition[obj.name];
              obj.name = t(
                `dashboard.statistics.charts.glacierTransitions.ranking.${obj.name}`
              );
              return obj;
            });
            setDoubleRankingData(data);
            setRankingPage(1);
            if (data.territoryPage > 0) setRankingPage(data.territoryPage);
          }
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.code,
    values.territory.type,
    values.grouping,
    values.timeMode,
    values.years,
    values.months,
    values.dataType,
    rankingOrder,
    t,
  ]);

  /**
   * This userEffect gets new ranking data every time the ranking page is modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (values.grouping) {
      api
        .get(`/transitions/ranking`, {
          params: {
            timeMode: values.timeMode,
            territory: values.territory.type,
            territoryCode: values.territory.code,
            grouping: values.grouping,
            years: values.years,
            months: values.months,
            order: rankingOrder ? "desc" : "asc",
            page: rankingPage,
            dataType: values.dataType,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            data.series = data.series.map((obj) => {
              obj.color = theme.transition[obj.name];
              obj.name = t(
                `dashboard.statistics.charts.glacierTransitions.ranking.${obj.name}`
              );
              return obj;
            });
            setDoubleRankingData(data);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [rankingPage]);

  return useMemo(
    () => (
      <>
        {infoPanelData && (
          <InfoPanel
            title={`${t(
              "dashboard.statistics.charts.glacierTransitions.infoPanel.title"
            )} - ${values.territory.name}`}
            data={infoPanelData}
          />
        )}
        <TransitionsRectangle />
        {sankeyData && (
          <Sankey
            title={t(
              "dashboard.statistics.charts.glacierTransitions.sankey.title"
            )}
            data={sankeyData}
            info={t(
              "dashboard.statistics.charts.glacierTransitions.sankey.info"
            )}
            analysis={t("dashboard.statistics.charts.glacierTransitions.title")}
          />
        )}
        {doubleRankingData && (
          <Ranking
            sum
            title={t(
              "dashboard.statistics.charts.glacierTransitions.ranking.title"
            )}
            data={doubleRankingData}
            info={t(
              "dashboard.statistics.charts.glacierTransitions.ranking.info"
            )}
            totalPages={rankingTotalPages}
            page={rankingPage}
            setRankingPage={setRankingPage}
            rankingOrder={rankingOrder}
            setRankingOrder={setRankingOrder}
          />
        )}
      </>
    ),
    [sankeyData, infoPanelData, doubleRankingData, values.territory.name, t]
  );
}
