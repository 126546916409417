/* eslint-disable no-unused-vars */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from "react";
import { useTheme } from "react-jss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import highchartsMore from "highcharts/highcharts-more";
import { InfoOutlined } from "@material-ui/icons";
import useOptions from "../../../../hooks/options";
import useStyles from "../styles";
import CustomTooltip from "../../../CustomTooltip";

highchartsMore(Highcharts);

/**
 * This component is a stylized chart to display area data.
 * @param {Object with two arrays: categories and values} data
 */
export default function Area({ title, data, info, format }) {
  Area.defaultProps = {
    data: undefined,
    format: undefined,
  };

  Area.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape(),
    info: PropTypes.string.isRequired,
    format: PropTypes.func,
  };

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const defaultOptions = useOptions();
  const { t } = useTranslation();
  const classes = useStyles();

  const [chartObj, setChartObj] = useState();

  /**
   * This useEffect shows tooltip when a specific year was selected.
   */
  useEffect(() => {
    if (chartObj) {
      if (data.selectedPoint) {
        setTimeout(() => {
          chartObj.tooltip.refresh(
            chartObj.series[0].points[data.selectedPoint]
          );
        }, 1000);
      }
    }
  }, [data]);

  function roundToTwo(num) {
    return +`${Math.round(`${num}e+2`)}e-2`;
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  /**
   * This function creates delta tooltip.
   */
  function deltaTooltip(chart) {
    const chartContainer = document.getElementById("container");

    if (chartContainer && !isEmpty(chart)) {
      const xAxis = chart.xAxis[0];
      const yAxis = chart.series[0];
      let isMouseDown = false;
      let inicial;
      let final;
      let yInicial;
      let yFinal;
      let xInicial;
      let xFinal;
      let yInicialPoint;

      chartContainer.onmousedown = function omd(e) {
        if (!isEmpty(chart)) {
          inicial = Math.round(xAxis.toValue(e.chartX));
          if (inicial >= 0) {
            isMouseDown = true;
            yInicialPoint = yAxis.points[inicial];
            if (yInicialPoint) {
              yInicial = yInicialPoint.options.y;
            }
          }
        }
      };

      chartContainer.onmousemove = function omm(e) {
        if (!isEmpty(chart)) {
          let delta;
          let deltaPctg;
          if (isMouseDown && yInicial) {
            final = Math.round(xAxis.toValue(e.chartX));
            if (final >= 0) {
              const yFinalPoint = yAxis.points[final];
              if (yInicialPoint && yFinalPoint) {
                yFinal = yFinalPoint.options.y;
                if (inicial < final) {
                  delta = yFinal - yInicial;
                  deltaPctg = (delta / yInicial) * 100;
                  xInicial = yInicialPoint.category;
                  xFinal = yFinalPoint.category;
                } else {
                  delta = yInicial - yFinal;
                  deltaPctg = (delta / yFinal) * 100;
                  xInicial = yFinalPoint.category;
                  xFinal = yInicialPoint.category;
                }

                chart.update({
                  tooltip: {
                    useHTML: true,
                    formatter() {
                      if (data.timeMode === "annual") {
                        return `
                            <b>
                              ${t("general.date.year", {
                                date: new Date(xInicial),
                              })}
                            </b> - 
                            <b>
                              ${t("general.date.year", {
                                date: new Date(xFinal),
                              })}
                            </b>
                            </br>
                            <span style="color:${delta > 0 ? "green" : "red"}">
                              <tr>
                                <td>
                                  <b>${delta > 0 ? "+" : ""}${t(
                          "general.roundNumber",
                          {
                            value: delta,
                          }
                        )} ha
                                  </b>
                                </td>
                                <td>
                                  <b>(${t("general.number", {
                                    value: roundToTwo(Math.abs(deltaPctg)),
                                  })}%)</b>
                                </td>
                              </tr>
                            </span>`;
                      }
                      return `
                            <b>
                              ${t("general.date.monthNameYear", {
                                date: new Date(xInicial),
                              })}
                            </b> - 
                            <b>
                              ${t("general.date.monthNameYear", {
                                date: new Date(xFinal),
                              })}
                            </b>
                            </br>
                            <span style="color:${delta > 0 ? "green" : "red"}">
                              <tr>
                                <td>
                                  <b>${delta > 0 ? "+" : ""}${t(
                        "general.roundNumber",
                        {
                          value: delta,
                        }
                      )} ha
                                  </b>
                                </td>
                                <td>
                                  <b>(${t("general.number", {
                                    value: roundToTwo(Math.abs(deltaPctg)),
                                  })}%)</b>
                                </td>
                              </tr>
                            </span>`;
                    },
                  },
                });
              }
            }
          }
        }
      };

      chartContainer.onmouseup = function omu(e) {
        if (!isEmpty(chart)) {
          isMouseDown = false;
          chart.update({
            tooltip: {
              useHTML: true,
              formatter() {
                if (data.timeMode === "monthly") {
                  return `<b>${t("general.date.monthNameYear", {
                    date: new Date(this.point.category),
                  })}</b> </br>
                      <tr><td style="color: ${this.series.color}">${
                    this.series.name
                  }: </td>
                        <td style="text-align: right"><b>${t(
                          "general.roundNumber",
                          {
                            value: this.point.y,
                          }
                        )} ha
                      </b></td></tr>`;
                }
                return `<b>${t("general.date.year", {
                  date: new Date(this.point.category),
                })}</b> </br>
                        <tr><td style="color: ${this.series.color}">${
                  this.series.name
                }: </td>
                          <td style="text-align: right"><b>${t(
                            "general.roundNumber",
                            {
                              value: this.point.y,
                            }
                          )} ha
                        </b></td></tr>`;
              },
            },
          });
        }
      };
    }
  }

  /**
   * This useEffect set delta tooltip every time that data is updated.
   */
  useEffect(() => {
    if (chartObj) {
      deltaTooltip(chartObj);
    }
  }, [data]);

  const options = {
    chart: {
      type: "area",
      events: {
        load() {
          const chart = this;
          if (chart) {
            deltaTooltip(chart);
          }
        },
      },
      ...defaultOptions.chart,
    },
    credits: defaultOptions.credits,
    title: {
      text: title,
      ...defaultOptions.title,
    },
    xAxis: {
      ...defaultOptions.xAxis,
      labels: {
        style: {
          fontSize: "10px",
          color: theme.text.secondary,
          ...defaultOptions.yAxis.labels.style,
        },
        formatter() {
          if (format) {
            if (Number.isNaN(this.value)) {
              return format(this.value);
            }

            return format(Math.trunc(this.value));
          }
          if (data.timeMode === "monthly") {
            return t("general.date.monthYear", { date: new Date(this.value) });
          }
          return t("general.date.year", { date: new Date(this.value) });
        },
      },
      categories: data.x,
    },
    yAxis: defaultOptions.yAxis,
    tooltip: {
      useHTML: true,
      formatter() {
        if (format) {
          return `<b>${format(this.point.category)}</b> </br>
          <tr><td style="color: ${this.series.color}">${this.series.name}: </td>
            <td style="text-align: right"><b>${t("general.roundNumber", {
              value: this.point.y,
            })} ha
          </b></td></tr>`;
        }

        if (data.timeMode === "monthly") {
          return `<b>${t("general.date.monthNameYear", {
            date: new Date(this.point.category),
          })}</b> </br>
          <tr><td style="color: ${this.series.color}">${this.series.name}: </td>
            <td style="text-align: right"><b>${t("general.roundNumber", {
              value: this.point.y,
            })} ha
          </b></td></tr>`;
        }
        return `<b>${t("general.date.year", {
          date: new Date(this.point.category),
        })}</b> </br>
            <tr><td style="color: ${this.series.color}">${
          this.series.name
        }: </td>
              <td style="text-align: right"><b>${t("general.roundNumber", {
                value: this.point.y,
              })} ha
            </b></td></tr>`;
      },
    },
    legend: defaultOptions.legend,
    plotOptions: {
      area: {
        lineColor: theme.primary,
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: {
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, `${theme.primary}66`],
            [1, "rgba(0, 108, 255, 0)"],
          ],
        },
        trackByArea: true,
      },
    },
    series: data.series,
    navigation: defaultOptions.navigation,
    lang: defaultOptions.lang,
    exporting: {
      ...defaultOptions.exporting,
      chartOptions: {
        chart: {
          events: null,
          style: {
            backgroundColor: theme.background.primary,
          },
        },
      },
    },
  };

  return (
    <div id="container" className={classes.wrapper}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={(chart) => {
          setChartObj(chart);
        }}
      />
      <div className={classes.tooltip}>
        <CustomTooltip title={info} placement="bottom">
          <InfoOutlined
            style={{
              color: theme.text.primary,
              fontSize: "15px",
            }}
          />
        </CustomTooltip>
      </div>
    </div>
  );
}
