/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  ListSubheader,
  Popper,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SvgSearch from "../../Icons/Search";
import useStyles from "./styles";
import FiltersContext from "../../../contexts/filtering";
import { defaults } from "../../../constants/general";
import api from "../../../services/api";
import { breakpoints } from "../../../constants/constraints";
import SvgBack from "../../Icons/Back";
import SvgPlace from "../../Icons/Place";

/**
 * This component creates a Autocomplete containing a list of available territories.
 */
function TerritoriesAutocomplete() {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const {
    options: { territoriesOptions },
    values: { territory, dataType, initiative },
    setters: { setTerritory, setTerritoriesOptions },
  } = useContext(FiltersContext);
  const [textValue, setTextValue] = useState(defaults[dataType].territory.name);
  const [loading, setLoading] = useState(false);
  const smd = useMediaQuery(breakpoints.max.smd);
  const [smdOpened, setSmdOpened] = useState(false);

  /**
   * This useEffect controls the async autocomplete.
   */
  useEffect(() => {
    let active = true;

    if (setTerritoriesOptions && territory) {
      if (textValue.length <= 1) {
        setTerritoriesOptions(territory ? [territory] : []);
      } else if (textValue.length > 1 && !/^\s*$/.test(textValue)) {
        setLoading(true);

        api
          .get(`/territories/search/${textValue}`, {
            params: {
              dataType,
              initiative,
            },
          })
          .then(({ data }) => {
            if (active) {
              const index = data.findIndex(
                (element) => element.type === territory.type
              );

              /*
            If there are an element of the current type on list, the selected element 
            should be inserted on correct sorted position on list to avoid duplied group headers.
            If the same element is already on list, there is no need to include it again inside list array.
            */

              if (index >= 0) {
                if (!data.find((element) => element.code === territory.code)) {
                  data.splice(index, 0, territory);
                }
                setTerritoriesOptions(data);
              } else {
                setTerritoriesOptions([territory, ...data]);
              }

              setLoading(false);
            }
          });
      }
    }

    return () => {
      active = false;
    };
  }, [textValue, territory, initiative]);

  return useMemo(
    () => (
      <div
        className={
          smdOpened ? classes.containerMobile : classes.containerDesktop
        }
      >
        <div
          className={smdOpened ? classes.mobileWrapper : classes.defaultWrapper}
        >
          {smdOpened && (
            <Button className={classes.backButton}>
              <SvgBack className={classes.backArrow} />
            </Button>
          )}
          <Autocomplete
            autoComplete
            autoHighlight
            selectOnFocus
            disableClearable
            options={territoriesOptions}
            getOptionSelected={(option, value) => {
              if (territoriesOptions.length === 0) {
                return true;
              }
              return option.code === value.code && option.type === value.type;
            }}
            getOptionLabel={(option) => option.name}
            classes={{
              listbox: classes.listbox,
              root: classes.root,
              popper: classes.popper,
            }}
            loading={loading}
            loadingText={t("dashboard.filters.territory.loading")}
            groupBy={(option) => option.type}
            defaultValue={defaults[dataType].territory}
            noOptionsText={t("dashboard.filters.territory.notFound")}
            popupIcon={<SvgSearch className={classes.searchIcon} />}
            onOpen={() => {
              if (smd) {
                setSmdOpened(true);
              }
            }}
            onClose={() => {
              if (textValue !== territory.name) {
                setTextValue(territory.name);
              }
              setSmdOpened(false);
            }}
            renderGroup={(params, index) => [
              <ListSubheader key={`${params.group}_${index}`} component="div">
                {t(`dashboard.filters.territory.type.${params.group}`)}
              </ListSubheader>,
              params.children,
            ]}
            onChange={(event, newSelection) => {
              if (newSelection) setTerritory(newSelection);
            }}
            value={territory}
            inputValue={textValue}
            onInputChange={(event, newInputValue) => {
              if (newInputValue.length < 50) setTextValue(newInputValue);
            }}
            PopperComponent={(props) => (
              <div className={classes.popperWrapper}>
                <Popper
                  disablePortal={smd}
                  {...props}
                  className={classes.popper}
                />
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("dashboard.filters.territory.title")}
                size="small"
                variant="outlined"
                fullWidth
                className={classes.autocompleteInput}
              />
            )}
            renderOption={(option) => (
              <div className={classes.option}>
                {smd && <SvgPlace className={classes.placeIcon} />}
                <span className={classes.partText}>
                  {option.name}
                  {option.acronym && (
                    <span className={classes.acronym}>
                      &nbsp;&nbsp;&nbsp;{option.acronym}
                    </span>
                  )}
                </span>
              </div>
            )}
          />
        </div>
      </div>
    ),
    [
      t,
      territoriesOptions,
      territory,
      textValue,
      loading,
      classes,
      theme,
      smd,
      smdOpened,
    ]
  );
}

export default TerritoriesAutocomplete;
