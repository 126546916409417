import { createUseStyles } from "react-jss";
import { styles } from "../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 60000,
  },
  exit: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  wrapper: {
    padding: "0px 10px",
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 50000,
    // minHeight: "258px",
    width: "100%",
    maxWidth: "396px",
    transform: "translate(-50%, -50%)",
  },
  container: {
    backgroundColor: theme.background.primary,
    width: "100%",
    boxShadow: theme.shadow,
  },
  header: {
    padding: "10px 20px",
    borderBottom: `1px solid ${theme.stroke}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    "&.MuiButton-root": {
      minWidth: 20,
      minHeight: 30,
      marginRight: 0,

      ...styles(theme).button,
    },
  },
  closeIcon: {
    transform: "scale(1.3)",

    "& path": {
      fill: theme.grey,
    },
  },
  text: {
    marginBottom: 10,
    overflow: "auto",
    height: 100,
    whiteSpace: "pre-line",

    "@media (min-height: 300px)": {
      height: 150,
    },
    "@media (min-height: 480px)": {
      height: 200,
    },
    "@media (min-height: 680px)": {
      height: "100%",
    },
  },
  content: {
    padding: "10px 20px",
  },
  copyButton: {
    "&.MuiButton-root": {
      width: "100%",
      minHeight: 40,
      marginRight: 0,
      textTransform: "none",

      "&:hover": {
        backgroundColor: theme.primaryHard,
      },
      "&:active": {
        backgroundColor: theme.primaryHarder,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
}));

export default useStyles;
