import React from "react";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import useStyles from "./styles";

/**
 * This functional component renders a mobile switch.
 * @returns A mobile switch.
 */
function MobileSwitch({ marked, setMarked }) {
  MobileSwitch.propTypes = {
    marked: PropTypes.bool.isRequired,
    setMarked: PropTypes.func.isRequired,
  };

  const classes = useStyles();

  return (
    <Switch
      classes={{ root: classes.root }}
      checked={marked}
      onChange={setMarked}
    >
      <div
        className={classes.mark}
        style={{ transform: marked ? "translateX(10px)" : "translateX(-10px)" }}
      />
    </Switch>
  );
}

export default MobileSwitch;
