import * as React from "react";

function SvgDownBigArrow(props) {
  return (
    <svg
      width={14}
      height={11}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.424 10.683a.71.71 0 00.25.233.673.673 0 00.65 0 .71.71 0 00.25-.233l6.301-9.532a.755.755 0 00-.213-1.046A.676.676 0 0013.3 0H.7a.678.678 0 00-.362.107.722.722 0 00-.257.287.764.764 0 00.043.757l6.3 9.532z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgDownBigArrow;
