import { useMemo } from "react";

/* eslint-disable prefer-destructuring */
export default function useYears(initialTime, endTime) {
  return useMemo(() => {
    if (initialTime && endTime) {
      let yearInitialTime = initialTime;
      let yearEndTime = endTime;

      if (initialTime.includes("-")) {
        yearInitialTime = initialTime.split("-")[1];
      }

      if (endTime.includes("-")) {
        yearEndTime = endTime.split("-")[1];
      }

      if (yearInitialTime === yearEndTime) {
        return [yearInitialTime];
      }

      return [yearInitialTime, yearEndTime];
    }

    return null;
  }, [initialTime, endTime]);
}
