import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    padding: "24px 0px",

    "&:not(:last-of-type)": {
      "&::after": {
        content: "''",
        height: 1,
        backgroundColor: theme.stroke,
        position: "absolute",
        left: -16,
        right: -16,
        bottom: 0,
      },
    },
  },
  title: {
    marginBottom: 20,
  },
  optionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    userSelect: "none",

    "&:not(:last-of-type)": {
      marginBottom: 24,
    },
  },
  radio: {
    right: -2,
  },
}));

export default useStyles;
