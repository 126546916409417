import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTheme } from "react-jss";
import { useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import useStylesParent from "../styles";
import CustomTypography from "../../../CustomTypography";
import { breakpoints } from "../../../../constants/constraints";
import FiltersContext from "../../../../contexts/filtering";
import CustomTooltip from "../../../CustomTooltip";

/**
 * This function renders a component that handles the map legend
 * @returns The location component.
 */
function Legend({
  iconColors,
  legendColors,
  description,
  isClassification,
  tooltip,
  title1,
  children,
}) {
  Legend.propTypes = {
    iconColors: PropTypes.arrayOf(PropTypes.string).isRequired,
    legendColors: PropTypes.arrayOf(PropTypes.string).isRequired,
    description: PropTypes.arrayOf(PropTypes.string).isRequired,
    isClassification: PropTypes.bool,
    tooltip: PropTypes.string,
    title1: PropTypes.string,
    children: PropTypes.any,
  };

  Legend.defaultProps = {
    isClassification: false,
    tooltip: "",
    title1: "",
  };

  const {
    values: { analysis, filtersWaterClassification },
    setters: { setFiltersWaterClassification },
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const classesParent = useStylesParent();
  const collapseRef = useRef();
  const wrapperRef = useRef();
  const smd = useMediaQuery(breakpoints.max.smd);
  const biggerSmd = useMediaQuery(breakpoints.min.smd);
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState("#ffffff");

  /**
   * Set opened on big screens.
   */
  useEffect(() => {
    if (biggerSmd) {
      setOpen(true);
    }
  }, [biggerSmd]);

  /**
   * This function switches the collapse bar.
   */
  function handleOpenBar() {
    setOpen(!open);
  }

  /**
   * Generates the linear-gradient.
   */
  useEffect(() => {
    let colorAux = `linear-gradient(90deg`;
    const step = 100 / (legendColors.length - 1);

    legendColors.forEach((colorItem, index) => {
      if (index < legendColors.length) {
        colorAux += `,${colorItem} ${index * step}%`;
      } else {
        colorAux += `,${colorItem} 100%`;
      }
    });
    colorAux += ")";
    setColor(colorAux);
  }, [legendColors]);

  const handleFilters = (value) => {
    setFiltersWaterClassification((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  return useMemo(
    () => (
      <div
        className={classNames(
          classesParent.navigationWrapper,
          classes.container
        )}
        style={{
          borderRadius: open
            ? smd
              ? "0px 4px 4px 0px"
              : "4px 0px 0px 4px"
            : 4,
        }}
      >
        <CustomTooltip
          title={
            open
              ? t("dashboard.map.legend.close")
              : t("dashboard.map.legend.open")
          }
          placement={open ? "bottom" : "right"}
        >
          <div
            role="button"
            className={classNames(
              classesParent.navigationButton,
              classes.legendButton
            )}
            onClick={handleOpenBar}
            onKeyDown={() => {}}
            style={{
              borderRadius: open
                ? smd
                  ? "0px 4px 4px 0px"
                  : "4px 0px 0px 4px"
                : 4,
            }}
            tabIndex={-1}
          >
            <div
              className={classes.circle}
              style={{
                backgroundColor: iconColors[3],
                width: 24,
                height: 24,
                transform: "rotate(25deg)",
              }}
            >
              <div
                className={classes.circle}
                style={{
                  backgroundColor: iconColors[2],
                  width: 20,
                  height: 20,
                }}
              >
                <div
                  className={classes.circle}
                  style={{
                    backgroundColor: iconColors[1],
                    width: 16,
                    height: 16,
                  }}
                >
                  <div
                    className={classes.circle}
                    style={{
                      backgroundColor: iconColors[0],
                      width: 12,
                      height: 12,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </CustomTooltip>

        <CustomTooltip title={tooltip} placement="right">
          <div
            ref={collapseRef}
            style={{ width: open ? (isClassification ? 110 : 200) : 0 }}
            className={classes.collapse}
          >
            <div
              ref={wrapperRef}
              className={
                isClassification ? classes.verticalWrapper : classes.wrapper
              }
            >
              <div className={classes.values}>
                <div className={classes.title}>{title1}</div>
                {!isClassification && (
                  <div
                    className={classes.coloredDiv}
                    style={{
                      background: color,
                    }}
                  />
                )}
                {isClassification ? (
                  <div className={classes.verticalLabels}>
                    {description.map((value) => (
                      <div
                        role="button"
                        tabIndex={0}
                        key={value}
                        onClick={() => handleFilters(value)}
                        onKeyDown={() => handleFilters(value)}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          flexFlow: "row nowrap",
                        }}
                      >
                        <div
                          className={classes.legendPoint}
                          style={{
                            backgroundColor: theme[analysis][value],
                          }}
                        />
                        <CustomTypography
                          className={classes.label}
                          color={
                            filtersWaterClassification[value]
                              ? theme.text.primary
                              : theme.text.tertiary
                          }
                          weight="bold"
                        >
                          {t(`dashboard.map.legend.${analysis}.${value}`)}
                        </CustomTypography>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={classes.labels}>
                    {description.map((value) => (
                      <CustomTypography
                        key={value}
                        className={classes.label}
                        color={theme.text.legend}
                        weight="bold"
                      >
                        {value}
                      </CustomTypography>
                    ))}
                  </div>
                )}
                {children}
              </div>
            </div>
          </div>
        </CustomTooltip>
      </div>
    ),
    [filtersWaterClassification, description, open, color, analysis, tooltip]
  );
}

export default Legend;
