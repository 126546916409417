import { createUseStyles } from "react-jss";
import { sizes, styles } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    "&.MuiButton-root": {
      minWidth: 30,
      minHeight: 30,
      marginRight: 0,

      ...styles(theme).button,
    },
  },
  closeIcon: {
    transform: "scale(1.3)",

    "& path": {
      fill: theme.grey,
    },
  },
  content: {
    position: "fixed",
    top: sizes.header.height,
    left: 0,
    right: 0,
    height: `calc(100% - ${sizes.header.height + 81}px)`,
    overflowY: "auto",
    overflowX: "hidden",
    bottom: 0,
    padding: "0px 16px",
  },
  buttonContainer: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: 81,
    backgroundColor: theme.background.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 32px",
  },
  confirmButton: {
    "&.MuiButton-root": {
      minWidth: "100%",
      minHeight: 48,
      marginRight: 0,
      textTransform: "none",

      "&:hover": {
        backgroundColor: theme.primaryHard,
      },
      "&:active": {
        backgroundColor: theme.primaryHarder,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
}));

export default useStyles;
