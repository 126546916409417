import * as React from "react";

function SvgDownArrow(props) {
  return (
    <svg
      width={14}
      height={8}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.316.342A1.06 1.06 0 012.541.54L7 5 11.46.54a1.06 1.06 0 011.5 1.5L7.706 7.294a1 1 0 01-1.414 0L1.04 2.04a1.06 1.06 0 01.275-1.7z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgDownArrow;
