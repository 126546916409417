import { createUseStyles } from "react-jss";
import { styles } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 60000,
  },
  exit: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  wrapper: {
    padding: "0px 10px",
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: 50000,
    minHeight: "258px",
    width: "100%",
    maxWidth: "396px",
    transform: "translate(-50%, -50%)",
  },
  container: {
    backgroundColor: theme.background.primary,
    width: "100%",
    height: "100%",
    boxShadow: theme.shadow,
  },
  header: {
    padding: "10px 20px",
    borderBottom: `1px solid ${theme.stroke}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    "&.MuiButton-root": {
      minWidth: 20,
      minHeight: 30,
      marginRight: 0,

      ...styles(theme).button,
    },
  },
  closeIcon: {
    transform: "scale(1.3)",

    "& path": {
      fill: theme.grey,
    },
  },
  content: {
    padding: "10px 20px",
  },
  textfieldRoot: {
    width: "100%",

    "& .MuiFormLabel-root": {
      color: theme.text.gray,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiIconButton-root": {
      color: theme.text.primary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.stroke,
      transition: "border-color .2s ease",
    },
    "& .MuiOutlinedInput-root:hover": {
      transition: "background-color 0.15s ease",
      borderColor: theme.text.primary,
      backgroundColor: theme.background.secondary,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.text.primary,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.primary,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.primary,
    },
    "& .MuiInputBase-input": {
      color: theme.text.primary,
    },
    "& .MuiAutocomplete-popupIndicatorOpen": {
      transform: "rotate(0deg)",
    },
  },
  copyButton: {
    "&.MuiButton-root": {
      width: "100%",
      maxWidth: 232,
      minHeight: 40,
      marginRight: 0,
      textTransform: "none",

      "&:hover": {
        backgroundColor: theme.primaryHard,
      },
      "&:active": {
        backgroundColor: theme.primaryHarder,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
  copyButtonWrapper: {
    position: "relative",
    marginTop: 30,
    marginBottom: 10,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  copiedText: {
    position: "absolute",
    top: -23,
  },
  shareButton: {
    transition: "all 0.3s ease",

    "&.MuiButton-root": {
      minWidth: 34,
      minHeight: 30,

      ...styles(theme).button,
    },
  },
  whatsappIcon: {
    "& path": {
      fill: theme.share.whatsapp,
    },
  },
  facebookIcon: {
    "& path": {
      fill: theme.share.facebook,
    },
  },
  twitterIcon: {
    "& path": {
      fill: theme.share.twitter,
    },
  },
  socialNetworksWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
