import React, { useContext } from "react";
import MapTimeline from "../../../components/Dashboard/MapView/Timeline";
import FiltersContext from "../../../contexts/filtering";

function Timeline() {
  const {
    options: { timeOptions, analysisOptions },
    values: { timeMode, analysis },
  } = useContext(FiltersContext);

  if (!timeOptions) {
    return null;
  }

  return (
    <MapTimeline
      values={timeMode === "annual" ? timeOptions.years : timeOptions.months}
      availableTimeOptions={
        analysis === analysisOptions.surface?.key &&
        timeOptions.months.length > 0
          ? "both"
          : "annual"
        // "annual"
      }
    />
  );
}

export default Timeline;
