import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    outline: "none",
  },
  icon: {
    transform: "scale(0.5)",
    marginTop: -14,

    "& path": {
      fill: theme.primary,
    },
  },
}));

export default useStyles;
