import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    boxShadow: theme.floatingShadow,
  },
  navigationButton: {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
  zoomButton: {
    "&:first-of-type": {
      borderRadius: "4px 4px 0px 0px",
    },
    "&:last-of-type": {
      borderRadius: "0px 0px 4px 4px",
    },
    "&:hover": {
      backgroundColor: theme.background.secondary,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.hover,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:focus": {
      outline: `none`,
    },
  },
}));

export default useStyles;
