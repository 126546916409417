import * as React from "react";

function SvgClose(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.323 4.497l.096.084a.937.937 0 01.084 1.23l-.084.095L10.326 9l3.093 3.094a.937.937 0 01.084 1.23l-.084.095a.937.937 0 01-1.23.084l-.095-.084L9 10.326l-3.094 3.093a.937.937 0 01-1.23.084l-.095-.084a.938.938 0 01-.084-1.23l.084-.095L7.674 9 4.581 5.906a.938.938 0 01-.084-1.23l.084-.095a.938.938 0 011.23-.084l.095.084L9 7.674l3.094-3.093a.938.938 0 011.23-.084l.095.084-.096-.084z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgClose;
