import { createUseStyles } from "react-jss";
import {
  styles,
  transitions,
  breakpoints,
  sizes,
} from "../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "absolute",
    top: 20,
    right: 16,
    width: sizes.statistiscs.width,
    bottom: 96,
    background: theme.background.primary,
    transition: transitions.background,
    borderRadius: "4px",
    zIndex: 31000,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    [breakpoints.max.smd]: {
      position: "relative",
      right: 0,
      top: 0,
      bottom: 0,
      width: "100%",
      marginTop: -sizes.statistiscs.header.height,
    },
  },
  collide: {
    top: 70,
  },
  colapse: {
    width: 170,
    height: 0,
  },
  childrenContainer: {
    width: "100%",
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "auto",
    overflowX: "hidden",
    borderTop: "solid 1px",
    borderColor: theme.filters.timeline.separator,

    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.background.secondary,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.filters.timeline.separator,
      borderRadius: 10,
    },
  },
  children: {
    padding: 20,
    "& > *:last-child": {
      marginBottom: 0,
    },
    "& > *:first-child": {
      marginTop: 0,
    },
  },
  hideBorder: {
    borderTop: "none",
  },
  statisticsHeader: {
    background: theme.background.primary,
    borderRadius: "inherit",
    padding: "10px 14px 10px 20px",
    width: "100%",
    height: sizes.statistiscs.header.height,
    display: "flex",
    flexDirection: "horizontal",
    alignItems: "center",
    justifyContent: "space-between",
  },
  nextButton: {
    "&.MuiButton-root": {
      minWidth: 30,
      minHeight: 30,

      ...styles(theme).button,
    },
  },
  nextIcon: {
    transition: "transform 0.3s ease",

    "& path": {
      fill: theme.text.primary,
    },
  },
}));

export default useStyles;
