/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { Fade, Paper, useMediaQuery } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import SyncLoader from "react-spinners/SyncLoader";
import useStyles from "./styles";
import SvgClose from "../../../Icons/Close";
import { breakpoints, sizes } from "../../../../constants/constraints";
import FiltersContext from "../../../../contexts/filtering";
import useOptions from "../../../../hooks/options";

/**
 * This function renders a container to comport the gif.
 * @returns The gif container.
 */
function TrendChartContainer({
  containerRef,
  chartData,
  handleClose,
  opened,
  loading,
  timeMode,
}) {
  TrendChartContainer.defaultProps = {
    chartData: undefined,
    timeMode: "monthly",
  };

  TrendChartContainer.propTypes = {
    chartData: PropTypes.shape(),
    handleClose: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    timeMode: PropTypes.string,
    containerRef: PropTypes.any.isRequired,
  };

  const classes = useStyles();
  const {
    values: { statisticsOpened },
  } = useContext(FiltersContext);
  const theme = useTheme();
  const defaultOptions = useOptions();
  const isSmd = useMediaQuery(breakpoints.max.smd);
  const isSm = useMediaQuery(breakpoints.max.sm);
  const isXsm = useMediaQuery(breakpoints.max.xsm);
  const { t } = useTranslation();
  const chartRef = useRef();
  let options = {};

  if (chartData) {
    options = {
      credits: defaultOptions.credits,
      chart: {
        type: "line",
        ...defaultOptions.chart,
        height: 230,
        width: 300,
        spacing: [10, 10, 15, 10],
      },
      title: {
        text: t("dashboard.map.pixelSelection.title"),
        ...defaultOptions.title,
      },
      xAxis: {
        ...defaultOptions.xAxis,
        labels: {
          style: {
            fontSize: "10px",
            color: theme.text.secondary,
            ...defaultOptions.yAxis.labels.style,
          },
          formatter() {
            if (timeMode === "monthly") {
              return t("general.date.monthYear", {
                date: new Date(this.value),
              });
            }
            return t("general.date.year", { date: new Date(this.value) });
          },
        },
        categories: chartData.x,
      },
      yAxis: {
        ...defaultOptions.yAxis,
        labels: {
          formatter() {
            return `${this.value}`;
          },
          style: {
            fontSize: "10px",
            color: theme.text.secondary,
            ...defaultOptions.yAxis.labels.style,
          },
        },
      },
      tooltip: {
        useHTML: true,
        formatter() {
          if (timeMode === "monthly") {
            return `<b>${t("general.date.monthNameYear", {
              date: new Date(this.point.category),
            })}</b> </br>
            <tr><td style="color: ${this.series.color}">
            ${this.series.name}: </td>
              <td style="text-align: right"><b>${t("general.number", {
                value: this.point.y,
              })} ha
            </b></td></tr>`;
          }
          return `<b>${t("general.date.year", {
            date: new Date(this.point.category),
          })}</b> </br>
              <tr><td style="color: ${this.series.color}">${
            this.series.name
          }: </td>
                <td style="text-align: right"><b>${t("general.roundNumber", {
                  value: this.point.y,
                })} ha
              </b></td></tr>`;
        },
      },
      legend: { ...defaultOptions.legend, enabled: true },
      series: chartData.series,
      navigation: defaultOptions.navigation,
      lang: defaultOptions.lang,
      exporting: {
        ...defaultOptions.exporting,
        buttons: {
          contextButton: {
            x: -33,
            ...defaultOptions.exporting.buttons.contextButton,
          },
        },
      },
    };
  }

  return (
    <Fade in={opened || loading}>
      <Paper>
        <div
          className={classes.container}
          style={
            isSmd
              ? {}
              : {
                  right:
                    sizes.map.navigation.itemsMargin +
                    (statisticsOpened
                      ? sizes.statistiscs.width +
                        sizes.map.navigation.itemsMargin
                      : 0),
                }
          }
          ref={containerRef}
        >
          {!loading && (
            <div
              role="button"
              className={classes.clickableButton}
              onClick={() => {
                handleClose();
              }}
              onKeyDown={() => {}}
              tabIndex={0}
            >
              <SvgClose
                className={classes.navigationIcon}
                style={{ transform: "scale(1)" }}
              />
            </div>
          )}
          {loading && (
            <div className={classes.gifLoaderWrapper}>
              <SyncLoader color={theme.primary} size={10} />
            </div>
          )}
          {!loading && (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </div>
      </Paper>
    </Fade>
  );
}

export default TrendChartContainer;
