import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((/* theme */) => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  childrenWrapper: {
    padding: "0px 20px",
  },
}));

export default useStyles;
