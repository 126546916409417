/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import CustomTypography from "../../../../../CustomTypography";
import SvgDownArrow from "../../../../../Icons/DownArrow";
import SvgClose from "../../../../../Icons/Close";

/**
 * This functional component renders a selector to be used on PeriodSelector.
 * @returns The time selector.
 */
function Selector({ title, value, setValue, options }) {
  Selector.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape(),
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    setValue: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(),
        PropTypes.number,
        PropTypes.string,
      ])
    ).isRequired,
  };

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [optionListValue, setOptionListValue] = useState(value);

  return (
    <div className={classes.container}>
      <CustomTypography
        variant="title"
        weight="bold"
        color={theme.text.tertiary}
      >
        {`${title}:`}
      </CustomTypography>
      <Button className={classes.button} onClick={() => setOpen(true)}>
        <CustomTypography
          variant="contrast"
          weight="bold"
          color={theme.text.primary}
        >
          {value.value ? t(value.translation) : value}
        </CustomTypography>
        <SvgDownArrow className={classes.arrowIcon} />
      </Button>
      {open && (
        <>
          <div
            tabIndex={0}
            role="button"
            className={classes.optionsContainerRest}
            onClick={() => setOpen(false)}
            onKeyDown={() => setOpen(false)}
          />
          <div className={classes.optionsContainer}>
            <div className={classes.optionsTitle}>
              <CustomTypography
                variant="contrast"
                weight="bold"
                color={theme.text.primary}
              >
                {title}
              </CustomTypography>
              <Button
                className={classes.closeButton}
                onClick={() => {
                  setOpen(false);
                  if (value.value) {
                    setOptionListValue(value);
                  } else {
                    setOptionListValue(value.toString());
                  }
                }}
              >
                <SvgClose className={classes.closeIcon} />
              </Button>
            </div>
            <div className={classes.optionsList}>
              {options && value.value
                ? options.map((option) => (
                    <Button
                      key={option.value}
                      className={
                        optionListValue.value === option.value
                          ? classes.optionButtonSelected
                          : classes.optionButton
                      }
                      onClick={() => setOptionListValue(option)}
                    >
                      <CustomTypography
                        variant="contrast"
                        weight="bold"
                        color={theme.text.primary}
                      >
                        {t(option.translation)}
                      </CustomTypography>

                      {option.beta && (
                        <CustomTypography
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            fontSize: 10,
                          }}
                          variant="legend"
                        >
                          BETA
                        </CustomTypography>
                      )}
                    </Button>
                  ))
                : options.map((option) => (
                    <Button
                      key={option}
                      className={
                        optionListValue.toString() === option.toString()
                          ? classes.optionButtonSelected
                          : classes.optionButton
                      }
                      onClick={() => setOptionListValue(option.toString())}
                    >
                      <CustomTypography
                        variant="contrast"
                        weight="bold"
                        color={theme.text.primary}
                      >
                        {option}
                      </CustomTypography>

                      {option.beta && (
                        <CustomTypography
                          style={{
                            color: "red",
                            marginLeft: "5px",
                            fontSize: 10,
                          }}
                          variant="legend"
                        >
                          BETA
                        </CustomTypography>
                      )}
                    </Button>
                  ))}
            </div>
            <div className={classes.selectButtonWrapper}>
              <Button
                className={classes.selectButton}
                variant="contained"
                disableElevation
                color="primary"
                onClick={() => {
                  setOpen(false);
                  if (value.value) {
                    setValue(optionListValue.value);
                  } else {
                    setValue(optionListValue);
                  }
                }}
              >
                <CustomTypography variant="contrast" color={theme.white}>
                  {t("dashboard.filters.mobile.select")}
                </CustomTypography>
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Selector;
