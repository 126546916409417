import { createUseStyles } from "react-jss";
import { sizes } from "../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.background.primary,
    zIndex: 40000,
  },
  headerWrapper: {
    width: "100%",
    borderBottom: `solid 1px ${theme.stroke}`,
    height: sizes.header.height,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.background.primary,
    position: "relative",
    padding: "0px 16px",
  },
  contentWrapper: {
    position: "relative",
    width: "100%",
    padding: "0px 16px",
  },
}));

export default useStyles;
