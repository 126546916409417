import { createUseStyles } from "react-jss";
import { breakpoints, sizes, styles } from "../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  containerDesktop: {
    position: "relative",
    width: "100%",
    display: "flex",
  },
  containerMobile: {
    position: "fixed",
    width: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: theme.background.primary,
  },
  autocompleteInput: {
    "& .MuiInputBase-input": {
      color: theme.text.primary,
    },
  },
  acronym: {
    color: theme.text.tertiary,
    opacity: 0.7,
    fontWeight: 700,
    fontSize: 10,
  },
  partText: {
    fontFamily: "Manrope",
    fontWeight: 400,
    color: theme.text.primary,
  },
  listbox: {
    boxSizing: "border-box",

    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
  defaultWrapper: {
    width: "100%",
  },
  mobileWrapper: {
    width: "100%",
    borderBottom: `solid 1px ${theme.stroke}`,
    height: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.background.primary,
    position: "relative",
  },
  root: {
    width: "100%",

    "& .MuiFormLabel-root": {
      color: theme.text.primary,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiIconButton-root": {
      color: theme.text.primary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.stroke,
      transition: "border-color .2s ease",
    },
    "& .MuiOutlinedInput-root:hover": {
      transition: "background-color 0.15s ease",
      borderColor: theme.text.primary,
      backgroundColor: theme.background.secondary,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.text.primary,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.primary,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.primary,
    },
    "& .MuiInputBase-input": {
      color: theme.colorText,
    },
    "& .MuiAutocomplete-popupIndicatorOpen": {
      transform: "rotate(0deg)",
    },
    [breakpoints.max.smd]: {
      "& .MuiFormLabel-root": {
        color: "transparent",
      },
      "& .MuiIconButton-root": {
        color: theme.text.primary,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "& .MuiOutlinedInput-root:hover": {
        backgroundColor: "transparent",
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "transparent",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "& .MuiInputBase-input": {
        color: theme.colorText,
      },
    },
  },
  popperWrapper: {
    position: "fixed",
    top: sizes.header.height,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "hidden !important",
  },
  popper: {
    position: "absolute",
    zIndex: 10000,

    "& .MuiPaper-root": {
      marginTop: 5,
      backgroundColor: theme.background.primary,
      color: theme.text.primary,
      borderRadius: 8,
      boxShadow: theme.shadow,
    },
    "& .MuiListSubheader-root": {
      color: theme.primary,
    },
    "& .MuiListSubheader-sticky": {
      position: "relative",
    },
    "& .MuiAutocomplete-listbox": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiAutocomplete-option": {
      paddingTop: 10,
      paddingBottom: 10,
      transition: "all 0.1s ease",
    },
    '& .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: theme.background.secondary,

      "&:active": {
        backgroundColor: theme.hover,
      },
    },
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: theme.hover,
    },
    [breakpoints.max.smd]: {
      position: "fixed",
      width: "100% !important",
      transform: "translate3d(0px, 0px, 0px) !important",
      height: `100%`,
      overflowY: "hidden !important",

      "& .MuiPaper-root": {
        marginTop: 0,
        position: "fixed",
        backgroundColor: "transparent",
        color: "transparent",
        borderRadius: 0,
        boxShadow: "none",
        overflowY: "hidden !important",
      },
      "& .MuiAutocomplete-listbox": {
        position: "fixed",
        width: "100%",
        height: `100%`,
        maxHeight: `100%`,
      },
      "& .MuiAutocomplete-option": {
        position: "relative",

        "&:not(:last-of-type)": {
          "&::after": {
            content: "''",
            left: 16,
            right: 16,
            position: "absolute",
            bottom: 0,
            height: 1,
            backgroundColor: theme.stroke,
          },
          "& + .MuiListSubheader-root": {
            "&::after": {
              content: "''",
              left: 16,
              right: 16,
              position: "absolute",
              top: -1,
              height: 1,
              backgroundColor: theme.background.primary,
            },
          },
        },
      },
      '& .MuiAutocomplete-option[data-focus="true"]': {
        backgroundColor: theme.background.secondary,
      },
      '& .MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: "transparent",

        "&:hover": {
          backgroundColor: theme.background.secondary,
        },
      },
    },
  },
  searchIcon: {
    position: "absolute",
    right: 5,
    top: -1,
    pointerEvents: "none",

    "& path": {
      fill: theme.text.primary,
    },
    [breakpoints.max.smd]: {
      display: "none",
    },
  },
  backArrow: {
    transform: "scale(1.5)",

    "& path": {
      fill: theme.text.primary,
    },
  },
  backButton: {
    "&.MuiButton-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 10,
      minWidth: 30,
      minHeight: 30,
      ...styles(theme).button,
    },
  },
  placeIcon: {
    transform: "scale(1.2)",
    marginRight: 15,

    "& path": {
      fill: theme.text.primary,
    },
  },
  option: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
