import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useStyles from "./styles";
import useStylesParent from "../styles";
import SvgAdd from "../../../Icons/Add";
import SvgMinus from "../../../Icons/Minus";

/**
 * This function renders a zoom component to map.
 * @returns The Zoom component.
 */
function Zoom({ value, setValue }) {
  Zoom.propTypes = {
    value: PropTypes.number.isRequired,
    setValue: PropTypes.func.isRequired,
  };

  const classes = useStyles();
  const classesParent = useStylesParent();

  /**
   * This function change the viewport zoom value.
   * @param {Increment or decrement value} change
   */
  function changeZoom(change) {
    if (change > 0) {
      setValue(value + change < 24 ? value + change : value);
    } else {
      setValue(value + change > 0 ? value + change : value);
    }
  }

  return (
    <div
      className={classNames(classesParent.navigationWrapper, classes.container)}
    >
      <div
        role="button"
        className={classNames(
          classesParent.navigationButton,
          classes.zoomButton
        )}
        onClick={() => changeZoom(1)}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <SvgAdd className={classesParent.navigationIcon} />
      </div>
      <div
        role="button"
        className={classNames(
          classesParent.navigationButton,
          classes.zoomButton
        )}
        onClick={() => changeZoom(-1)}
        onKeyDown={() => {}}
        tabIndex={-1}
      >
        <SvgMinus className={classesParent.navigationIcon} />
      </div>
    </div>
  );
}

export default Zoom;
