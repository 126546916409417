import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    display: "flex",
    boxShadow: theme.floatingShadow,
  },
  legendButton: {
    position: "relative",
    borderRadius: 4,
    zIndex: 1000,

    "&:hover": {
      backgroundColor: theme.background.secondary,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.hover,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:focus": {
      outline: `none`,
    },
  },
  collapse: {
    position: "absolute",
    transition: "width .3s ease",
    overflow: "hidden",
    left: 45,
    borderRadius: "4px",
    boxShadow: theme.floatingShadow,
    zIndex: -1,

    [breakpoints.max.smd]: {
      left: "initial",
      right: 40,
      borderRadius: "4px 0px 0px 4px",
    },
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    fontWeight: 700,
    marginBottom: "5px",
  },
  verticalWrapper: {
    display: "flex",
    flexDirection: "horizontal",
    alignItems: "center",
    backgroundColor: "pink",
  },
  wrapper: {
    display: "flex",
    flexDirection: "horizontal",
    alignItems: "center",
    width: 200,
  },
  values: {
    backgroundColor: theme.background.primary,
    borderLeft: `solid 1px ${theme.background.secondary}`,
    padding: "6px 8px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    [breakpoints.max.smd]: {
      borderLeft: `none`,
      borderRight: `solid 1px ${theme.background.secondary}`,
    },
  },
  coloredDiv: {
    marginTop: 3,
    width: "100%",
    height: 10,
  },
  verticalLabels: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    height: 100,
    width: "100%",
  },
  labels: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  label: {
    fontSize: 10,
  },
  legendPoint: {
    width: 15,
    height: 15,
    minWidth: 15,
    minHeight: 15,
    marginRight: 8,
    borderRadius: "100%",
  },
  circle: {
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
