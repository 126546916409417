import * as React from "react";

function SvgAbout(props) {
  return (
    <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#555">
        <path d="M14.464 12.37l-4.24 4.501a1.683 1.683 0 01-2.448 0l-4.24-4.501h.016l-.008-.009-.008-.01a7.146 7.146 0 1110.929 0l-.01.01-.007.009h.016zm-.816-.681a6.095 6.095 0 10-9.295 0l.129.153L8.54 16.15a.631.631 0 00.918 0l4.06-4.308.129-.153z" />
        <path d="M8.365 9.836a.491.491 0 00.502.522.545.545 0 00.536-.523l.004-.065c.011-.153.066-.319.166-.498.11-.202.275-.383.493-.543.221-.164.424-.35.609-.555a2.98 2.98 0 00.526-.795c.133-.293.199-.61.199-.949 0-.274-.042-.537-.127-.79a1.971 1.971 0 00-.388-.674 1.887 1.887 0 00-.847-.583A3.106 3.106 0 008.97 4.2c-.285 0-.567.046-.848.137-.28.088-.53.227-.747.418a1.93 1.93 0 00-.548.743c-.06.138-.107.28-.144.424-.07.276.159.52.444.52.276 0 .488-.234.575-.496l.005-.013a.963.963 0 01.266-.406c.118-.122.266-.212.443-.27a1.9 1.9 0 01.576-.085c.214 0 .419.034.614.103.2.065.366.181.498.349.167.198.25.472.25.823 0 .198-.026.385-.078.56a1.344 1.344 0 01-.288.51c-.166.183-.334.339-.504.468-.17.126-.328.256-.476.39a1.745 1.745 0 00-.382.52c-.118.22-.195.45-.232.686a4.557 4.557 0 00-.03.255zm-.016 2.244a.52.52 0 101.041 0v-.212a.52.52 0 00-1.04 0v.212z" />
      </g>
    </svg>
  );
}

export default SvgAbout;
