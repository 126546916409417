import { createUseStyles } from "react-jss";
import {
  sizes,
  transitions,
  styles,
  breakpoints,
} from "../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  containerDesktop: {
    position: "absolute",
    top: 0,
    left: 0,
    height: sizes.header.height,
    backgroundColor: theme.background.primary,
    transition: transitions.background,
    boxShadow: theme.shadow,
    borderRadius: "0px 0px 8px 0px",
    zIndex: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    [breakpoints.max.smd]: {
      display: "none",
    },
  },
  containerMobile: {
    position: "absolute",
    top: 8,
    left: 12,
    right: 12,
    height: 56,
    backgroundColor: theme.background.primary,
    transition: transitions.background,
    boxShadow: theme.shadow,
    borderRadius: 8,
    zIndex: 50000,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    [breakpoints.min.smd]: {
      display: "none",
    },
  },
  collapse: {
    transition: "width .3s ease",
    overflow: "hidden",
  },
  wrapper: {
    display: "flex",
    flexDirection: "horizontal",
    alignItems: "center",
    width: 706,
    height: sizes.header.height,
  },
  backButton: {
    transition: "all 0.3s ease",

    "&.MuiButton-root": {
      minWidth: 30,
      minHeight: 30,

      ...styles(theme).button,
    },
  },
  backIcon: {
    transition: "transform 0.3s ease",

    "& path": {
      fill: theme.text.primary,
    },
  },
  houseButton: {
    transition: "all 0.3s ease",

    "&.MuiButton-root": {
      minWidth: 34,
      minHeight: 30,

      ...styles(theme).button,
    },
  },
  houseIcon: {
    "& path": {
      fill: "transparent",
      stroke: theme.text.primary,
    },
    "& rect": {
      fill: theme.text.primary,
    },
    "& circle": {
      fill: theme.text.primary,
    },
  },
  resetButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&.MuiButton-root": {
      minWidth: 34,
      minHeight: 30,
      marginRight: 10,

      ...styles(theme).button,
    },
  },
  filterButton: {
    "&.MuiButton-root": {
      minWidth: 34,
      minHeight: 30,
      marginRight: 10,

      ...styles(theme).button,
    },
  },
  filterIcon: {
    transform: "scale(1.1)",

    "& path": {
      fill: theme.text.primary,
    },
  },
}));

export default useStyles;
