/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    pointerEvents: "none",
  },
  wrapper: {
    position: "absolute",
    pointerEvents: "all",
    backgroundColor: theme.background.primary,
    left: 45,
    bottom: 0,
    borderRadius: 4,
    boxShadow: theme.floatingShadow,
    zIndex: 31000,

    [breakpoints.max.smd]: {
      left: "initial",
      right: 45,
      bottom: 0,
    },
  },
}));

export default useStyles;
