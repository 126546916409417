import * as React from "react";

function SvgOptions(props) {
  return (
    <svg
      width={4}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={2} cy={1.778} r={1.778} fill="#000" />
      <circle cx={2} cy={8} r={1.778} fill="#000" />
      <circle cx={2} cy={14.222} r={1.778} fill="#000" />
    </svg>
  );
}

export default SvgOptions;
