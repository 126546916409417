import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "absolute",
    minHeight: 100,
    minWidth: 100,
    maxHeight: 400,
    maxWidth: 400,
    bottom: 96,
    zIndex: 30000,
    backgroundColor: theme.background.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    padding: 5,
    transition: "all 0.3s ease",

    [breakpoints.max.smd]: {
      bottom: 41,
      right: 57,
    },
  },
  clickableButton: {
    backgroundColor:
      theme.mode === "dark" ? "transparent" : theme.background.primary,
    transition: "background-color 0.2s ease",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: theme.background.secondary,
    },
    "&:active": {
      backgroundColor: theme.hover,
    },
    "&:focus": {
      outline: `none`,
    },
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 5,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,

    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: "50%",
      width: 0,
      height: 0,
    },
  },
  gifLoaderWrapper: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    position: "relative",
    objectFit: "contain",
    backgroundColor: theme.map.selection.gifBackground,
  },
  navigationIcon: {
    position: "relative",

    "& path": {
      fill: theme.text.primary,
    },
  },
}));

export default useStyles;
