import React from "react";
import { useTheme } from "react-jss";
import { useTranslation } from "react-i18next";
import useGeneralStyles from "../styles";
import useStyles from "./styles";
import CustomTypography from "../../../CustomTypography";
import TriangleSvg from "../../../../assets/images/transitions_pyramid.svg";
/* import MapContext from "../../../../contexts/mapping";
import { isTouchDevice } from "../../../../constants/utils";
import { darkScheme } from "../../../../constants/schemes"; */

export default function TransitionsTriangle() {
  const theme = useTheme();
  const generalClasses = useGeneralStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  /*   const { pointedColor, hoveringFeatures } = useContext(MapContext);
  const [pointerVisible, setPointerVisible] = useState(false);

  const blueY = (-480 * pointedColor.b) / 255; // Max = -490%
  const blueX = 0; // Max = 0%
  const redY = (260 * pointedColor.r) / 255; // Max = 260%
  const redX = (450 * pointedColor.r) / 255; // Max = 450%
  const greenY = (260 * pointedColor.g) / 255; // Max = 260%
  const greenX = (-450 * pointedColor.g) / 255; // Max = -450%
  const y = blueY + redY + greenY;
  const x = blueX + redX + greenX; */

  /**
   * Add pointer exceptions.
   */
  /* useEffect(() => {
    let feature =
      hoveringFeatures &&
      hoveringFeatures.find((f) => f.layer.id === "focusData");

    if (!feature) {
      feature =
        hoveringFeatures &&
        hoveringFeatures.find((f) => f.layer.id.includes("label"));
    }

    if (isTouchDevice() || feature) {
      setPointerVisible(false);
    } else if (theme === darkScheme) {
      if (
        pointedColor.r === pointedColor.g &&
        pointedColor.r === pointedColor.b
      ) {
        setPointerVisible(false);
      } else if (
        pointedColor.r === 42 &&
        pointedColor.g === 44 &&
        pointedColor.b === 44
      ) {
        setPointerVisible(false);
      } else if (
        pointedColor.r === 27 &&
        pointedColor.g === 27 &&
        pointedColor.b === 29
      ) {
        setPointerVisible(false);
      } else {
        setPointerVisible(true);
      }
    } else if (
      pointedColor.r === pointedColor.g &&
      pointedColor.r === pointedColor.b
    ) {
      setPointerVisible(false);
    } else if (
      pointedColor.r === 225 &&
      pointedColor.g === 229 &&
      pointedColor.b === 229
    ) {
      setPointerVisible(false);
    } else if (
      pointedColor.r === 240 &&
      pointedColor.g === 242 &&
      pointedColor.b === 242
    ) {
      setPointerVisible(false);
    } else if (
      pointedColor.r === 233 &&
      pointedColor.g === 235 &&
      pointedColor.b === 235
    ) {
      setPointerVisible(false);
    } else if (
      pointedColor.r === 194 &&
      pointedColor.g === 200 &&
      pointedColor.b === 202
    ) {
      setPointerVisible(false);
    } else {
      setPointerVisible(true);
    }
  }, [hoveringFeatures]); */

  return (
    <div className={generalClasses.wrapper}>
      <CustomTypography variant="title">
        {t("dashboard.statistics.charts.waterTransitions.triangle.title")}
      </CustomTypography>
      <div className={classes.innerWrapper}>
        <div className={classes.line}>
          <div className={classes.triangleWrapper}>
            <img
              src={TriangleSvg}
              alt="triangleSvg"
              className={classes.triangleImg}
            />
            {/* {pointerVisible && (
              <div className={classes.pointerWrapper}>
                <div
                  className={classes.pointer}
                  style={{
                    transform: `translate(${x}%, ${y}%)`,
                  }}
                />
              </div>
            )} */}
          </div>
          <div className={classes.column}>
            <div className={classes.lineItem}>
              <div
                className={classes.pointColor}
                style={{ backgroundColor: "#2AE510" }}
              />
              <CustomTypography color={theme.text.legend} variant="subStats">
                {t(
                  "dashboard.statistics.charts.waterTransitions.triangle.addition"
                )}
              </CustomTypography>
            </div>
            <div className={classes.lineItem}>
              <div
                className={classes.pointColor}
                style={{ backgroundColor: "#FA0E0D" }}
              />
              <CustomTypography color={theme.text.legend} variant="subStats">
                {t(
                  "dashboard.statistics.charts.waterTransitions.triangle.decrease"
                )}
              </CustomTypography>
            </div>
            <div className={classes.lineItem}>
              <div
                className={classes.pointColor}
                style={{ backgroundColor: "#0800FF" }}
              />
              <CustomTypography color={theme.text.legend} variant="subStats">
                {t(
                  "dashboard.statistics.charts.waterTransitions.triangle.persistent"
                )}
              </CustomTypography>
            </div>
            <div className={classes.lineItem}>
              <div
                className={classes.pointColor}
                style={{
                  backgroundColor: "#000000",
                  borderColor: "#555555",
                  border: "solid 1px",
                }}
              />
              <CustomTypography color={theme.text.legend} variant="subStats">
                {t(
                  "dashboard.statistics.charts.waterTransitions.triangle.sporadic"
                )}
              </CustomTypography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
