/* eslint-disable react/no-this-in-sfc */
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "react-jss";
import highchartsMore from "highcharts/highcharts-more";
import sankey from "highcharts/modules/sankey";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { InfoOutlined } from "@material-ui/icons";
import useStyles from "../styles";
import useOptions from "../../../../hooks/options";
import FiltersContext from "../../../../contexts/filtering";
import CustomTooltip from "../../../CustomTooltip";

highchartsMore(Highcharts);
sankey(Highcharts);

export default function Sankey({ title, info, data, analysis }) {
  Sankey.propTypes = {
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    analysis: PropTypes.string.isRequired,
  };

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const classes = useStyles({ theme });
  const defaultOptions = useOptions();
  const [chartObj, setChartObj] = useState();
  const { values } = useContext(FiltersContext);
  const { t } = useTranslation();

  Highcharts.seriesTypes.sankey.prototype.pointAttribs = function gradient(
    point,
    state
  ) {
    let opacity = this.options.linkOpacity;
    let { color } = point;

    if (state) {
      opacity = this.options.states[state].linkOpacity || opacity;
      color = this.options.states[state].color || point.color;
    }

    return {
      fill: point.isNode
        ? color
        : {
            linearGradient: {
              x1: 0,
              x2: 1,
              y1: 0,
              y2: 0,
            },
            stops: [
              [0, Highcharts.color(color).setOpacity(opacity).get()],
              [
                1,
                Highcharts.color(point.toNode.color).setOpacity(opacity).get(),
              ],
            ],
          },
    };
  };

  /**
   * This useEffect update years delimiters on sankey chart.
   */
  useEffect(() => {
    if (chartObj) {
      chartObj.update({
        subtitle: {
          text:
            "<div style = 'width: 100%; margin-top: 20px; display: flex; flex-flow: row nowrap; justify-content: space-between'}> " +
            `<div>${values.years[0]}</div>` +
            `<div>${values.years[1]}</div>` +
            "</div>",
        },
      });
    }
  }, [values.years]);

  const options = {
    chart: {
      type: "sankey",
      ...defaultOptions.chart,
      height: "200px",
    },
    credits: defaultOptions.credits,
    title: {
      text: title,
      ...defaultOptions.title,
    },
    subtitle: {
      text:
        "<div style = 'width: 100%; margin-top: 20px; display: flex; flex-flow: row nowrap; justify-content: space-between'}> " +
        `<div>${values.years[0]}</div>` +
        `<div>${values.years[1]}</div>` +
        "</div>",
      align: "left",
      style: {
        right: "0",
        color: theme.textSecondary,
        fontSize: "12px",
        fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
      },
      widthAdjust: 0,
      useHTML: true,
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      useHTML: true,
      pointFormatter() {
        return `${this.from} ➞ ${this.to}: <b> ${t("general.roundNumber", {
          value: this.weight,
        })} ha </b>`;
      },
      nodeFormatter() {
        return `${this.name}: <b>${t("general.roundNumber", {
          value: this.sum,
        })} ha </b>`;
      },
      headerFormat: "",
    },
    plotOptions: {
      sankey: {
        colors: ["#0800FF", "#FD0100", "#FD0100", "#0800FF"],
        dataLabels: {
          enabled: false,
        },
        curveFactor: 0.5,
      },
    },
    series: [
      {
        name: analysis,
        data,
      },
    ],
    navigation: defaultOptions.navigation,
    lang: defaultOptions.lang,
    exporting: defaultOptions.exporting,
  };

  return (
    <div id="container" className={classes.wrapper}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={(chart) => {
          setChartObj(chart);
        }}
      />
      <div className={classes.tooltip}>
        <CustomTooltip title={info} placement="bottom">
          <InfoOutlined
            style={{
              color: theme.text.primary,
              fontSize: "15px",
            }}
          />
        </CustomTooltip>
      </div>
    </div>
  );
}
