import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  root: {
    "&.MuiSwitch-root": {
      width: 68,
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.switch.background,
      height: 24,
      borderRadius: 100,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.primary,
      height: 24,
      borderRadius: 100,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "white",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiSwitch-colorSecondary.Mui-checked:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiSwitch-switchBase": {
      transform: "translate(7px, 7px)",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translate(27px, 7px)",
    },
    "& .MuiTouchRipple-rippleVisible": {
      opacity: 0,
    },
    "& .MuiTouchRipple-child": {
      display: "none",
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      height: 16,
      width: 16,
    },
  },
}));

export default useStyles;
