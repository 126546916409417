import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  innerWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  line: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-around",
    width: "80%",
  },
  lineItem: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    padding: 4,
  },
  column: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  pointColor: {
    width: "12px",
    height: "12px",
    borderRadius: "100%",
    marginRight: 8,
  },
  pointer: {
    position: "relative",
    height: 16,
    width: 16,
    borderRadius: "100%",
    border: `3px solid ${theme.triangle.pointer.background}`,

    "&::after": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      content: "''",
      width: 11,
      height: 11,
      borderRadius: "100%",
      border: `1px solid ${theme.triangle.pointer.border}`,
    },
    "&::before": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      content: "''",
      width: 16,
      height: 16,
      borderRadius: "100%",
      border: `1px solid ${theme.triangle.pointer.border}`,
    },
  },
  triangleWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  triangleImg: {
    userSelect: "none",
    pointerEvents: "none",
    width: 100,
    height: 200,
  },
  pointerWrapper: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(2.5px, 20px)",
  },
}));

export default useStyles;
