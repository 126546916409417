/* eslint-disable react/no-this-in-sfc */
import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Paper, useMediaQuery } from "@material-ui/core";
import { useTheme } from "react-jss";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { breakpoints, sizes } from "../../../../constants/constraints";
import FiltersContext from "../../../../contexts/filtering";
import CustomTypography from "../../../CustomTypography";
import AnaLogo from "../../../../assets/images/ana_logo.svg";

/**
 * This function renders a infoContainer.
 * @returns The info container.
 */
function InfoContainer({ feature, opened, rightOffset }) {
  InfoContainer.defaultProps = {
    feature: null,
    rightOffset: () => {},
  };

  InfoContainer.propTypes = {
    feature: PropTypes.shape(),
    opened: PropTypes.bool.isRequired,
    rightOffset: PropTypes.func,
  };

  const classes = useStyles();
  const {
    values: { statisticsOpened },
  } = useContext(FiltersContext);
  const isSmd = useMediaQuery(breakpoints.max.smd);
  const theme = useTheme();
  const { t } = useTranslation();

  const propsItems = useMemo(() => {
    if (feature?.properties) {
      return Object.keys(feature.properties).map((key) => {
        if (key.toUpperCase().includes("CODE")) return null;
        if (
          key === "name" ||
          key === "acronymUf" ||
          key === "acronym" ||
          key === "areaKm2" ||
          key === "territoryType"
        )
          return null;
        if (!feature.properties[key] || feature.properties[key] === "null")
          return null;

        let unit = "";

        if (key === "lastQuota") unit = " m";
        if (key === "lastUsefulVolumePercentage") unit = "%";
        if (key === "lastVolumePercentage") unit = "%";

        const item = [];

        if (feature.properties[key]) {
          item.push(
            <CustomTypography
              key={`${key}-propTitle`}
              style={{ color: theme.text.tertiary, fontSize: 11 }}
              variant="caption"
            >
              {t(`dashboard.customPopup.${key}`)}
            </CustomTypography>
          );

          if (
            key === "usefulVolumePercentageSeries" ||
            key === "volumePercentageSeries"
          ) {
            const stringArray = feature.properties[key];

            item.push(
              <Sparklines data={JSON.parse(stringArray).reverse()}>
                <SparklinesLine color={theme.primary} />
              </Sparklines>
            );
          } else if (key === "source") {
            item.push(<img alt="source" src={AnaLogo} style={{ width: 50 }} />);
          } else {
            item.push(
              <CustomTypography
                key={`${key}-propValue`}
                style={{ color: theme.text.primary }}
                variant="body1"
              >
                {feature.properties[key] + unit}
              </CustomTypography>
            );
          }
        }

        return item;
      });
    }

    return null;
  }, [feature, t]);

  return (
    opened && (
      <Paper>
        <div
          className={classes.container}
          style={
            isSmd
              ? {}
              : {
                  right:
                    sizes.map.navigation.itemsMargin +
                    (statisticsOpened
                      ? sizes.statistiscs.width +
                        sizes.map.navigation.itemsMargin +
                        rightOffset()
                      : rightOffset()),
                }
          }
        >
          <CustomTypography
            style={{ color: theme.text.primary, fontSize: 12 }}
            variant="caption"
            align="center"
            weight="bold"
          >
            {feature?.properties?.name}
          </CustomTypography>

          {propsItems.filter((item) => !!item).length > 0 && (
            <>
              <div className={classes.separator} />
              {propsItems}
            </>
          )}
        </div>
      </Paper>
    )
  );
}

export default InfoContainer;
