/* eslint-disable no-unused-vars */
/* eslint-disable react/no-this-in-sfc */
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "react-jss";
import highchartsMore from "highcharts/highcharts-more";
import sankey from "highcharts/modules/sankey";
import PropTypes from "prop-types";
import { InfoOutlined } from "@material-ui/icons";
import useStyles from "../styles";
import useOptions from "../../../../hooks/options";
import CustomTooltip from "../../../CustomTooltip";

highchartsMore(Highcharts);
sankey(Highcharts);

export default function Pie({ title, data, analysis, info }) {
  Pie.propTypes = {
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    analysis: PropTypes.string.isRequired,
  };

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const classes = useStyles({ theme });
  const defaultOptions = useOptions();
  const [chartObj, setChartObj] = useState();

  const options = {
    chart: {
      type: "pie",
      ...defaultOptions.chart,
      height: 230,
    },
    credits: defaultOptions.credits,
    title: {
      text: title,
      ...defaultOptions.title,
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      useHTML: true,
      formatter() {
        return `${this.key}: <b>${this.percentage.toFixed(2)}%</b>`;
      },
    },
    series: [
      {
        data,
        name: analysis,
      },
    ],
    plotOptions: {
      pie: {
        innerSize: "50%",
        allowPointSelect: true,
        cursor: "pointer",
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    navigation: defaultOptions.navigation,
    lang: defaultOptions.lang,
    exporting: {
      ...defaultOptions.exporting,
      chartOptions: {
        chart: {
          events: null,
          style: {
            backgroundColor: theme.background.primary,
          },
        },
      },
    },
  };

  return (
    <div id="container" className={classes.wrapper}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={(chart) => {
          setChartObj(chart);
        }}
      />
      <div className={classes.tooltip}>
        <CustomTooltip title={info} placement="bottom">
          <InfoOutlined
            style={{
              color: theme.text.primary,
              fontSize: "15px",
            }}
          />
        </CustomTooltip>
      </div>
    </div>
  );
}
