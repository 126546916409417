import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  formControl: {
    position: "relative",
    width: "100%",

    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      color: theme.text.primary,
    },
    "& .MuiFormLabel-root": {
      color: theme.text.primary,
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.primary,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.primary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.stroke,
      transition: "border-color .2s ease",
    },
    "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.primary,
      },
    "& .MuiOutlinedInput-root:hover": {
      backgroundColor: theme.background.secondary,
      transition: "background-color 0.15s ease",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.text.primary,
    },
    "& .MuiSelect-icon": {
      color: theme.text.primary,
    },
  },
  label: {},
  select: {
    "& .MuiSelect-select:focus": {
      backgroundColor: theme.background.secondary,
      borderRadius: 8,
    },
  },
  menuPopOver: {
    "& .MuiPaper-root": {
      marginTop: 5,
      backgroundColor: theme.background.primary,
      color: theme.text.primary,
      borderRadius: 8,
      boxShadow: theme.shadow,
      maxHeight: "450px",
    },
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiMenuItem-root": {
      paddingTop: 8,
      paddingBottom: 8,

      "&:active": {
        backgroundColor: theme.hover,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: theme.hover,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: theme.background.secondary,
    },
  },
  downIcon: {
    position: "absolute",
    right: 10,
    pointerEvents: "none",
    transform: "scale(0.6)",

    "& path": {
      fill: theme.text.primary,
    },
  },
}));

export default useStyles;
