import React from "react";
import { useTheme } from "react-jss";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomTypography from "../../../CustomTypography";
import useStyles from "../styles";
import ProgressBar from "../../ProgressBar";

export default function Bars({ data, translationPrefix }) {
  Bars.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    translationPrefix: PropTypes.string.isRequired,
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const bar = (id, value, total, year) => (
    <div key={id} style={{ marginBottom: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <CustomTypography
          color={theme.text.secondary}
          style={{ fontSize: 12 }}
          variant="paragraph"
        >
          {`${t(`${translationPrefix + id}`)} ${year ? `(${year})` : ""}`}
        </CustomTypography>

        <CustomTypography
          color={theme.text.secondary}
          style={{ fontSize: 12 }}
          variant="paragraph"
        >
          {t("general.roundNumber", { value })} ha
        </CustomTypography>
      </div>

      <ProgressBar value={(value / total) * 100} />
    </div>
  );

  return (
    <div className={classes.wrapper}>
      {data.map((obj) => bar(obj.id, obj.value, obj.total, obj.year))}
    </div>
  );
}
