import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line camelcase
import HC_exporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "./contexts/theming";
import "./i18n/config";
import "./assets/css/styles.css";
import Routes from "./routes";

HC_exporting(Highcharts);

ReactDOM.render(
  <ThemeProvider>
    <CssBaseline />
    <Routes />
  </ThemeProvider>,
  document.getElementById("root")
);
