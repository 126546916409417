/* eslint-disable react/require-default-props */
/* eslint-disable react/no-this-in-sfc */
import React from "react";
import { useTheme } from "react-jss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import highchartsMore from "highcharts/highcharts-more";
import Pagination from "@material-ui/lab/Pagination";
import { useSwipeable } from "react-swipeable";
import { useMediaQuery } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import useOptions from "../../../../hooks/options";
import useStyles from "../styles";
import { breakpoints } from "../../../../constants/constraints";
import CustomTooltip from "../../../CustomTooltip";

highchartsMore(Highcharts);

/**
 * This component is a stylized chart to display ranking data.
 * @param {Object with two arrays: categories and values} data
 */
export default function Ranking({
  title,
  sum,
  data,
  info,
  totalPages,
  page,
  setRankingPage,
  rankingOrder,
  setRankingOrder,
}) {
  Ranking.defaultProps = {
    totalPages: 0,
  };

  Ranking.propTypes = {
    title: PropTypes.string.isRequired,
    sum: PropTypes.bool,
    data: PropTypes.shape().isRequired,
    totalPages: PropTypes.number,
    page: PropTypes.number.isRequired,
    setRankingPage: PropTypes.func.isRequired,
    rankingOrder: PropTypes.bool.isRequired,
    setRankingOrder: PropTypes.func.isRequired,
    info: PropTypes.string.isRequired,
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (page < totalPages) setRankingPage(page + 1);
    },
    onSwipedRight: () => {
      if (page > 1) setRankingPage(page - 1);
    },
    delta: 25,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const defaultOptions = useOptions();
  const { t } = useTranslation();
  const classes = useStyles();
  const isSmd = useMediaQuery(breakpoints.max.smd);
  const isSm = useMediaQuery(breakpoints.max.sm);
  const isXsm = useMediaQuery(breakpoints.max.xsm);

  const options = {
    chart: {
      type: "bar",
      events: {
        render() {
          const chart = this;
          chart.series.forEach((s) => {
            s.points.forEach((p) => {
              if (p.dataLabel) {
                p.dataLabel.attr({
                  x: chart.plotWidth - p.dataLabel.width,
                });
              }
            });
          });
        },
      },
      ...defaultOptions.chart,
      height: 350,
    },
    credits: defaultOptions.credits,
    title: {
      text: title,
      align: "left",
      style: {
        padding: sum && "0 0 25px 0",
        fontFamily: `"Manrope", "Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "19.12px",
        letterSpacing: "-0.00833em",
        color: theme.text.primary,
      },
      useHTML: true,
    },
    subtitle: {
      text:
        sum &&
        `<div   
        style="
         width: 100vw;
          font-size: 10px;
          font-weight: 600;
          color: ${theme.text.primary};
          pointer-events: none;
          user-select: none;
      "
    >
      <div style="position: absolute; left: -20px">
        ${t("dashboard.statistics.charts.waterTransitions.ranking.territory")}
      </div>
      <div  style="position: absolute; right: -15px">
        ${t("dashboard.statistics.charts.waterTransitions.ranking.sum")}
      </div> 
    </div>`,
      useHTML: true,
    },
    xAxis: {
      ...defaultOptions.xAxis,
      gridLineWidth: 0,
      labels: {
        style: {
          fontSize: "13px",
          color: theme.text.secondary,
          ...defaultOptions.xAxis.labels.style,
        },
        align: "left",
        reserveSpace: true,
        formatter: (obj) => `${data.position[obj.pos]}° ${obj.value}`,
      },
      categories: data.x,
    },
    yAxis: {
      ...defaultOptions.yAxis,
      gridLineWidth: 0,
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      width: isXsm ? "40%" : isSm ? "70%" : isSmd ? "80%" : "70%",
    },
    tooltip: {
      useHTML: true,
      formatter() {
        return `<b>${this.point.category}</b> </br>
            <tr><td style="color: ${this.series.color}">${
          this.series.name
        }: </td>
              <td style="text-align: right"><b>${t("general.roundNumber", {
                value: this.point.y,
              })} ha
            </b></td></tr>`;
      },
    },
    legend: defaultOptions.legend,
    plotOptions: {
      bar: {
        pointWidth: data.values ? 10 : 23,
        borderWidth: 0,
      },
      series: {
        pointStart: 0,
        stacking: data.values ? undefined : "normal",
        dataLabels: {
          x: 999,
          enabled: true,
          formatter() {
            if (data.values) {
              if (this.point.series.index === 0) {
                return `${t("general.roundNumber", {
                  value: data.values[this.point.index],
                })} ha`;
              }
              return "";
            }
            return `${t("general.roundNumber", {
              value: this.total,
            })} ha`;
          },
          style: {
            textOutline: false,
            fontSize: "11px",
            fontWeight: 500,
            color: theme.text.secondary,
            /* ...defaultOptions.series.dataLabels.style, */
          },
          inside: false,
          useHTML: true,
        },
      },
    },
    series: data.series,
    navigation: defaultOptions.navigation,
    lang: {
      ...defaultOptions.lang,
      info,
    },
    exporting: {
      allowHTML: true,
      chartOptions: {
        chart: {
          spacing: [3, 20, 15, 20],
          events: null,
          style: {
            backgroundColor: theme.background.primary,
          },
        },
        subtitle: {
          text:
            sum &&
            `<div   
            style="
             width: 100vw;
              font-size: 10px;
              font-weight: 600;
              color: ${theme.text.primary};
              pointer-events: none;
              user-select: none;
          "
        >
          <div style="position: absolute; left: -20px">
            ${t(
              "dashboard.statistics.charts.waterTransitions.ranking.territory"
            )}
          </div>
          <div  style="position: absolute; right: -25px">
            ${t("dashboard.statistics.charts.waterTransitions.ranking.sum")}
          </div> 
        </div>`,
          useHTML: true,
        },
      },
      ...defaultOptions.exporting,
      buttons: {
        order: {
          symbol: rankingOrder ? "triangle-down" : "triangle",
          symbolFill: theme.text.primary,
          symbolStroke: theme.text.primary,
          symbolSize: 6,
          symbolY: 11.5,
          onclick() {
            setRankingOrder(!rankingOrder);
          },
          theme: {
            fill: "transparent",
            states: {
              hover: {
                fill: theme.filters.timeline.separator,
              },
              select: {
                fill: theme.filters.timeline.separator,
              },
            },
          },
        },
        ...defaultOptions.exporting.buttons,
      },
    },
  };

  return (
    <div {...handlers} className={classes.wrapper}>
      <HighchartsReact highcharts={Highcharts} options={options} />

      <div className={classes.tooltip} style={{ right: 55, top: 7 }}>
        <CustomTooltip title={info} placement="bottom">
          <InfoOutlined
            style={{
              color: theme.text.primary,
              fontSize: "15px",
            }}
          />
        </CustomTooltip>
      </div>
      <Pagination
        className={classes.pagination}
        size="small"
        count={totalPages}
        page={page}
        onChange={(event, value) => setRankingPage(value)}
      />
    </div>
  );
}
