import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { defaults } from "../constants/general";

/**
 * This hook updates the territoryhistory and return the current territory history.
 * @param {A list containing scale hierarchy} scalesHierarchy
 * @param {The current selected territory} territory
 * @param {The current selected scale} scale
 * @param {The current selected shape} shape
 * @param {The current selected focus shape} focusShape
 */
export default function useTerritoryHistory(
  hierarchy,
  territory,
  scale,
  dataType,
  /* shape, */
  initiative,
  initiativeOptions
) {
  const params = useParams();
  const [territoryHistory, setTerritoryHitory] = useState([
    initiativeOptions[params.initiative]
      ? initiativeOptions[params.initiative].defaultTerritory
      : defaults[dataType].territory,
  ]);

  useEffect(() => {
    if (initiativeOptions[params.initiative]) {
      setTerritoryHitory((old) =>
        old.map((item, index) => {
          if (index > 0) return item;

          return initiativeOptions[params.initiative].defaultTerritory;
        })
      );
    }
  }, [initiativeOptions]);

  /**
   * This useEffect calculate a new territory history list.
   */
  useEffect(() => {
    if (hierarchy) {
      const hierarchyAmountAux = {};
      const hierarchyPosition = {};

      hierarchy.forEach((hierarchyEl) => {
        if (!hierarchyAmountAux[hierarchyEl.position])
          hierarchyAmountAux[hierarchyEl.position] = 0;
        hierarchyAmountAux[hierarchyEl.position] += 1;
        hierarchyPosition[hierarchyEl.type] = hierarchyEl.position;
      });

      const territoryHistoryAux = territoryHistory;

      const foundIndex = territoryHistoryAux.findIndex(
        (element) =>
          element.type === territory.type ||
          hierarchyPosition[element.type] === hierarchyPosition[territory.type]
      );

      const territoryAux = {
        ...territory,
        defaultScale: scale,
        /* shape, */
        // focusShape,
      };

      if (foundIndex > -1) {
        territoryHistoryAux.splice(
          foundIndex,
          Object.keys(hierarchyAmountAux).length,
          territoryAux
        );
      } else {
        const territoryPosition = hierarchyPosition[territoryAux.type];

        let foundMinorIndex = -1;

        for (
          let i = 0;
          i < territoryHistoryAux.length && foundMinorIndex === -1;
          i += 1
        ) {
          const iPosition = hierarchyPosition[territoryHistoryAux[i].type];

          if (territoryPosition < iPosition) {
            foundMinorIndex = i;
          }
        }

        if (foundMinorIndex > -1) {
          territoryHistoryAux.splice(
            foundMinorIndex,
            Object.keys(hierarchyAmountAux).length,
            territoryAux
          );
        } else {
          territoryHistoryAux.push(territoryAux);
        }
      }

      setTerritoryHitory(territoryHistoryAux);
    }
  }, [hierarchy, territory, scale /* shape */ /* focusShape */]);

  if (!(territory && hierarchy))
    return [
      initiativeOptions[params.initiative]
        ? initiativeOptions[params.initiative].defaultTerritory
        : defaults[dataType].territory,
    ];

  return territoryHistory;
}
