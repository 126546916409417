/**
 * This file defines the platform color schemes.
 */
export const lightScheme = {
  mode: "light",
  primary: "#006CFF",
  primaryHard: "#0057cf",
  primaryHarder: "#0044a1",
  secondary: "#F2994A",
  stroke: "#E9E9E9",
  hover: "#e5f0ff",
  shadow: "0px 4px 22px 0px rgba(0,0,0,0.1)",
  floatingShadow: "0px 0px 12px rgba(0,0,0,0.1)",
  grey: "#555555",
  white: "#ffffff",
  background: {
    primary: "#FFFFFF",
    secondary: "#F5F5F5",
  },
  text: {
    primary: "#555555",
    secondary: "#888A8B",
    tertiary: "#A3A3A3",
    legend: "#555555",
    soft: "#C2C8CA",
    gray: "#888A8B",
  },
  header: {
    stroke: "#EAEAEA",
  },
  map: {
    scale: "#888A8B",
    scaleSatellite: "#ffffff",
    water: "#c2c8ca",
    border: "black",
    selection: {
      stroke: "#888A8B",
      background: "#888A8B",
      close: "#080808",
      gifBackground: "#ffffff",
    },
  },
  filters: {
    timeline: {
      separator: "#EAEAEA",
      mark: {
        unactive: "#C2C8CA",
        selected: "#00439D",
      },
      calendarSeparator: "#C2C8CA",
    },
  },
  switch: {
    background: "#C2C8CA",
  },
  tooltip: {
    background: "#080808",
    text: "#C2C8CA",
  },
  triangle: {
    pointer: {
      background: "white",
      border: "#080808",
    },
  },
  waterClassification: {
    mining: "#AC0024",
    hydroelectric: "#E6528B",
    natural: "#0000ff",
    anthropic: "#00C4D9",
    aquaculture: "#091077",
  },
  transition: {
    gain: "#0800FF",
    loss: "#FD0200",
  },
  trend: {
    area: "#0800FF",
    harmonyArea: "#FF683E",
    positiveResidue: "#00CD9A",
    negativeResidue: "#FD0200",
  },
  share: {
    twitter: "#555555",
    whatsapp: "#128c7E",
    facebook: "#3B5998",
  },
};

export const darkScheme = {
  mode: "dark",
  primary: "#006CFF",
  primaryHard: "#0057cf",
  primaryHarder: "#0044a1",
  secondary: "#F2994A",
  stroke: "#1F1F1F",
  hover: "#0c141f",
  shadow: "0px 4px 22px 0px rgba(0,0,0,0.1)",
  floatingShadow: "0px 0px 12px rgba(0,0,0,0.1)",
  grey: "#555555",
  background: {
    primary: "#080808",
    secondary: "#1F1F1F",
  },
  text: {
    primary: "#C2C8CA",
    secondary: "#C2C8CA",
    tertiary: "#555555",
    legend: "#555555",
    soft: "#555555",
    gray: "#888A8B",
  },
  header: {
    stroke: "#6D6D6D",
  },
  map: {
    scale: "#888A8B",
    scaleSatellite: "#cccccc",
    water: "#1b1b1d",
    border: "white",
    selection: {
      stroke: "#dedede",
      background: "#ebebeb",
      close: "#ffffff",
      gifBackground: "#000000",
    },
  },
  filters: {
    timeline: {
      separator: "#6D6D6D",
      mark: {
        unactive: "#555555",
        selected: "#00439D",
      },
      calendarSeparator: "#C2C8CA",
    },
  },
  switch: {
    background: "#C2C8CA",
  },
  tooltip: {
    background: "#080808",
    text: "#C2C8CA",
  },
  triangle: {
    pointer: {
      background: "#080808",
      border: "#C2C8CA",
    },
  },
  waterClassification: {
    mining: "#AC0024",
    hydroelectric: "#E6528B",
    natural: "#0000ff",
    anthropic: "#00C4D9",
  },
  transition: {
    gain: "#0800FF",
    loss: "#FD0200",
  },
  trend: {
    area: "#0800FF",
    harmonyArea: "#FF683E",
    positiveResidue: "#00CD9A",
    negativeResidue: "#FD0200",
  },
  share: {
    twitter: "#C2C8CA",
    whatsapp: "#128c7E",
    facebook: "#3B5998",
  },
};

export const glacierLightScheme = {
  ...lightScheme,
  primary: "#1D9DB8",
  primaryHard: "#227789",
  primaryHarder: "#1C5F6D",
  filters: {
    timeline: {
      separator: "#EAEAEA",
      mark: {
        unactive: "#C2C8CA",
        selected: "#1C5F6D",
      },
      calendarSeparator: "#C2C8CA",
    },
  },
};

export const glacierDarkScheme = {
  ...darkScheme,
  primary: "#1D9DB8",
  primaryHard: "#227789",
  primaryHarder: "#1C5F6D",
  filters: {
    timeline: {
      separator: "#6D6D6D",
      mark: {
        unactive: "#555555",
        selected: "#1C5F6D",
      },
      calendarSeparator: "#C2C8CA",
    },
  },
};
