import * as React from "react";

function SvgAdd(props) {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.872.759L7 .75a.937.937 0 01.929.81l.008.127v4.375h4.375a.938.938 0 01.93.81L13.25 7a.937.937 0 01-.81.929l-.127.008H7.936v4.375a.938.938 0 01-.81.93L7 13.25a.937.937 0 01-.929-.81l-.008-.127V7.936H1.688a.937.937 0 01-.93-.81L.75 7a.937.937 0 01.81-.929l.127-.008h4.375V1.688a.937.937 0 01.81-.93L7 .75l-.128.009z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgAdd;
