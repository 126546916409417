import React from "react";
import PropTypes from "prop-types";
import useStyles from "./styles";

/**
 * This functional component renders the mobile menu.
 * @returns A wrapper that contains the mobile menu.
 */
function MobileBar({ header, children }) {
  MobileBar.propTypes = {
    header: PropTypes.shape().isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.headerWrapper}>{header}</div>
      <div className={classes.contentWrapper}>{children}</div>
    </div>
  );
}

export default MobileBar;
