/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import SyncLoader from "react-spinners/SyncLoader";
import { Fade, Paper } from "@material-ui/core";
import useStyles from "./styles";
import SvgClose from "../../../Icons/Close";
import { sizes } from "../../../../constants/constraints";
import FiltersContext from "../../../../contexts/filtering";

/**
 * This function renders a container to comport the gif.
 * @returns The gif container.
 */
function GifContainer({
  gifAddress,
  handleClose,
  gifLoading,
  opened,
  containerRef,
}) {
  GifContainer.defaultProps = {
    gifAddress: undefined,
  };

  GifContainer.propTypes = {
    gifAddress: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    gifLoading: PropTypes.bool.isRequired,
    opened: PropTypes.bool.isRequired,
    containerRef: PropTypes.any.isRequired,
  };

  const theme = useTheme();
  const classes = useStyles();
  const {
    values: { statisticsOpened },
  } = useContext(FiltersContext);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [sizingStyle, setSizingStyle] = useState({});
  const [minSize, setMinSize] = useState({});

  /**
   * Fired when the gif is loaded.
   */
  function onLoaded(event) {
    const img = event.target;
    setImageLoaded(true);

    if (img) {
      if (img.height > img.width) {
        setSizingStyle({ height: "100%" });
      } else {
        setSizingStyle({ width: "100%" });
      }
      setMinSize({ minWidth: 100, minHeight: 100 });
    }
  }

  useEffect(() => {
    setSizingStyle({ width: 120, height: 120 });
    setMinSize({});
  }, [gifLoading]);

  useEffect(() => {
    setImageLoaded(false);
  }, [gifAddress]);

  return (
    <Fade in={opened}>
      <Paper>
        <div
          className={classes.container}
          style={{
            right:
              sizes.map.navigation.itemsMargin +
              (statisticsOpened
                ? sizes.statistiscs.width + sizes.map.navigation.itemsMargin
                : 0),
            ...sizingStyle,
          }}
          ref={containerRef}
        >
          {!(gifLoading || !imageLoaded) && (
            <div
              role="button"
              className={classes.clickableButton}
              onClick={() => {
                setImageLoaded(false);
                handleClose();
              }}
              onKeyDown={() => {}}
              tabIndex={0}
            >
              <SvgClose
                className={classes.navigationIcon}
                style={{ transform: "scale(1)" }}
              />
            </div>
          )}
          {(gifLoading || !imageLoaded) && (
            <div className={classes.gifLoaderWrapper}>
              <SyncLoader color={theme.primary} size={10} />
            </div>
          )}
          {!gifLoading && (
            <img
              alt="gif"
              src={gifAddress}
              className={classes.img}
              style={{
                opacity: imageLoaded ? 1 : 0,
                ...sizingStyle,
                ...minSize,
              }}
              onLoad={onLoaded}
            />
          )}
        </div>
      </Paper>
    </Fade>
  );
}

export default GifContainer;
