/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { RENDER_STATE } from "react-map-gl-draw";
import {
  breakpoints,
  sizes,
  transitions,
} from "../../../constants/constraints";
import { lightScheme } from "../../../constants/schemes";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  map: {
    "& .mapboxgl-ctrl-bottom-left": {
      "& .mapboxgl-ctrl": {
        marginLeft: 18,
        [breakpoints.max.smd]: {
          marginLeft: 14,
        },
      },
    },
    "& .mapboxgl-ctrl-bottom-right": {
      marginBottom: 8,
      marginRight: 12,

      "@media (max-width: 650px)": {
        marginBottom: 0,
        marginRight: 2,
      },
      "& .mapboxgl-ctrl, .mapboxgl-ctrl-attrib": {
        backgroundColor: "transparent",

        "& .mapboxgl-ctrl-attrib, a": {
          color: theme.text.secondary,
          fontFamily: "Manrope",
        },
      },
    },
  },
  scale: {
    "& .mapboxgl-ctrl-scale": {
      color: theme.map.scale,
      borderColor: theme.map.scale,
      backgroundColor: "transparent",
      borderLeft: "none",
      borderRight: "none",
      fontFamily: "Manrope",
      fontWeight: 700,
      marginBottom: 47,

      "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "50%",
        borderLeft: `solid 2px ${theme.map.scale}`,
        borderBottom: "none",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        right: -2,
        bottom: -2,
        width: 1,
        height: "50%",
        borderLeft: `solid 2px ${theme.map.scale}`,
        borderBottom: "none",
      },
      [breakpoints.max.smd]: {
        marginBottom: -8,
        marginLeft: -60,
      },
    },
  },
  scaleSatellite: {
    "& .mapboxgl-ctrl-scale": {
      color: theme.map.scaleSatellite,
      borderColor: theme.map.scaleSatellite,
      backgroundColor: "transparent",
      borderLeft: "none",
      borderRight: "none",
      fontFamily: "Manrope",
      fontWeight: 700,
      marginBottom: 47,

      "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        bottom: 0,
        width: 1,
        height: "50%",
        borderLeft: `solid 2px ${theme.map.scaleSatellite}`,
        borderBottom: "none",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        right: -2,
        bottom: -2,
        width: 1,
        height: "50%",
        borderLeft: `solid 2px ${theme.map.scaleSatellite}`,
        borderBottom: "none",
      },
      [breakpoints.max.smd]: {
        marginBottom: -8,
        marginLeft: -60,
      },
    },
  },
  navigationContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    bottom: 40,
    pointerEvents: "none",
    left: 16,
    right: 16,

    "& > *": {
      marginBottom: sizes.map.navigation.itemsMargin,
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
    [breakpoints.max.smd]: {
      alignItems: "flex-end",
      left: 12,
      right: 12,
      bottom: 25,
    },
  },
  north: {
    backgroundColor: theme.background.primary,
    transition: transitions.background,
    borderRadius: 4,
    width: 40,
    height: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "all",
    boxShadow: theme.floatingShadow,
  },
  navigationIcon: {
    "& path": {
      fill: theme.text.primary,
    },
  },
  backgroundOpacityIcon: {
    "& path": {
      fill: theme.text.primary,
    },
  },
  navigationIconActived: {
    "& path": {
      fill: theme.primary,
    },
  },
  navigationWrapper: {
    backgroundColor: theme.background.primary,
    transition: transitions.background,
    width: 40,
    borderRadius: 4,
    pointerEvents: "all",
  },
  navigationButton: {
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
  },
  bottomContainer: {
    display: "flex",
    flexDirection: "horizontal",
  },
  middleContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    userSelect: "none",
    top: 165,
    transform: "translateY(-50%)",
    pointerEvents: "none",
    right: 16,
    left: 16,

    "& > *": {
      marginBottom: sizes.map.navigation.itemsMargin,
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
    "@media (min-height: 720px)": {
      top: "50%",
    },
    [breakpoints.max.smd]: {
      top: "50%",
      alignItems: "flex-end",
      left: 12,
      right: 12,

      "@media (max-height: 595px)": {
        top: 175,
      },
    },
  },
  selectionButton: {
    backgroundColor: theme.background.primary,
    transition: transitions.background,
    borderRadius: 4,
    width: 40,
    height: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "all",
    boxShadow: theme.floatingShadow,
  },
  mapCursor: {
    position: "absolute",
    left: "50%",
    top: "50%",
    zIndex: 10000,
    transform: "translate(-50%, -50%)",
    filter: theme.mode === "light" ? "invert(0)" : "invert(1)",
    transition: "filter 1s ease",
    pointerEvents: "none",
  },
  geodatinContainer: {
    position: "absolute",
    bottom: 7,
    width: 115,
    height: 25,
    left: "50%",
    transform: "translateX(-50%)",
    userSelect: "none",

    "@media (max-width: 330px)": {
      width: 25,
    },
    "&::after": {
      content: '""',
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
    },
  },
  geodatinLogo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "left",
  },
  clickableButton: {
    "&:hover": {
      backgroundColor: theme.background.secondary,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.hover,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:focus": {
      outline: `none`,
    },
  },
  clickButtonContainerActived: {
    border: `1px solid ${theme.primary}`,
  },
  marker: {
    userSelect: "none",
    pointerEvents: "none",
  },
  pinImg: {
    height: 30,
    userSelect: "none",
    pointerEvents: "none",
  },
}));

export function getEditHandleStyle({ feature, state }, theme) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
      return {
        stroke: theme.map.selection.stroke,
        strokeWidth: 1.5,
        fill: theme.map.selection.background,
        fillOpacity: 0.1,
        strokeDasharray: "4,2",
        r: 7,
      };

    default:
      return {
        stroke: theme.map.selection.stroke,
        strokeWidth: 1.5,
        fill: theme.map.selection.background,
        fillOpacity: 0.1,
        strokeDasharray: "4,2",
        r: 7,
      };
  }
}

export function getFeatureStyle({ feature, index, state }, theme) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      return {
        stroke: theme.map.selection.stroke,
        strokeWidth: 1.5,
        fill: theme.map.selection.background,
        fillOpacity: 0.1,
        strokeDasharray: "4,2",
        r: 7,
      };

    default:
      return {
        stroke: theme.map.selection.stroke,
        strokeWidth: 1.5,
        fill: theme.map.selection.background,
        fillOpacity: 0.1,
        strokeDasharray: "4,2",
        r: 7,
      };
  }
}

export default useStyles;
