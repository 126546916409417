import React from "react";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import useStyles from "./styles";
import { Next } from "../../../Icons";
import CustomTypography from "../../../CustomTypography";

/**
 * This functional component renders a menu item.
 * @returns A menu item.
 */
function MenuItem({ children, icon, arrow, buttonProps }) {
  MenuItem.defaultProps = {
    arrow: false,
    icon: undefined,
  };

  MenuItem.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    arrow: PropTypes.bool,
    icon: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    buttonProps: PropTypes.shape().isRequired,
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button {...buttonProps} className={classes.button}>
        <div className={classes.wrapper}>
          <div className={classes.leftWrapper}>
            {icon}
            <CustomTypography variant="contrast">{children}</CustomTypography>
          </div>
          {arrow && <Next className={classes.arrowIcon} />}
        </div>
      </Button>
    </div>
  );
}

export default MenuItem;
