/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTheme } from "react-jss";
import { Fade, Paper, useMediaQuery } from "@material-ui/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import SyncLoader from "react-spinners/SyncLoader";
import useStyles from "./styles";
import SvgClose from "../../../Icons/Close";
import { breakpoints, sizes } from "../../../../constants/constraints";
import FiltersContext from "../../../../contexts/filtering";
import useOptions from "../../../../hooks/options";

/**
 * This function renders a container to comport the pixel.
 * @returns The pixel container.
 */
function WaterChartContainer({
  containerRef,
  chartData,
  handleClose,
  opened,
  loading,
}) {
  WaterChartContainer.defaultProps = {
    chartData: undefined,
  };

  WaterChartContainer.propTypes = {
    chartData: PropTypes.shape(),
    handleClose: PropTypes.func.isRequired,
    opened: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    containerRef: PropTypes.any.isRequired,
  };

  const classes = useStyles();
  const {
    values: { statisticsOpened, dataType },
  } = useContext(FiltersContext);
  const theme = useTheme();
  const defaultOptions = useOptions();
  const isSmd = useMediaQuery(breakpoints.max.smd);
  const isSm = useMediaQuery(breakpoints.max.sm);
  const isXsm = useMediaQuery(breakpoints.max.xsm);
  const { t } = useTranslation();
  let options = {};

  if (chartData) {
    options = {
      credits: defaultOptions.credits,
      chart: {
        type: "line",
        ...defaultOptions.chart,
        height: 160,
        width: 300,
        spacing: [10, 10, 15, 10],
      },
      title: {
        text: t("dashboard.map.pixelSelection.title"),
        ...defaultOptions.title,
      },
      lang: defaultOptions.lang,
      xAxis: {
        categories: chartData.x,
        zoomEnabled: false,
        labels: {
          style: {
            fontSize: "10px",
            color: theme.text.secondary,
            ...defaultOptions.yAxis.labels.style,
          },
          formatter() {
            if (this.value && this.value.includes && this.value.includes("-")) {
              return t("general.date.monthYear", {
                date: new Date(this.value),
              });
            }
            return t("general.date.year", { date: this.value });
          },
        },
        gridLineWidth: 0,
        gridLineColor: theme.filters.timeline.separator,
      },
      yAxis: {
        title: {
          enabled: false,
        },
        max: 1,
        min: 0,
        zoomEnabled: false,
        labels: {
          enabled: false,
          style: {
            fontSize: "10px",
            color: theme.text.tertiary,
            ...defaultOptions.xAxis.labels.style,
          },
        },
        gridLineWidth: 0,
        gridLineColor: theme.filters.timeline.separator,
        width: isXsm ? "40%" : isSm ? "70%" : isSmd ? "80%" : "70%",
      },
      series: [
        {
          name:
            dataType === "glacier"
              ? t("dashboard.map.pixelSelection.legendGlacier")
              : t("dashboard.map.pixelSelection.legendWater"),
          color: theme.primary,
          data: chartData.y.map((v) => ({
            y: v,
            color: v === 0 ? theme.text.primary : theme.primary,
          })),
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        {
          name: t("dashboard.map.pixelSelection.other"),
          color: theme.text.primary,
          data: [],
        },
      ],
      tooltip: {
        useHTML: true,
        formatter() {
          if (this.value && this.value.includes && this.value.includes("-")) {
            return `<b>${t("general.date.monthNameYear", {
              date: new Date(this.point.category),
            })}</b> </br>
              <tr><td style="color: ${this.series.color}">${
              this.point.y === 0
                ? t("dashboard.map.pixelSelection.other")
                : dataType === "glacier"
                ? t("dashboard.map.pixelSelection.legendGlacier")
                : t("dashboard.map.pixelSelection.legendWater")
            }</td>
                </tr>`;
          }
          return `<b>${this.point.category}</b> </br>
              <tr><td style="color: ${this.series.color}">${
            this.point.y === 0
              ? t("dashboard.map.pixelSelection.other")
              : dataType === "glacier"
              ? t("dashboard.map.pixelSelection.legendGlacier")
              : t("dashboard.map.pixelSelection.legendWater")
          }</td>
                </tr>`;
        },
      },
      plotOptions: {
        line: {
          states: {
            hover: {
              lineWidthPlus: 0,
            },
          },
          lineWidth: 0,
          marker: {
            enabled: true,
            lineWidth: 0,
          },
        },
        series: {
          animation: false,
          events: {
            legendItemClick(e) {
              e.preventDefault();
            },
          },
        },
      },
      legend: {
        margin: 0,
        itemStyle: { color: theme.text.primary },
        itemHoverStyle: { color: theme.text.primary },
      },
      navigation: defaultOptions.navigation,
      exporting: {
        ...defaultOptions.exporting,
        buttons: {
          contextButton: {
            x: -33,
            ...defaultOptions.exporting.buttons.contextButton,
          },
        },
      },
    };
  }

  return (
    <Fade in={opened || loading}>
      <Paper>
        <div
          className={classes.container}
          style={
            isSmd
              ? {}
              : {
                  right:
                    sizes.map.navigation.itemsMargin +
                    (statisticsOpened
                      ? sizes.statistiscs.width +
                        sizes.map.navigation.itemsMargin
                      : 0),
                }
          }
          ref={containerRef}
        >
          {!loading && (
            <div
              role="button"
              className={classes.clickableButton}
              onClick={() => {
                handleClose();
              }}
              onKeyDown={() => {}}
              tabIndex={0}
            >
              <SvgClose
                className={classes.navigationIcon}
                style={{ transform: "scale(1)" }}
              />
            </div>
          )}
          {loading && (
            <div className={classes.gifLoaderWrapper}>
              <SyncLoader color={theme.primary} size={10} />
            </div>
          )}
          {!loading && (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </div>
      </Paper>
    </Fade>
  );
}

export default WaterChartContainer;
