import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "react-jss";

export default function ProgressBar({ value }) {
  ProgressBar.propTypes = {
    value: PropTypes.number.isRequired,
  };

  const theme = useTheme();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 8,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.background.secondary,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.primary,
    },
  }))(LinearProgress);

  return <BorderLinearProgress variant="determinate" value={value} />;
}
