import { createUseStyles } from "react-jss";
import { styles } from "../../../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
  },
  button: {
    "&.MuiButton-root": {
      marginTop: 4,
      height: 48,
      padding: "0px 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: `1px solid ${theme.stroke}`,
      minHeight: 48,
      width: "100%",
      ...styles(theme).button,
    },
  },
  arrowIcon: {
    position: "absolute",
    right: 5,
    transform: "scale(0.7)",

    "& path": {
      fill: theme.grey,
    },
  },
  optionsContainer: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.background.primary,
    boxShadow: "0px -4px 16px rgba(0, 0, 0, 0.06)",
    zIndex: 150,
  },
  optionsContainerRest: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "transparent",
    zIndex: 100,
  },
  optionsTitle: {
    display: "flex",
    padding: "10px 16px",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid${theme.stroke}`,
  },
  closeIcon: {
    transform: "scale(1.1)",

    "& path": {
      fill: theme.grey,
    },
  },
  closeButton: {
    "&.MuiButton-root": {
      marginTop: 4,
      height: 34,
      display: "flex",
      minHeight: 34,
      minWidth: 34,
      ...styles(theme).button,
    },
  },
  selectButtonWrapper: {
    width: "100%",
    padding: "16px 32px",
  },
  selectButton: {
    "&.MuiButton-root": {
      minWidth: "100%",
      minHeight: 48,
      marginRight: 0,
      textTransform: "none",

      "&:hover": {
        backgroundColor: theme.primaryHard,
      },
      "&:active": {
        backgroundColor: theme.primaryHarder,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
  optionsList: {
    minHeight: 50,
    maxHeight: 250,
    overflow: "auto",
    padding: "10px 16px",
  },
  optionButton: {
    "&.MuiButton-root": {
      marginTop: 4,
      display: "flex",
      minHeight: 48,
      minWidth: "100%",
      justifyContent: "flex-start",
      textTransform: "none",
      ...styles(theme).button,
    },
  },
  optionButtonSelected: {
    "&.MuiButton-root": {
      marginTop: 4,
      display: "flex",
      minHeight: 48,
      minWidth: "100%",
      justifyContent: "flex-start",
      backgroundColor: theme.hover,
      textTransform: "none",

      "&:hover": {
        backgroundColor: theme.hover,
      },
      "&:active": {
        backgroundColor: theme.hover,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
  },
}));

export default useStyles;
