import { createUseStyles } from "react-jss";
import { breakpoints, styles } from "../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  button: {
    "&.MuiButton-root": {
      minWidth: 30,
      ...styles(theme).button,
    },
  },
  icon: {
    height: 16,

    "& circle": {
      fill: theme.text.primary,
    },
  },
  list: {
    "& .MuiPaper-root": {
      backgroundColor: theme.background.primary,
      color: theme.text.primary,
      transition: "background-color 0.2s ease",
    },
    "& .MuiDrawer-paper": {
      width: "100%",
      maxWidth: "300px",

      [breakpoints.max.sm]: {
        maxWidth: "100%",
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: 36,
    },
  },
  topContainer: {
    padding: "12px 16px",
    borderBottom: `1px solid ${theme.stroke}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    height: 32,
    width: 154,
    marginRight: 26,
    userSelect: "none",

    "@media (max-width: 270px)": {
      height: 32,
      width: 50,
      objectFit: "cover",
      objectPosition: 0,
    },
  },
  closeButton: {
    "&.MuiButton-root": {
      minWidth: 30,
      minHeight: 30,
      marginRight: 0,

      ...styles(theme).button,
    },
  },
  closeIcon: {
    transform: "scale(1.3)",

    "& path": {
      fill: theme.grey,
    },
  },
  items: {
    padding: "0px 16px",
  },
  themeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 0px",
  },
  darkModeIcon: {
    transform: "scale(1.1)",
    marginRight: 10,

    "& path": {
      fill: theme.grey,
    },
  },
  themeTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backButton: {
    "&.MuiButton-root": {
      minWidth: 30,
      minHeight: 30,
      marginRight: 0,

      ...styles(theme).button,
    },
  },
  translationTopContainer: {
    padding: "12px 16px",
    borderBottom: `1px solid ${theme.stroke}`,
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
