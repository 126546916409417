import React from "react";
import PropTypes from "prop-types";
import { Radio } from "@material-ui/core";
import useStyles from "./styles";
import CustomTypography from "../../../../CustomTypography";

export default function BaseMapOption({ title, onClick, checked }) {
  BaseMapOption.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
  };

  const classes = useStyles();

  return (
    <>
      <div
        role="button"
        onClick={onClick}
        onKeyDown={onClick}
        tabIndex={0}
        className={classes.container}
      >
        <Radio
          checked={checked}
          classes={{ root: classes.radio }}
          size="medium"
          color="primary"
        />
        <div className={classes.titleWrapper}>
          <CustomTypography variant="body">{title}</CustomTypography>
        </div>
      </div>
    </>
  );
}
