import { createUseStyles } from "react-jss";
import { breakpoints, sizes } from "../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "fixed",
    width: "100%",
    height: `calc(100% - ${sizes.header.height}px)`,
    overflow: "auto",
  },
  mapContainer: {
    position: "relative",
    width: "100%",
    height: "100%",

    [breakpoints.max.smd]: {
      paddingBottom: sizes.statistiscs.header.height,
    },
  },
  separator: {
    width: 1,
    height: 24,
    margin: "0px 16px",
    backgroundColor: theme.filters.timeline.separator,
  },
  legendContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  legendSeparator: {
    width: "100%",
    height: 1,
    backgroundColor: theme.filters.timeline.separator,
    margin: "10px 0px 10px 0px",
  },
  legendTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    fontWeight: 700,
    marginBottom: "5px",
  },
  legendItem: {
    display: "flex",
    flexDirection: "row",
    margin: "5px 0px 5px 0px",
    alignItems: "center",
  },
  legendSquare: {
    width: "9px",
    height: "9px",
    marginRight: "5px",
  },
  legendText: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default useStyles;
