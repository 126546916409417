/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    maxWidth: 224,
    height: 40,
    display: "flex",
    alignItems: "center",
    padding: "0px 20px 0px 20px",

    "&:hover": {
      backgroundColor: theme.background.secondary,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.hover,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:focus": {
      outline: `none`,
    },
    "&:first-of-type": {
      borderRadius: "4px 4px 0px 0px",
    },
    "&:last-of-type": {
      borderRadius: "0px 0px 4px 0px",
    },
    [breakpoints.max.smd]: {
      padding: "0px 20px 0px 20px",
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  slider: {
    opacity: 1,

    "& .MuiSlider-root": {
      width: "100%",
      marginTop: 5,
    },
    "& .MuiSlider-thumb": {
      opacity: 1,
      boxShadow: "none",
      height: 10,
      width: 10,
      transform: "translate(2px, 1px)",

      "&:focus, &:hover, &$active": {
        boxShadow: "none",
      },
    },
    "& .MuiSlider-track": {
      opacity: 1,
      backgroundColor: theme.primary,
    },
    "& .MuiSlider-rail": {
      opacity: 1,
      backgroundColor: theme.filters.timeline.mark.unactive,
    },
    "& .MuiSlider-mark": {
      height: 6,
      width: 2,
      top: 11,
      borderRadius: "30%",
      backgroundColor: theme.filters.timeline.mark.unactive,

      "&:nth-child(5n -1)": {
        height: 12,
        top: 8,
      },
    },
    "& .MuiSlider-markActive": {
      width: 2,
      backgroundColor: theme.filters.timeline.mark.selected,
      borderRadius: "30%",
    },
  },
}));

export default useStyles;
