import React, { useContext, useEffect, useState } from "react";
import FiltersContext from "../../../../contexts/filtering";
import TransitionStatistics from "./Transitions";
import WaterSurfaceStatistics from "./WaterSurface";

export default function Statistics() {
  const {
    values,
    options: { analysisOptions },
  } = useContext(FiltersContext);
  const [statistics, setStatistics] = useState(<></>);

  useEffect(() => {
    if (values.analysis === analysisOptions.transition?.key) {
      setStatistics(<TransitionStatistics />);
    } else if (values.analysis === analysisOptions.surface?.key) {
      setStatistics(<WaterSurfaceStatistics />);
    } else {
      setStatistics(<></>);
    }
  }, [values.analysis]);

  return statistics;
}
