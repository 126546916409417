/* eslint-disable no-unused-vars */
import React from "react";
import DashboardLoader from "../../../components/DashboardLoader";

function Loader() {
  return null;
  /*  if (!mapLoading) {
    return null;
  }

  return <DashboardLoader />; */
}

export default Loader;
