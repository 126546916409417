/* eslint-disable react/no-this-in-sfc */
import React from "react";
import { useTheme } from "react-jss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import highchartsMore from "highcharts/highcharts-more";
import { InfoOutlined } from "@material-ui/icons";
import useOptions from "../../../../hooks/options";
import useStyles from "../styles";
import CustomTooltip from "../../../CustomTooltip";

highchartsMore(Highcharts);

/**
 * This component is a stylized chart to display stacked area data.
 */
export default function Lines({ title, info, data }) {
  Lines.defaultProps = {
    data: undefined,
  };

  Lines.propTypes = {
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    data: PropTypes.shape(),
  };

  /**
   * The component will be rendered only if there are data.
   */
  if (!data) {
    return null;
  }

  const theme = useTheme();
  const defaultOptions = useOptions();
  const { t } = useTranslation();
  const classes = useStyles();

  const options = {
    chart: {
      type: "line",
      ...defaultOptions.chart,
      height: 300,
    },
    credits: defaultOptions.credits,
    title: {
      text: title,
      ...defaultOptions.title,
    },
    xAxis: {
      ...defaultOptions.xAxis,
      labels: {
        style: {
          fontSize: "10px",
          color: theme.text.secondary,
          ...defaultOptions.yAxis.labels.style,
        },
        formatter() {
          if (data.timeMode === "monthly") {
            return t("general.date.monthYear", { date: new Date(this.value) });
          }
          return t("general.date.year", { date: new Date(this.value) });
        },
      },
      categories: data.x,
    },
    yAxis: defaultOptions.yAxis,
    tooltip: {
      useHTML: true,
      formatter() {
        if (data.timeMode === "monthly") {
          return `<b>${t("general.date.monthNameYear", {
            date: new Date(this.point.category),
          })}</b> </br>
          <tr><td style="color: ${this.series.color}">
          ${this.series.name}: </td>
            <td style="text-align: right"><b>${t("general.roundNumber", {
              value: this.point.y,
            })} ha
          </b></td></tr>`;
        }
        return `<b>${t("general.date.year", {
          date: new Date(this.point.category),
        })}</b> </br>
            <tr><td style="color: ${this.series.color}">${
          this.series.name
        }: </td>
              <td style="text-align: right"><b>${t("general.roundNumber", {
                value: this.point.y,
              })} ha
            </b></td></tr>`;
      },
    },
    legend: { ...defaultOptions.legend, enabled: true },
    series: data.series,
    navigation: defaultOptions.navigation,
    lang: defaultOptions.lang,
    exporting: {
      ...defaultOptions.exporting,
      chartOptions: {
        chart: {
          events: null,
          style: {
            backgroundColor: theme.background.primary,
          },
        },
      },
    },
  };

  return (
    <div id="container" className={classes.wrapper}>
      <HighchartsReact highcharts={Highcharts} options={options} />
      <div className={classes.tooltip}>
        <CustomTooltip title={info} placement="bottom">
          <InfoOutlined
            style={{
              color: theme.text.primary,
              fontSize: "15px",
            }}
          />
        </CustomTooltip>
      </div>
    </div>
  );
}
