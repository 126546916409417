/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    width: 224,
    maxWidth: 224,
    height: 40,
    display: "flex",
    alignItems: "center",
    padding: "0px 15px",

    "&:hover": {
      backgroundColor: theme.background.secondary,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.hover,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:focus": {
      outline: `none`,
    },
    "&:first-of-type": {
      borderRadius: "4px 4px 0px 0px",
    },
    "&:last-of-type": {
      borderRadius: "0px 0px 4px 4px",
    },
    "&:only-of-type": {
      borderRadius: 4,
    },
  },
  actived: {
    backgroundColor: theme.hover,

    "&:hover": {
      backgroundColor: theme.background.secondary,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.hover,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:focus": {
      outline: `none`,
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 11,

    [breakpoints.max.smd]: {
      paddingLeft: "initial",
      paddingRight: 11,
    },
  },
  icon: {
    "& path": {
      fill: theme.text.primary,
    },
    [breakpoints.max.smd]: {
      transform: "rotate(180deg)",
    },
    transition: "transform 0.2s ease",
  },
  iconWrapper: {
    width: 23,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  optionsWrapper: {
    [breakpoints.min.smd]: {
      display: "none",
    },
  },
  checkContainer: {
    backgroundColor: theme.primary,
    minWidth: "25px",
    height: "15px",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    transition: "all 0.2s ease",
  },
  checkMark: {
    backgroundColor: "white",
    width: "11px",
    height: "11px",
    borderRadius: "100%",
    margin: "2px",
  },
}));

export default useStyles;
