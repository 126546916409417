/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import MapGL, { ScaleControl, Marker, Source, Layer } from "react-map-gl";
import { useParams } from "react-router-dom";
import { useMediaQuery, Paper, Typography } from "@material-ui/core";
import classNames from "classnames";
import { Editor, DrawRectangleMode } from "react-map-gl-draw";
import { useTranslation } from "react-i18next";
import Fade from "@material-ui/core/Fade";
import i18next from "i18next";
import ThemeContext from "../../../contexts/theming";
import useStyles, { getFeatureStyle, getEditHandleStyle } from "./styles";
import SvgNorth from "../../Icons/North";
import SvgAbout from "../../Icons/About";
import Zoom from "./Zoom";
import Location from "./Location";
import MapContext from "../../../contexts/mapping";
import LocationContext from "../../../contexts/location";
import { breakpoints } from "../../../constants/constraints";
import { SvgMapCursor } from "../../Icons";
import FiltersContext from "../../../contexts/filtering";
import GeodatinLogo from "../../../assets/images/geodatin_map.svg";
import SvgShare from "../../Icons/Share";
import Share from "./Share";
import api, { geApi } from "../../../services/api";
import GifContainer from "./GifContainer";
import CustomTooltip from "../../CustomTooltip";
import WaterChartContainer from "./WaterChartContainer";
import TrendChartContainer from "./TrendChartContainer";
import MapPinLight from "../../../assets/images/map_pin_light.svg";
import MapPinDark from "../../../assets/images/map_pin_dark.svg";
import OptionsButton from "./OptionsButton";
import SvgCamera from "../../Icons/Camera";
import OptionButton from "./OptionButton";
import { isTouchDevice } from "../../../constants/utils";
import SvgMap from "../../Icons/Map";
import BaseMapOptions from "./BaseMapOptions";
import BaseMapOption from "./BaseMapOptions/BaseMapOption";
import ClassificationChartContainer from "./ClassificationChartContainer";
import Attention from "../Attention";
import SvgOpacity from "../../Icons/Opacity";
import MapOpacity from "./MapOpacity";
import InfoContainer from "./InfoContainer";
import LayersButton from "./LayersButton";
import CustomTypography from "../../CustomTypography";
import SvgLayers from "../../Icons/Layers";

/**
 * This function renders the setted up MapBox componnent.
 * @returns The MapBox component.
 */
function MapView({ route, children, legend, postLocationChild, loader }) {
  MapView.propTypes = {
    route: PropTypes.string.isRequired,
    children: PropTypes.shape().isRequired,
    legend: PropTypes.shape().isRequired,
    postLocationChild: PropTypes.shape().isRequired,
    loader: PropTypes.shape().isRequired,
  };

  const isSmd = useMediaQuery(breakpoints.max.smd);
  const params = useParams();
  const {
    mapRef,
    mapLoaded,
    setMapLoaded,
    onClick,
    onHover,
    setPointedColor,
    cursor,
    setCursor,
    setHoveringFeatures,
    baseMap,
    setBaseMap,
    viewport,
    setViewport,
    infoContent,
    setAnimation,
    layers,
    updateLayer,
    addLayers,
    resetLayers,
    transparentBackground,
    setTransparentBackground,
  } = useContext(MapContext);
  const {
    values: {
      analysis,
      timeMode,
      initialTime,
      endTime,
      territory,
      dataType,
      initiative,
    },
    options: { timeOptions, analysisOptions },
  } = useContext(FiltersContext);
  const { handleMapRoute } = useContext(LocationContext);
  const [drag, setDrag] = useState(false);
  const [shareOpened, setShareOpened] = useState(false);
  const [attentionOpened, setAttentionOpened] = useState(true);
  const [mode, setMode] = useState();
  const [drawFeature, setDrawFeature] = useState([]);
  const [drawing, setDrawing] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const { themeName, selectedTheme } = useContext(ThemeContext);
  const classes = useStyles();
  const [waterGifAddress, setWaterGifAddress] = useState();
  const [waterGifLoading, setWaterGifLoading] = useState(false);
  const [classificationGifAddress, setClassificationGifAddress] = useState();
  const [classificationGifLoading, setClassificationGifLoading] =
    useState(false);
  const editorRef = useRef();
  const { t } = useTranslation();
  const [waterChartData, setWaterChartData] = useState();
  const [waterClassificationChartData, setWaterClassificationChartData] =
    useState();
  const [trendChartData, setTrendChartData] = useState();
  const [lastLngLatClick, setLastLngLatClick] = useState();
  const [waterPixelLoading, setWaterPixelLoading] = useState(false);
  const [trendPixelLoading, setTrendPixelLoading] = useState(false);
  const [waterClassificationPixelLoading, setWaterClassificationPixelLoading] =
    useState(false);
  const [openBaseMapOptions, setOpenBaseMapOptions] = useState(false);
  const [openMapOptions, setOpenMapOptions] = useState(false);
  const [openMapLayers, setOpenMapLayers] = useState(false);
  const [zoomLoaded, setZoomLoaded] = useState(false);
  const [openOpacity, setOpenOpacity] = useState(false);
  const [initiativeLayers, setInitiativeLayers] = useState([]);

  /**
   * When the style is loaded, the map is setted as loaded.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (mapLoaded) {
      const map = mapRef.current.getMap();

      map.on("style.load", () => {
        setMapLoaded(true);

        setTimeout(() => {
          if (isSubscribed) {
            setViewport((vw) => ({ ...vw, zoom: vw.zoom + 0.1 }));

            setTimeout(() => {
              if (isSubscribed) {
                setViewport((vw) => ({ ...vw, zoom: vw.zoom - 0.1 }));
              }
            }, 250);
          }
        }, 250);
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [mapLoaded]);

  /**
   * Reset the loading state when the theme is changed.
   */
  useEffect(() => {
    if (mapLoaded) {
      if (baseMap === "default") {
        setMapLoaded(false);
      }
    }
  }, [themeName]);

  /**
   * This function handle the url address according to the current viewport params.
   */
  function handleHistoryChange() {
    if (viewport.latitude && viewport.longitude && viewport.zoom) {
      handleMapRoute(
        route,
        viewport.latitude.toFixed(6),
        viewport.longitude.toFixed(6),
        viewport.zoom.toFixed(1)
      );
    }
  }

  /**
   * This function resets the map animation when a transition ends.
   */
  function handleTransitionEnd() {
    handleHistoryChange();
  }

  /**
   * Loads route params and sets the viewport.
   */
  useEffect(() => {
    if (
      params.lat &&
      params.lng &&
      params.zoom &&
      params.territoryCode &&
      params.territoryType &&
      params.analysis
    ) {
      const auxViewport = {};
      let error = false;

      if (!Number.isNaN(params.lat)) {
        const latitude = parseFloat(params.lat);

        if (latitude >= -90 && latitude <= 90) {
          auxViewport.latitude = latitude;
        } else {
          error = true;
        }
      }
      if (!error && !Number.isNaN(params.lnt)) {
        const longitude = parseFloat(params.lng);

        if (longitude >= -180 && longitude <= 180) {
          auxViewport.longitude = longitude;
        } else {
          error = true;
        }
      }
      if (!error && !Number.isNaN(params.zoom)) {
        const zoom = parseFloat(params.zoom);

        if (zoom >= 0 && zoom <= 24) {
          auxViewport.zoom = parseFloat(params.zoom);
          setZoomLoaded(true);
        } else {
          error = true;
        }
      }

      if (error) {
        handleHistoryChange();
      } else {
        setViewport((v) => ({ ...v, ...auxViewport }));
      }
    } else {
      handleHistoryChange();
    }
  }, []);

  /**
   * This function changes the viewport zoom value.
   * @param {Change the viewport zoom value} zoom
   */
  function setZoom(zoom) {
    setViewport((v) => ({ ...v, zoom }));
  }

  /**
   * This function changes the viewport latitude and longitude.
   * @param {New items to do the viewport transition} newViewport
   */
  function setLocation(newViewport) {
    setViewport((v) => ({ ...v, ...newViewport }));
  }

  function onHandleHover(event) {
    const { features, point } = event;
    const feature = features && features.find((f) => f.layer.id === "data");

    setHoveringFeatures(features);

    if (drag) {
      setCursor("grabbing");
    } else if (drawing || selecting) {
      setCursor("crosshair");
    } else if (feature || (territory && territory.type !== "continent")) {
      setCursor("pointer");
    } else {
      setCursor("grab");
    }

    // Gets the color under the cursor:
    if (analysis === analysisOptions.transition?.key) {
      const canvas = mapRef.current.getMap().getCanvas();
      const gl = canvas.getContext("webgl") || canvas.getContext("webgl2");
      if (gl) {
        const x = point[0];
        const y = point[1];
        const data = new Uint8Array(4);
        const intX = parseInt(x, 10);
        const intY = parseInt(y, 10);
        const canvasX = intX - canvas.offsetLeft;
        const canvasY = canvas.height - intY - canvas.offsetTop;
        gl.readPixels(canvasX, canvasY, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, data);
        const [r, g, b, a] = data;
        setPointedColor({ r, g, b, a });
      }
    }
  }

  function onUpdateDraw({ data }) {
    let coordinates = [];

    if (data.length > 1) {
      const feature = data[1];
      setDrawFeature([feature]);
      coordinates = feature.geometry.coordinates;
    } else {
      const feature = data[0];
      setDrawFeature([feature]);
      coordinates = feature.geometry.coordinates;
    }

    if (timeMode === "annual") {
      if (analysis === analysisOptions.surface?.key) {
        setWaterGifLoading(true);
        geApi
          .post("/gif", {
            region: coordinates[0],
            cadence: timeMode,
            years: [parseInt(initialTime, 10), parseInt(endTime, 10)],
            theme: themeName === "dark" ? "dark" : "light",
            type: dataType === "glacier" ? "glacier" : "water",
          })
          .then((resp) => {
            setWaterGifAddress(resp.data.url);
            setWaterGifLoading(false);
          });
      } else if (analysis === analysisOptions.waterClassification?.key) {
        setClassificationGifLoading(true);
        const { years } = timeOptions;

        geApi
          .post("/gif", {
            region: coordinates[0],
            cadence: timeMode,
            years: [years[0], years[years.length - 1]],
            theme: themeName === "dark" ? "dark" : "light",
            type: "classification",
          })
          .then((resp) => {
            setClassificationGifAddress(resp.data.url);
            setClassificationGifLoading(false);
          });
      }
    } else if (analysis === analysisOptions.surface?.key) {
      const initialYear = initialTime.substring(2).replace("-", "");
      const initialMonth = initialTime.substring(0, 2).replace("-", "");
      const endYear = endTime.substring(2).replace("-", "");
      const endMonth = endTime.substring(0, 2).replace("-", "");

      setWaterGifLoading(true);
      geApi
        .post("/gif", {
          region: coordinates[0],
          cadence: timeMode,
          years: [parseInt(initialYear, 10), parseInt(endYear, 10)],
          months: [parseInt(initialMonth, 10), parseInt(endMonth, 10)],
          theme: themeName === "dark" ? "dark" : "light",
          type: dataType === "glacier" ? "glacier" : "water",
        })
        .then((resp) => {
          setWaterGifAddress(resp.data.url);
          setWaterGifLoading(false);
        });
    }
  }

  useEffect(() => {
    let isSubscribed = true;

    if (drawFeature[0]) {
      if (timeMode === "annual") {
        if (analysis === analysisOptions.surface.key) {
          setWaterGifAddress();
          setWaterGifLoading(true);

          const { coordinates } = drawFeature[0].geometry;

          geApi
            .post("/gif", {
              region: coordinates[0],
              cadence: timeMode,
              years: [parseInt(initialTime, 10), parseInt(endTime, 10)],
              theme: themeName === "dark" ? "dark" : "light",
            })
            .then((resp) => {
              if (isSubscribed) {
                setWaterGifAddress(resp.data.url);
                setWaterGifLoading(false);
              }
            });
        }
      } else if (analysis === analysisOptions.surface.key) {
        const initialYear = initialTime.substring(2).replace("-", "");
        const initialMonth = initialTime.substring(0, 2).replace("-", "");
        const endYear = endTime.substring(2).replace("-", "");
        const endMonth = endTime.substring(0, 2).replace("-", "");
        setWaterGifAddress();
        setWaterGifLoading(true);

        const { coordinates } = drawFeature[0].geometry;

        geApi
          .post("/gif", {
            region: coordinates[0],
            cadence: timeMode,
            years: [parseInt(initialYear, 10), parseInt(endYear, 10)],
            months: [parseInt(initialMonth, 10), parseInt(endMonth, 10)],
            theme: themeName === "dark" ? "dark" : "light",
          })
          .then((resp) => {
            if (isSubscribed) {
              setWaterGifAddress(resp.data.url);
              setWaterGifLoading(false);
            }
          });
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [themeName, initialTime, endTime]);

  useEffect(() => {
    let isSubscribed = true;

    if (drawFeature[0]) {
      if (
        classificationGifAddress &&
        analysis === analysisOptions.waterClassification?.key
      ) {
        setClassificationGifAddress();
        setClassificationGifLoading(true);

        const { coordinates } = drawFeature[0].geometry;
        const { years } = timeOptions;

        geApi
          .post("/gif", {
            region: coordinates[0],
            cadence: timeMode,
            years: [years[0], years[years.length - 1]],
            theme: themeName === "dark" ? "dark" : "light",
            type: "classification",
          })
          .then((resp) => {
            if (isSubscribed) {
              setClassificationGifAddress(resp.data.url);
              setClassificationGifLoading(false);
            }
          });
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [themeName]);

  useEffect(() => {
    setDrawFeature([]);
    setMode();
    setCursor("grab");

    setWaterGifAddress();
    setClassificationGifAddress();
    setWaterGifLoading(false);
    setClassificationGifLoading(false);
    setSelecting(false);
    setLastLngLatClick();
    setWaterPixelLoading(false);
    setTrendPixelLoading(false);
    setWaterClassificationPixelLoading(false);
    setWaterClassificationChartData();
    setWaterChartData();
    setTrendChartData();
  }, [analysis]);

  function handleCloseGif() {
    setWaterGifAddress();
    setClassificationGifAddress();
    setDrawFeature([]);
  }

  function handleCloseChart() {
    setWaterChartData();
    setWaterClassificationChartData();
    setTrendChartData();
    setWaterPixelLoading(false);
    setWaterClassificationPixelLoading(false);
    setTrendPixelLoading(false);
    setCursor("grab");
    setSelecting(false);
    setLastLngLatClick();
  }

  function handleClick(e) {
    if (drawing) {
      handleCloseChart();
      setMode();
      setCursor("grab");
      setDrawing(false);
    } else if (selecting) {
      if (analysis === analysisOptions.surface?.key) {
        if (isSmd) {
          handleCloseChart();
          setCursor("grab");
          setSelecting(false);
        }
        handleCloseGif();
        setLastLngLatClick(e.lngLat);

        setWaterPixelLoading(true);
        if (timeMode === "annual") {
          geApi
            .post("timeSeries", {
              coordinates: e.lngLat,
              cadence: timeMode,
              years: [parseInt(initialTime, 10), parseInt(endTime, 10)],
              type: dataType === "glacier" ? "glacier" : "water",
            })
            .then(({ data }) => {
              setWaterChartData(data);
              setWaterPixelLoading(false);
            });
        } else {
          const initialYear = initialTime.substring(2).replace("-", "");
          const initialMonth = initialTime.substring(0, 2).replace("-", "");
          const endYear = endTime.substring(2).replace("-", "");
          const endMonth = endTime.substring(0, 2).replace("-", "");

          geApi
            .post("timeSeries", {
              coordinates: e.lngLat,
              cadence: timeMode,
              years: [parseInt(initialYear, 10), parseInt(endYear, 10)],
              months: [parseInt(initialMonth, 10), parseInt(endMonth, 10)],
              type: dataType === "glacier" ? "glacier" : "water",
            })
            .then(({ data }) => {
              setWaterChartData(data);
              setWaterPixelLoading(false);
            });
        }
      } else if (analysis === analysisOptions.trend?.key) {
        if (isSmd) {
          handleCloseChart();
          setCursor("grab");
          setSelecting(false);
        }
        handleCloseGif();
        setLastLngLatClick(e.lngLat);

        setTrendPixelLoading(true);

        geApi
          .post("harmonicSeries", {
            coordinates: e.lngLat,
            cadence: timeMode,
            years: [parseInt(initialTime, 10), parseInt(endTime, 10)],
          })
          .then(({ data }) => {
            data.series = data.series.map((obj) => {
              obj.color = selectedTheme.trend[obj.name];
              obj.name = t(
                `dashboard.statistics.charts.trend.lines.${obj.name}`
              );
              return obj;
            });
            setTrendChartData(data);
            setTrendPixelLoading(false);
          });
      } else if (analysis === analysisOptions.waterClassification?.key) {
        if (isSmd) {
          handleCloseChart();
          setCursor("grab");
          setSelecting(false);
        }
        handleCloseGif();
        setLastLngLatClick(e.lngLat);

        setWaterClassificationPixelLoading(true);
        const { years } = timeOptions;

        geApi
          .post("timeSeries", {
            coordinates: e.lngLat,
            cadence: timeMode,
            years: [years[0], years[years.length - 1]],
            type: "classification",
          })
          .then(({ data }) => {
            setWaterClassificationChartData(data);
            setWaterClassificationPixelLoading(false);
          });
      }
    } else {
      onClick(e);
    }
  }

  /**
   * Update chart data if the time is changed.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (lastLngLatClick) {
      if (waterChartData) {
        geApi
          .post("timeSeries", {
            coordinates: lastLngLatClick,
            cadence: timeMode,
            years: [parseInt(initialTime, 10), parseInt(endTime, 10)],
            type: dataType === "glacier" ? "glacier" : "water",
          })
          .then(({ data }) => {
            if (isSubscribed) {
              setWaterChartData(data);
            }
          });
      } else if (trendChartData) {
        geApi
          .post("harmonicSeries", {
            coordinates: lastLngLatClick,
            cadence: timeMode,
            years: [parseInt(initialTime, 10), parseInt(endTime, 10)],
          })
          .then(({ data }) => {
            if (isSubscribed) {
              data.series = data.series.map((obj) => {
                obj.color = selectedTheme.trend[obj.name];
                obj.name = t(
                  `dashboard.statistics.charts.trend.lines.${obj.name}`
                );
                return obj;
              });
              setTrendChartData(data);
            }
          });
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [initialTime, endTime]);

  function closeAllSuboptions() {
    setOpenBaseMapOptions(false);
    setOpenOpacity(false);
  }

  /**
   * Reset options when the analysis is changed.
   */
  useEffect(() => {
    closeAllSuboptions();
    setOpenMapOptions(false);
  }, [analysis]);

  function handleOptionsClick() {
    if (isSmd) {
      if (waterGifAddress || waterChartData) {
        handleCloseChart();
        handleCloseGif();
      }
    }
    if (selecting) {
      setCursor("grab");
      setSelecting(false);
    } else if (drawing) {
      setCursor("grab");
      setMode();
      setDrawing(false);
    }
    setOpenMapLayers(false);
  }

  function handleOpenMapLayersClick() {
    setOpenMapOptions(false);
  }

  const planetDate = useMemo(() => {
    const d = new Date();
    d.setMonth(d.getMonth() - 2);

    let month = d.getMonth() + 1;
    month = month.toString();

    if (month.length === 1) {
      month = `0${month.toString()}`;
    }

    return `${d.getFullYear()}-${month}`;
  }, []);

  const baseMapOptions = useMemo(() => {
    let d = new Date();
    d.setMonth(d.getMonth() - 2);

    let month = d.getMonth() + 1;
    month = month.toString();

    if (month.length === 1) {
      month = `0${month.toString()}`;
    }

    d = `${month}/${d.getFullYear()}`;

    return [
      <BaseMapOption
        key="default"
        checked={baseMap === "default"}
        title={
          themeName === "light"
            ? t("dashboard.map.baseMap.light")
            : t("dashboard.map.baseMap.dark")
        }
        onClick={() => {
          setBaseMap("default");
        }}
      />,
      <BaseMapOption
        key="satellite"
        checked={baseMap === "satellite"}
        title={t("dashboard.map.baseMap.satellite")}
        onClick={() => {
          setBaseMap("satellite");
        }}
      />,
      /* <BaseMapOption
      key="mapbiomas"
        checked={baseMap === "mapbiomas"}
        title={t("dashboard.map.baseMap.mapbiomas")}
        onClick={() => {
          setBaseMap("mapbiomas");
        }}
      />, */
      <BaseMapOption
        key="planet"
        checked={baseMap === "planet"}
        title={`${t("dashboard.map.baseMap.planet")} (${d})`}
        onClick={() => {
          setBaseMap("planet");
        }}
      />,
    ];
  }, [baseMap, themeName, t]);

  const optionsList = useMemo(() => {
    if (isTouchDevice()) {
      if (analysis === analysisOptions.surface?.key) {
        return [
          <OptionButton
            key="mobile.water.opacity"
            title={t("dashboard.map.opacity.title")}
            icon={<SvgOpacity className={classes.navigationIcon} />}
            onClick={() => {
              closeAllSuboptions();
              setOpenOpacity(!openOpacity);
            }}
            actived={openOpacity}
            options={<MapOpacity />}
            accordionMode
          />,
          <OptionButton
            key="mobile.water.baseMap"
            title={t("dashboard.map.baseMap.title")}
            icon={<SvgMap className={classes.navigationIcon} />}
            onClick={() => {
              const openBaseMapOptionsAux = openBaseMapOptions;
              closeAllSuboptions();
              setOpenBaseMapOptions(!openBaseMapOptionsAux);
            }}
            actived={openBaseMapOptions}
            options={baseMapOptions}
          />,
          <OptionButton
            key="mobile.water.backgroundOpacity"
            title={t("dashboard.map.backgroundOpacity.title")}
            icon={<SvgLayers className={classes.backgroundOpacityIcon} />}
            onClick={() => {
              closeAllSuboptions();
              setTransparentBackground((oldValue) => !oldValue);
            }}
            checked={transparentBackground}
            checkable
          />,
          <OptionButton
            key="mobile.water.pixel"
            title={t("dashboard.map.clickSelectionTooltip")}
            icon={<SvgAbout className={classes.navigationIcon} />}
            onClick={() => {
              setMode();
              setDrawing(false);
              setCursor("crosshair");
              setSelecting(true);
              setOpenMapOptions(false);
              closeAllSuboptions();
            }}
          />,
          <OptionButton
            key="mobile.water.selection"
            title={t("dashboard.map.selectionTooltip")}
            icon={<SvgCamera className={classes.navigationIcon} />}
            onClick={() => {
              setSelecting(false);
              setCursor("crosshair");
              setMode(new DrawRectangleMode());
              setDrawing(true);
              setOpenMapOptions(false);
              closeAllSuboptions();
            }}
          />,
        ];
      }

      if (analysis === analysisOptions.trend?.key) {
        return [
          <OptionButton
            key="mobile.trend.opacity"
            title={t("dashboard.map.opacity.title")}
            icon={<SvgOpacity className={classes.navigationIcon} />}
            onClick={() => {
              closeAllSuboptions();
              setOpenOpacity(!openOpacity);
            }}
            actived={openOpacity}
            options={<MapOpacity />}
            accordionMode
          />,
          <OptionButton
            key="mobile.trend.baseMap"
            title={t("dashboard.map.baseMap.title")}
            icon={<SvgMap className={classes.navigationIcon} />}
            onClick={() => {
              const openBaseMapOptionsAux = openBaseMapOptions;
              closeAllSuboptions();
              setOpenBaseMapOptions(!openBaseMapOptionsAux);
            }}
            actived={openBaseMapOptions}
            options={baseMapOptions}
          />,
          <OptionButton
            key="mobile.trend.backgroundOpacity"
            title={t("dashboard.map.backgroundOpacity.title")}
            icon={<SvgLayers className={classes.backgroundOpacityIcon} />}
            onClick={() => {
              closeAllSuboptions();
              setTransparentBackground((oldValue) => !oldValue);
            }}
            checked={transparentBackground}
            checkable
          />,
          <OptionButton
            key="mobile.trend.pixel"
            title={t("dashboard.map.clickSelectionTooltip")}
            icon={<SvgAbout className={classes.navigationIcon} />}
            onClick={() => {
              setMode();
              setDrawing(false);
              setCursor("crosshair");
              setSelecting(true);
              setOpenMapOptions(false);
              closeAllSuboptions();
            }}
          />,
        ];
      }

      if (analysis === analysisOptions.waterClassification?.key) {
        return [
          <OptionButton
            key="mobile.waterClassification.opacity"
            title={t("dashboard.map.opacity.title")}
            icon={<SvgOpacity className={classes.navigationIcon} />}
            onClick={() => {
              closeAllSuboptions();
              setOpenOpacity(!openOpacity);
            }}
            actived={openOpacity}
            options={<MapOpacity />}
            accordionMode
          />,
          <OptionButton
            key="mobile.waterClassification.baseMap"
            title={t("dashboard.map.baseMap.title")}
            icon={<SvgMap className={classes.navigationIcon} />}
            onClick={() => {
              const openBaseMapOptionsAux = openBaseMapOptions;
              closeAllSuboptions();
              setOpenBaseMapOptions(!openBaseMapOptionsAux);
            }}
            actived={openBaseMapOptions}
            options={baseMapOptions}
          />,
          <OptionButton
            key="mobile.waterClassification.backgroundOpacity"
            title={t("dashboard.map.backgroundOpacity.title")}
            icon={<SvgLayers className={classes.backgroundOpacityIcon} />}
            onClick={() => {
              closeAllSuboptions();
              setTransparentBackground((oldValue) => !oldValue);
            }}
            checked={transparentBackground}
            checkable
          />,
          <OptionButton
            key="mobile.waterClassification.pixel"
            title={t("dashboard.map.clickSelectionTooltip")}
            icon={<SvgAbout className={classes.navigationIcon} />}
            onClick={() => {
              setMode();
              setDrawing(false);
              setCursor("crosshair");
              setSelecting(true);
              setOpenMapOptions(false);
              closeAllSuboptions();
            }}
          />,
          <OptionButton
            key="mobile.waterClassification.selection"
            title={t("dashboard.map.selectionTooltip")}
            icon={<SvgCamera className={classes.navigationIcon} />}
            onClick={() => {
              setSelecting(false);
              setCursor("crosshair");
              setMode(new DrawRectangleMode());
              setDrawing(true);
              setOpenMapOptions(false);
              closeAllSuboptions();
            }}
          />,
        ];
      }

      return [
        <OptionButton
          key="mobile.water.opacity"
          title={t("dashboard.map.opacity.title")}
          icon={<SvgOpacity className={classes.navigationIcon} />}
          onClick={() => {
            closeAllSuboptions();
            setOpenOpacity(!openOpacity);
          }}
          actived={openOpacity}
          options={<MapOpacity />}
          accordionMode
        />,
        <OptionButton
          key="desktop.other.baseMap"
          title={t("dashboard.map.baseMap.title")}
          icon={<SvgMap className={classes.navigationIcon} />}
          onClick={() => {
            const openBaseMapOptionsAux = openBaseMapOptions;
            closeAllSuboptions();
            setOpenBaseMapOptions(!openBaseMapOptionsAux);
          }}
          actived={openBaseMapOptions}
          options={baseMapOptions}
        />,
        <OptionButton
          key="desktop.other.backgroundOpacity"
          title={t("dashboard.map.backgroundOpacity.title")}
          icon={<SvgLayers className={classes.backgroundOpacityIcon} />}
          onClick={() => {
            closeAllSuboptions();
            setTransparentBackground((oldValue) => !oldValue);
          }}
          checked={transparentBackground}
          checkable
        />,
      ];
    }

    if (analysis === analysisOptions.surface?.key) {
      return [
        <OptionButton
          key="desktop.water.opacity"
          title={t("dashboard.map.opacity.title")}
          icon={<SvgOpacity className={classes.navigationIcon} />}
          onClick={() => {
            closeAllSuboptions();
            setOpenOpacity(!openOpacity);
          }}
          actived={openOpacity}
          options={<MapOpacity />}
          accordionMode
        />,
        <OptionButton
          key="desktop.water.baseMap"
          title={t("dashboard.map.baseMap.title")}
          icon={<SvgMap className={classes.navigationIcon} />}
          onClick={() => {
            const openBaseMapOptionsAux = openBaseMapOptions;
            closeAllSuboptions();
            setOpenBaseMapOptions(!openBaseMapOptionsAux);
          }}
          actived={openBaseMapOptions}
          options={baseMapOptions}
        />,
        <OptionButton
          key="desktop.water.backgroundOpacity"
          title={t("dashboard.map.backgroundOpacity.title")}
          icon={<SvgLayers className={classes.backgroundOpacityIcon} />}
          onClick={() => {
            closeAllSuboptions();
            setTransparentBackground((oldValue) => !oldValue);
          }}
          checked={transparentBackground}
          checkable
        />,
        <OptionButton
          key="desktop.water.pixel"
          title={t("dashboard.map.clickSelectionTooltip")}
          icon={<SvgAbout className={classes.navigationIcon} />}
          onClick={() => {
            setMode();
            setDrawing(false);
            setCursor("crosshair");
            setSelecting(true);
            setOpenMapOptions(false);
            closeAllSuboptions();
          }}
        />,
        <OptionButton
          key="desktop.water.selection"
          title={t("dashboard.map.selectionTooltip")}
          icon={<SvgCamera className={classes.navigationIcon} />}
          onClick={() => {
            setSelecting(false);
            setCursor("crosshair");
            setMode(new DrawRectangleMode());
            setDrawing(true);
            setOpenMapOptions(false);
            closeAllSuboptions();
          }}
        />,
      ];
    }

    if (analysis === analysisOptions.trend?.key) {
      return [
        <OptionButton
          key="desktop.trend.opacity"
          title={t("dashboard.map.opacity.title")}
          icon={<SvgOpacity className={classes.navigationIcon} />}
          onClick={() => {
            closeAllSuboptions();
            setOpenOpacity(!openOpacity);
          }}
          actived={openOpacity}
          options={<MapOpacity />}
          accordionMode
        />,
        <OptionButton
          key="desktop.trend.baseMap"
          title={t("dashboard.map.baseMap.title")}
          icon={<SvgMap className={classes.navigationIcon} />}
          onClick={() => {
            const openBaseMapOptionsAux = openBaseMapOptions;
            closeAllSuboptions();
            setOpenBaseMapOptions(!openBaseMapOptionsAux);
          }}
          actived={openBaseMapOptions}
          options={baseMapOptions}
        />,
        <OptionButton
          key="desktop.trend.backgroundOpacity"
          title={t("dashboard.map.backgroundOpacity.title")}
          icon={<SvgLayers className={classes.backgroundOpacityIcon} />}
          onClick={() => {
            closeAllSuboptions();
            setTransparentBackground((oldValue) => !oldValue);
          }}
          checked={transparentBackground}
          checkable
        />,
        <OptionButton
          key="desktop.trend.selection"
          title={t("dashboard.map.clickSelectionTooltip")}
          icon={<SvgAbout className={classes.navigationIcon} />}
          onClick={() => {
            setMode();
            setDrawing(false);
            setCursor("crosshair");
            setSelecting(true);
            setOpenMapOptions(false);
            closeAllSuboptions();
          }}
        />,
      ];
    }

    if (analysis === analysisOptions.waterClassification?.key) {
      return [
        <OptionButton
          key="desktop.waterClassification.opacity"
          title={t("dashboard.map.opacity.title")}
          icon={<SvgOpacity className={classes.navigationIcon} />}
          onClick={() => {
            closeAllSuboptions();
            setOpenOpacity(!openOpacity);
          }}
          actived={openOpacity}
          options={<MapOpacity />}
          accordionMode
        />,
        <OptionButton
          key="desktop.waterClassification.baseMap"
          title={t("dashboard.map.baseMap.title")}
          icon={<SvgMap className={classes.navigationIcon} />}
          onClick={() => {
            const openBaseMapOptionsAux = openBaseMapOptions;
            closeAllSuboptions();
            setOpenBaseMapOptions(!openBaseMapOptionsAux);
          }}
          actived={openBaseMapOptions}
          options={baseMapOptions}
        />,
        <OptionButton
          key="desktop.waterClassification.backgroundOpacity"
          title={t("dashboard.map.backgroundOpacity.title")}
          icon={<SvgLayers className={classes.backgroundOpacityIcon} />}
          onClick={() => {
            closeAllSuboptions();
            setTransparentBackground((oldValue) => !oldValue);
          }}
          checked={transparentBackground}
          checkable
        />,
        <OptionButton
          key="desktop.waterClassification.pixel"
          title={t("dashboard.map.clickSelectionTooltip")}
          icon={<SvgAbout className={classes.navigationIcon} />}
          onClick={() => {
            setMode();
            setDrawing(false);
            setCursor("crosshair");
            setSelecting(true);
            setOpenMapOptions(false);
            closeAllSuboptions();
          }}
        />,
        <OptionButton
          key="desktop.waterClassification.selection"
          title={t("dashboard.map.selectionTooltip")}
          icon={<SvgCamera className={classes.navigationIcon} />}
          onClick={() => {
            setSelecting(false);
            setCursor("crosshair");
            setMode(new DrawRectangleMode());
            setDrawing(true);
            setOpenMapOptions(false);
            closeAllSuboptions();
          }}
        />,
      ];
    }

    return [
      <OptionButton
        key="desktop.other.opacity"
        title={t("dashboard.map.opacity.title")}
        icon={<SvgOpacity className={classes.navigationIcon} />}
        onClick={() => {
          closeAllSuboptions();
          setOpenOpacity(!openOpacity);
        }}
        actived={openOpacity}
        options={<MapOpacity />}
        accordionMode
      />,
      <OptionButton
        key="desktop.other.baseMap"
        title={t("dashboard.map.baseMap.title")}
        icon={<SvgMap className={classes.navigationIcon} />}
        onClick={() => {
          const openBaseMapOptionsAux = openBaseMapOptions;
          closeAllSuboptions();
          setOpenBaseMapOptions(!openBaseMapOptionsAux);
        }}
        actived={openBaseMapOptions}
        options={baseMapOptions}
      />,
      <OptionButton
        key="desktop.other.backgroundOpacity"
        title={t("dashboard.map.backgroundOpacity.title")}
        icon={<SvgLayers className={classes.backgroundOpacityIcon} />}
        onClick={() => {
          closeAllSuboptions();
          setTransparentBackground((oldValue) => !oldValue);
        }}
        checked={transparentBackground}
        checkable
      />,
    ];
  }, [
    selectedTheme,
    t,
    analysis,
    openBaseMapOptions,
    openOpacity,
    transparentBackground,
  ]);

  const layerList = useMemo(() => {
    const vectorItems = [];
    const rasterItems = [];

    if (
      /* analysis === analysisOptions.surface?.key && */
      dataType === "water" &&
      initiative === "brazil"
    ) {
      rasterItems.push({
        title: t("dashboard.map.layers.irrigation"),
        button: (
          <OptionButton
            key="desktop.other.irrigation"
            title={t("dashboard.map.layers.irrigation")}
            icon={<SvgMap className={classes.navigationIcon} />}
            onClick={() => {
              closeAllSuboptions();
              updateLayer("irrigation", "enabled", !layers.irrigation.enabled);
            }}
            checked={layers.irrigation.enabled}
            checkable
          />
        ),
      });
      rasterItems.push({
        title: t("dashboard.map.layers.intermittentWater"),
        button: (
          <OptionButton
            key="desktop.other.intermittentWater"
            title={t("dashboard.map.layers.intermittentWater")}
            icon={<SvgMap className={classes.navigationIcon} />}
            onClick={() => {
              closeAllSuboptions();
              updateLayer(
                "intermittentWater",
                "enabled",
                !layers.intermittentWater.enabled
              );
            }}
            checked={layers.intermittentWater.enabled}
            checkable
          />
        ),
      });
    }

    if (layers.initiative) {
      initiativeLayers.forEach((item) => {
        if (layers.initiative[item.type]) {
          const translation = `dashboard.filters.initiatives.layers.${item.type}`;

          i18next.addResource("pt", "translation", translation, item.namePt);
          i18next.addResource("en", "translation", translation, item.nameEn);
          i18next.addResource("es", "translation", translation, item.nameEs);

          vectorItems.push({
            title: t(`dashboard.filters.initiatives.layers.${item.type}`),
            button: (
              <OptionButton
                key={item.type}
                title={t(`dashboard.filters.initiatives.layers.${item.type}`)}
                icon={<SvgMap className={classes.navigationIcon} />}
                onClick={() => {
                  closeAllSuboptions();
                  updateLayer("initiative", [item.type], {
                    enabled: !layers.initiative[item.type].enabled,
                  });
                }}
                checked={layers.initiative[item.type].enabled}
                checkable
              />
            ),
          });
        }
      });
    }

    vectorItems.sort((a, b) => a.title.localeCompare(b.title));
    rasterItems.sort((a, b) => a.title.localeCompare(b.title));

    const items = [];

    if (rasterItems.length > 0) {
      items.push(
        <CustomTypography
          color={selectedTheme.primary}
          style={{ padding: "15px 15px 5px 15px" }}
        >
          {t(`dashboard.map.layers.rasterLayers`)}
        </CustomTypography>
      );
    }

    rasterItems.forEach((item) => items.push(item.button));
    if (vectorItems.length > 0) {
      items.push(
        <CustomTypography
          color={selectedTheme.primary}
          style={{ padding: "15px 15px 5px 15px" }}
        >
          {t(`dashboard.map.layers.vectorLayers`)}
        </CustomTypography>
      );
    }
    vectorItems.forEach((item) => items.push(item.button));

    return items;
  }, [analysis, layers, initiativeLayers, t, selectedTheme]);

  useEffect(() => {
    let isSubscribed = true;

    api.get(`initiatives/${initiative}/territoryTypes`).then(({ data }) => {
      if (isSubscribed) {
        const items = data;

        setInitiativeLayers(items);

        const layersItems = { initiative: {} };

        items.forEach((item) => {
          layersItems.initiative[item.type] = { enabled: false };
        });

        resetLayers();
        addLayers(layersItems);
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, [initiative, analysis]);

  const waterGifRef = useRef();
  const classificationGifRef = useRef();
  const waterClassificationChartRef = useRef();
  const trendChartRef = useRef();
  const waterChartRef = useRef();

  return (
    <div className={classes.container}>
      <MapGL
        {...viewport}
        ref={mapRef}
        getCursor={() => cursor}
        preserveDrawingBuffer
        width="100%"
        height="100%"
        onViewportChange={setViewport}
        dragRotate={false}
        mapStyle={
          baseMap === "default"
            ? themeName === "light"
              ? "mapbox://styles/geodatin/cknuhjfvj0vse18pi45prc4j1"
              : "mapbox://styles/geodatin/cknuhdu9m0vnr17paz9gilkl6"
            : "mapbox://styles/geodatin/ckrm76gaz1x4n17pi3c8q419r"
        }
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onTouchEnd={handleHistoryChange}
        onTransitionStart={() => setAnimation(true)}
        onTransitionEnd={() => {
          setAnimation(false);
          handleTransitionEnd();
        }}
        className={classes.map}
        onLoad={(e) => {
          setMapLoaded(true);
        }}
        onClick={handleClick}
        onHover={(e) => {
          onHandleHover(e);

          if (onHover) onHover(e);
        }}
        onMouseDown={() => setDrag(true)}
        onMouseUp={() => {
          setDrag(false);
          handleHistoryChange();
        }}
      >
        {/* <Source
          id="satellite"
          type="raster"
          tiles={[
            "https://brasil.mapserver.mapbiomas.org/wms/coverage.map?service=WMS&request=GetMap&layers=coverage&styles=&format=image%2Fpng&transparent=true&version=1.1.1&territory_ids=96&year=2019&class_tree_node_ids=28%2C36%2C50%2C51%2C52%2C35%2C29%2C37%2C38%2C41%2C40%2C39%2C30%2C43%2C42%2C54%2C56%2C55%2C57%2C53%2C44%2C31%2C45%2C46%2C47%2C34%2C32%2C49%2C48%2C33&width=256&height=256&srs=EPSG%3A3857&bbox={bbox-epsg-3857}",
          ]}
          tileSize={256}
        >
          <Layer
            id="satellite-layer"
            type="raster"
            beforeId="road-street"
            paint={{
              "raster-opacity": baseMap === "mapbiomas" ? 1 : 0,
            }}
          />
        </Source> */}
        <Source
          id="baseMap"
          type="raster"
          tiles={[
            `https://tiles.planet.com/basemaps/v1/planet-tiles/planet_medres_visual_${planetDate}_mosaic/gmap/{z}/{x}/{y}.png?api_key=57cd3a8c44024cfdb7446ac37d8d1fe9`,
          ]}
          tileSize={256}
        >
          <Layer
            id="baseMap-layer"
            type="raster"
            beforeId="road-street"
            paint={{
              "raster-opacity": baseMap === "planet" ? 1 : 0,
            }}
          />
        </Source>
        {lastLngLatClick && (
          <Marker
            latitude={lastLngLatClick[1]}
            longitude={lastLngLatClick[0]}
            offsetTop={-30}
            offsetLeft={-11}
            className={classes.marker}
          >
            <img
              className={classes.pinImg}
              src={themeName === "dark" ? MapPinDark : MapPinLight}
              alt="map_pin"
            />
          </Marker>
        )}
        <ScaleControl
          maxWidth={200}
          unit="metric"
          style={{
            position: "absolute",
            left: 72,
            bottom: 50,
          }}
          className={
            baseMap === "default" ? classes.scale : classes.scaleSatellite
          }
        />
        {children}
        <Editor
          ref={editorRef}
          clickRadius={12}
          mode={mode}
          features={drawFeature}
          onUpdate={onUpdateDraw}
          modeConfig={{ dragToDraw: false }}
          featureStyle={(e) => getFeatureStyle(e, selectedTheme)}
          editHandleStyle={(e) => getEditHandleStyle(e, selectedTheme)}
          selectable={false}
        />
      </MapGL>
      {loader}
      <GifContainer
        containerRef={waterGifRef}
        handleClose={handleCloseGif}
        gifAddress={waterGifAddress}
        gifLoading={waterGifLoading}
        opened={!!waterGifAddress}
      />
      <GifContainer
        containerRef={classificationGifRef}
        handleClose={handleCloseGif}
        gifAddress={classificationGifAddress}
        gifLoading={classificationGifLoading}
        opened={!!classificationGifAddress}
      />
      <WaterChartContainer
        containerRef={waterChartRef}
        handleClose={handleCloseChart}
        chartData={waterChartData}
        opened={!!waterChartData}
        loading={waterPixelLoading}
      />
      <ClassificationChartContainer
        containerRef={waterClassificationChartRef}
        handleClose={handleCloseChart}
        chartData={waterClassificationChartData}
        opened={!!waterClassificationChartData}
        loading={waterClassificationPixelLoading}
      />
      <TrendChartContainer
        containerRef={trendChartRef}
        handleClose={handleCloseChart}
        chartData={trendChartData}
        timeMode="monthly"
        opened={!!trendChartData}
        loading={trendPixelLoading}
      />
      <InfoContainer
        opened={!!infoContent}
        feature={infoContent}
        rightOffset={() => {
          if (!!waterGifAddress || waterGifLoading) {
            return waterGifRef.current.offsetWidth + 15;
          }

          if (!!classificationGifAddress || classificationGifLoading) {
            return classificationGifRef.current.offsetWidth + 15;
          }

          if (
            !!waterClassificationChartData ||
            waterClassificationPixelLoading
          ) {
            return waterClassificationChartRef.current.offsetWidth + 15;
          }

          if (!!trendChartData || trendPixelLoading) {
            return trendChartRef.current.offsetWidth + 15;
          }

          if (!!waterChartData || waterPixelLoading) {
            return waterChartRef.current.offsetWidth + 15;
          }

          return 0;
        }}
      />
      <a
        href="https://geodatin.com"
        target="blank"
        className={classes.geodatinContainer}
      >
        <img
          src={GeodatinLogo}
          alt="Geodatin Logo"
          className={classes.geodatinLogo}
        />
      </a>
      <div className={classes.middleContainer}>
        <div className={classes.north}>
          <SvgNorth
            className={classes.navigationIcon}
            style={{ transform: "scale(1.2)" }}
          />
        </div>
        <Zoom value={viewport.zoom} setValue={setZoom} />
        {legend}
      </div>
      <div className={classes.navigationContainer}>
        <CustomTooltip
          title={t("dashboard.map.shareTooltip")}
          placement="right"
        >
          <div
            role="button"
            className={classNames(
              classes.selectionButton,
              classes.clickableButton
            )}
            onClick={() => {
              setShareOpened(true);
            }}
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <SvgShare
              className={classes.navigationIcon}
              style={{ transform: "scale(1)" }}
            />
          </div>
        </CustomTooltip>

        <OptionsButton
          parentClasses={classes}
          actived={selecting || drawing}
          activedIcon={
            selecting ? (
              <SvgAbout className={classes.navigationIconActived} />
            ) : drawing ? (
              <SvgCamera className={classes.navigationIconActived} />
            ) : null
          }
          onClick={handleOptionsClick}
          setOpen={(e) => {
            setOpenMapOptions(e);
            setOpenMapLayers(false);
          }}
          open={openMapOptions}
          closeAllSuboptions={closeAllSuboptions}
          options={optionsList}
        >
          <Fade in={openBaseMapOptions}>
            <Paper elevation={0}>
              <BaseMapOptions options={baseMapOptions} />
            </Paper>
          </Fade>
        </OptionsButton>
        {layerList.length > 0 && (
          <LayersButton
            parentClasses={classes}
            onClick={handleOpenMapLayersClick}
            setOpen={(e) => {
              setOpenMapLayers(e);
              setOpenMapOptions(false);
            }}
            open={openMapLayers}
            closeAllSuboptions={closeAllSuboptions}
            options={layerList}
            customTooltip={t("dashboard.map.layers.tooltip")}
          />
        )}
        <div className={classes.bottomContainer}>
          <Location
            lat={viewport.latitude}
            lng={viewport.longitude}
            setValues={setLocation}
          />
          {postLocationChild}
        </div>
      </div>
      <SvgMapCursor className={classes.mapCursor} />
      {shareOpened && <Share close={() => setShareOpened(false)} />}
      {attentionOpened && <Attention close={() => setAttentionOpened(false)} />}
    </div>
  );
}

export default MapView;
