import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import MapLegend from "../../../components/Dashboard/MapView/Legend";
import FiltersContext from "../../../contexts/filtering";

/**
 * Handle the legend colors.
 */
function Legend() {
  const { t } = useTranslation();
  const {
    values: { analysis },
    options: { analysisOptions },
  } = useContext(FiltersContext);

  if (analysis === analysisOptions.transition.key) {
    return <></>;
  }

  return (
    <MapLegend
      iconColors={["#4BDDFE", "#1D9DB8", "#227789", "#1C5F6D"]}
      legendColors={["#4BDDFE", "#1D9DB8", "#227789", "#1C5F6D"]}
      description={[">0%", "50%", "100%"]}
      tooltip={t("dashboard.map.legend.tooltip.water")}
    />
  );
}

export default Legend;
