import * as React from "react";

function SvgMap(props) {
  return (
    <svg
      width={15}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.625.05a.429.429 0 01.232.379v8.57a.429.429 0 01-.209.368L9.363 11.94a.429.429 0 01-.429.006L5.07 9.798a.394.394 0 00-.42-.002l-.012.007L.78 11.945a.428.428 0 01-.637-.374V3.429a.429.429 0 01.183-.352l4.285-3a.429.429 0 01.492 0l4.04 2.829L13.182.078a.429.429 0 01.444-.029zM1.001 3.651v7.19l3.214-1.785c.069-.041.141-.075.214-.102V1.252L1 3.652zm4.497 5.404l3.22 1.788-.004-7.192-3.428-2.4v7.705c.073.026.144.059.212.1zm4.077 1.756L13 8.757V1.252l-3.429 2.4.004 7.16z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgMap;
