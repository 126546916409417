import { createUseStyles } from "react-jss";
import { styles } from "../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  languageIcon: {
    height: 16,

    "& path": {
      fill: theme.text.primary,
    },
  },
  downIcon: {
    height: 16,
    marginTop: 5,
    transform: "scale(0.6)",

    "& path": {
      fill: theme.text.primary,
    },
  },
  buttonText: {
    textTransform: "none",
  },
  menuPopOver: {
    "& .MuiPaper-root": {
      marginTop: 5,
      backgroundColor: theme.background.primary,
      color: theme.text.primary,
      borderRadius: 8,
      boxShadow: theme.shadow,
    },
    "& .MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiMenuItem-root": {
      paddingTop: 8,
      paddingBottom: 8,

      "&:active": {
        backgroundColor: theme.hover,
        transition: "background-color 0.15s ease",
        cursor: "pointer",
      },
      "& .MuiTouchRipple-root span": {
        display: "none",
      },
    },
    "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
      backgroundColor: theme.hover,
    },
    "& .MuiListItem-root:hover": {
      backgroundColor: theme.background.secondary,
    },
  },
  button: {
    ...styles(theme).button,
  },
}));

export default useStyles;
