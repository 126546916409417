/* eslint-disable no-unused-vars */
import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    maxWidth: 224,
    height: 40,
    display: "flex",
    alignItems: "center",
    padding: "0px 16px 0px 0px",

    "&:hover": {
      backgroundColor: theme.background.secondary,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.hover,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:focus": {
      outline: `none`,
    },
    "&:first-of-type": {
      borderRadius: "4px 4px 0px 0px",
    },
    "&:last-of-type": {
      borderRadius: "0px 0px 4px 0px",
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  radio: {
    "& .MuiIconButton-label": {
      transform: "scale(0.7)",
    },
    "& .MuiTouchRipple-root:hover": {
      display: "none",
    },
    "& .MuiTouchRipple-ripple, .MuiTouchRipple-rippleVisible": {
      display: "none",
    },
  },
}));

export default useStyles;
