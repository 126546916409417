import * as React from "react";

function SvgNext(props) {
  return (
    <svg
      width={8}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.434 1.224a1.06 1.06 0 00.199 1.224l4.46 4.46-4.46 4.46a1.06 1.06 0 101.5 1.5l5.252-5.253a1 1 0 000-1.414L2.133.948a1.06 1.06 0 00-1.699.276z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgNext;
