/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { useTheme } from "react-jss";
import useStyles from "./styles";
import CustomTypography from "../../CustomTypography";
import SvgClose from "../../Icons/Close";

/**
 * This function renders a custom modal.
 * @returns The custom modal component.
 */
function CustomModal({ close, buttonText, title, children }) {
  CustomModal.propTypes = {
    close: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.background}>
      <div
        className={classes.exit}
        role="button"
        onClick={() => close()}
        onKeyDown={() => {}}
        tabIndex={0}
      />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.header}>
            <CustomTypography variant="title">{title}</CustomTypography>
            <Button className={classes.closeButton} onClick={() => close()}>
              <SvgClose className={classes.closeIcon} />
            </Button>
          </div>
          <div className={classes.content}>
            <div className={classes.text}>{children}</div>
            <Button
              className={classes.copyButton}
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => close()}
            >
              <CustomTypography variant="contrast" color={theme.white}>
                {buttonText}
              </CustomTypography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomModal;
