import { createUseStyles } from "react-jss";
import { breakpoints } from "../../../../constants/constraints";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    width: "initial",

    [breakpoints.max.smd]: {
      display: "none",
    },
  },
  locationButton: {
    borderRadius: 4,

    "&:hover": {
      backgroundColor: theme.background.secondary,
      transition: "background-color 0.2s ease",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.hover,
      transition: "background-color 0.15s ease",
      cursor: "pointer",
    },
    "&:focus": {
      outline: `none`,
    },
  },
  targetButton: {
    backgroundColor: theme.background.primary,
    borderRadius: 4,
  },
  collapse: {
    transition: "width .3s ease",
    overflow: "hidden",
  },
  wrapper: {
    display: "flex",
    flexDirection: "horizontal",
    alignItems: "center",
    width: 152,
    height: 40,
  },
  values: {
    backgroundColor: theme.background.secondary,
    width: 112,
    height: "100%",
    display: "flex",
    flexDirection: "horizontal",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
