import * as React from "react";

function SvgLayers(props) {
  return (
    <svg
      width={16}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.217.054a.462.462 0 00-.434 0L.859 3.747a.462.462 0 000 .814L3.558 6l-2.7 1.44a.462.462 0 000 .813l6.924 3.693a.461.461 0 00.434 0l6.923-3.693a.461.461 0 000-.814L12.443 6l2.697-1.44a.462.462 0 000-.813L8.217.054zm3.245 6.47l2.48 1.322L8 11.016l-5.943-3.17 2.481-1.323 3.245 1.73a.462.462 0 00.434 0l3.245-1.73zM8 7.322l-5.943-3.17L8 .986l5.943 3.169L8 7.323z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgLayers;
