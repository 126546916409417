/* eslint-disable prefer-destructuring */
import { useTheme } from "react-jss";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MapLegend from "../../../components/Dashboard/MapView/Legend";
import FiltersContext from "../../../contexts/filtering";
import useStyles from "../styles";
import MapContext from "../../../contexts/mapping";
import api from "../../../services/api";

/**
 * Handle the legend colors.
 */
function Legend() {
  const {
    values: { analysis, years },
    options: { analysisOptions },
  } = useContext(FiltersContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { layers } = useContext(MapContext);
  const [intermittentYear, setIntermittentYear] = useState();
  const [irrigationYear, setIrrigationYear] = useState();

  useEffect(() => {
    let isSubscribed = true;

    if (analysis === analysisOptions.surface?.key) {
      const startYear = years[0];
      let endYear = years[0];

      if (years.length > 1) {
        endYear = years[1];
      }

      api
        .get(`/surface/intermittent/map/${startYear}/${endYear}`)
        .then(({ data }) => {
          if (isSubscribed) {
            setIntermittentYear(data.year);
          }
        });

      let endYearForIrrigation = years[0];

      if (years.length > 1) {
        endYearForIrrigation = years[1];
      }

      api
        .get(`/surface/irrigation/map/${endYearForIrrigation}`)
        .then(({ data }) => {
          if (isSubscribed) {
            setIrrigationYear(data.year);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [analysis, years]);

  if (analysis === analysisOptions.transition.key) {
    return <></>;
  }

  if (
    analysis ===
    (analysisOptions.waterClassification &&
      analysisOptions.waterClassification?.key)
  ) {
    return (
      <MapLegend
        isClassification
        iconColors={[
          theme.waterClassification.natural,
          theme.waterClassification.anthropic,
          theme.waterClassification.hydroelectric,
          theme.waterClassification.mining,
        ]}
        legendColors={[]}
        description={[
          "natural",
          "anthropic",
          "hydroelectric",
          "mining",
          "aquaculture",
        ]}
      />
    );
  }

  if (analysis === analysisOptions.trend.key) {
    return (
      <MapLegend
        iconColors={[
          theme.trend.positiveResidue,
          theme.trend.positiveResidue,
          "#fff",
          theme.trend.negativeResidue,
        ]}
        legendColors={[
          theme.trend.negativeResidue,
          "#fff",
          theme.trend.positiveResidue,
        ]}
        description={[
          t("dashboard.map.legend.trend.decreasing"),
          t("dashboard.map.legend.trend.increasing"),
        ]}
      />
    );
  }

  return (
    <MapLegend
      iconColors={["#00ffff", "#008eff", "#0037ff", "#0000a3"]}
      legendColors={["#00ffff", "#008eff", "#0037ff", "#0000a3"]}
      description={[">0%", "50%", "100%"]}
      tooltip={t("dashboard.map.legend.tooltip.water")}
      title1={t("dashboard.map.legend.water.title")}
    >
      <div className={classes.legendContainer}>
        {layers?.irrigation?.enabled && (
          <>
            <div className={classes.legendSeparator} />
            <div className={classes.legendTitle}>
              {t("dashboard.map.legend.water.irrigation")} ({irrigationYear})
            </div>
            <div className={classes.legendItem}>
              <div
                className={classes.legendSquare}
                style={{ backgroundColor: "#A35BFF" }}
              />
              <div className={classes.legendText}>
                {t("dashboard.map.legend.water.centralPivot")}
              </div>
            </div>
            <div className={classes.legendItem}>
              <div
                className={classes.legendSquare}
                style={{ backgroundColor: "#4BDDFE" }}
              />
              <div className={classes.legendText}>
                {t("dashboard.map.legend.water.otherSystems")}
              </div>
            </div>
            <div className={classes.legendItem}>
              <div
                className={classes.legendSquare}
                style={{ backgroundColor: "#FF5BF8" }}
              />
              <div className={classes.legendText}>
                {t("dashboard.map.legend.water.flood")}
              </div>
            </div>
          </>
        )}
        {layers?.intermittentWater?.enabled && (
          <>
            <div className={classes.legendSeparator} />
            <div className={classes.legendTitle}>
              {t("dashboard.map.legend.water.intermittentWater")} (
              {intermittentYear})
            </div>
            <div className={classes.legendItem}>
              <div
                className={classes.legendSquare}
                style={{ backgroundColor: "#45c2a5" }}
              />
              <div className={classes.legendText}>
                {t("dashboard.map.legend.water.intermittentWater")}
              </div>
            </div>
          </>
        )}
      </div>
    </MapLegend>
  );
}

export default Legend;
