/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import { useTheme } from "react-jss";
import React, { useContext, useEffect, useState } from "react";
import bbox from "@turf/bbox";
import { FlyToInterpolator, WebMercatorViewport } from "react-map-gl";
import { easeCubic } from "d3-ease";
import FiltersContext from "../../../contexts/filtering";
import MapContext from "../../../contexts/mapping";
import useTerritoryHistory from "../../../hooks/territoryHistory";
import { isTouchDevice } from "../../../constants/utils";
import CustomPopup from "../../../components/Dashboard/MapView/CustomPopup";
import api from "../../../services/api";
import conflictsData from "../../../assets/json/conflicts.json";
import glacierPinsData from "../../../assets/json/glacier_centroids.json";

/**
 * Export the map layer.
 */
function Map() {
  const { mapRef, mapLoaded } = useContext(MapContext);
  const {
    options: { territoriesOptions, analysisOptions, initiativeOptions },
    values: {
      analysis,
      timeMode,
      years,
      months,
      territory,
      grouping,
      hierarchy,
      statisticsOpened,
      dataType,
      initiative,
    },
    setters: { setTerritoriesOptions, setTerritory },
  } = useContext(FiltersContext);
  const {
    setOnClick,
    setOnHover,
    baseMap,
    opacity,
    setInfoContent,
    viewport,
    animation,
    setViewport,
    layers,
    transparentBackground,
  } = useContext(MapContext);
  const theme = useTheme();
  const territoryHistory = useTerritoryHistory(
    hierarchy,
    territory,
    grouping,
    dataType,
    initiative,
    initiativeOptions
  );
  const rasterConfig = {
    type: "raster",
    tileSize: 256,
    paint: {
      "raster-resampling": "nearest",
    },
  };
  const [popup, setPopup] = useState();
  const [surfaceUrl, setSurfaceUrl] = useState();
  const [transitionUrl, setTransitionUrl] = useState();
  const [
    fetchedInititiveTerritoriesRaster,
    setFetchedInititiveTerritoriesRaster,
  ] = useState();

  /**
   * Gets the surface raster url
   */
  useEffect(() => {
    let isSubscribed = true;

    const startYear = years[0];
    let endYear = years[0];

    if (years.length > 1) {
      endYear = years[1];
    }

    if (analysis === analysisOptions.surface?.key) {
      api
        .get(`/surface/map/annual/${startYear}/${endYear}`, {
          params: { dataType },
        })
        .then(({ data: { url } }) => {
          if (isSubscribed) {
            setSurfaceUrl(url);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [years, dataType, analysis]);

  /**
   * Gets the transition raster url
   */
  useEffect(() => {
    let isSubscribed = true;

    const startYear = years[0];
    let endYear = years[0];

    if (years.length > 1) {
      endYear = years[1];
    }

    if (analysis === analysisOptions.transition?.key) {
      api
        .get(`/transitions/map/annual/${startYear}/${endYear}`, {
          params: { dataType },
        })
        .then(({ data: { url } }) => {
          if (isSubscribed) {
            setTransitionUrl(url);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [years, dataType, analysis]);

  function updateRasterLayer() {
    const map = mapRef.current.getMap();

    if (map.getLayer("raster-layer")) {
      map.removeLayer("raster-layer");
    }
    if (map.getSource("raster")) {
      map.removeSource("raster");
    }

    if (analysis === analysisOptions.surface?.key) {
      if (surfaceUrl) {
        map.addSource("raster", {
          ...rasterConfig,
          tiles: [surfaceUrl],
        });
        map.addLayer(
          {
            id: "raster-layer",
            source: "raster",
            type: "raster",
            "tile-size": 256,
            paint: {
              "raster-resampling": "nearest",
            },
          },
          "road-street"
        );
      }
    } else if (analysis === analysisOptions.transition?.key) {
      if (transitionUrl) {
        map.addSource("raster", {
          ...rasterConfig,
          tiles: [transitionUrl],
        });
        map.addLayer(
          {
            id: "raster-layer",
            source: "raster",
            type: "raster",
            "tile-size": 256,
            paint: {
              "raster-resampling": "nearest",
            },
          },
          "road-street"
        );
      }
    }
  }

  /**
   * Handle the map layer visualization.
   */
  useEffect(() => {
    if (mapLoaded) {
      updateRasterLayer();
    }
  }, [
    mapLoaded,
    analysis,
    timeMode,
    years,
    months,
    theme,
    surfaceUrl,
    transitionUrl,
  ]);

  /**
   * Handle opacity change
   */
  useEffect(() => {
    const map = mapRef.current.getMap();

    if (map.getLayer("raster-layer")) {
      map.setPaintProperty("raster-layer", "raster-opacity", opacity / 100);
    }
  }, [opacity]);

  /**
   * Handle the style loading.
   */
  useEffect(() => {
    const map = mapRef?.current?.getMap();

    if (mapLoaded) {
      map.on("style.load", updateRasterLayer);
    }

    return () => {
      map.off("style.load", updateRasterLayer);
    };
  }, [baseMap, analysis, theme]);

  /**
   * This function enter inside a territory without broke the autocomplete logic.
   */
  const enterTerritory = (feature) => {
    if (feature && territoriesOptions) {
      const index = territoriesOptions.findIndex(
        (element) => element.type === grouping
      );

      const newTerritory = {
        code: feature.properties.code,
        name: feature.properties.name,
        type: feature.properties.territoryType,
      };

      if (index >= 0) {
        if (
          !territoriesOptions.find(
            (element) => element.code === feature.properties.code
          )
        ) {
          territoriesOptions.splice(index, 0, newTerritory);
        }
        setTerritoriesOptions(territoriesOptions);
      } else {
        setTerritoriesOptions([newTerritory, ...territoriesOptions]);
      }

      setTerritory(newTerritory);
    }
  };

  /**
   * This function pull the territory without broke the autocomplete logic.
   */
  const pullTerritory = () => {
    if (territoryHistory && territoryHistory.length > 1) {
      const lastTerritory = territoryHistory[territoryHistory.length - 2];

      if (lastTerritory && territoriesOptions) {
        const index = territoriesOptions.findIndex(
          (element) => element.type === grouping
        );
        const newTerritory = { ...lastTerritory };

        if (index >= 0) {
          if (
            !territoriesOptions.find(
              (element) => element.code === lastTerritory.code
            )
          ) {
            territoriesOptions.splice(index, 0, newTerritory);
          }
          setTerritoriesOptions(territoriesOptions);
        } else {
          setTerritoriesOptions([newTerritory, ...territoriesOptions]);
        }

        setTerritory(newTerritory);
      }
    }
  };

  /**
   * Set the map click.
   */
  useEffect(() => {
    setOnClick(() => (event) => {
      if (event) {
        const { features } = event;
        const feature = features && features.find((f) => f.layer.id === "data");

        if (isTouchDevice()) {
          if (feature) {
            setPopup(
              <CustomPopup
                feature={feature}
                lng={event.lngLat[0]}
                lat={event.lngLat[1]}
                closePopup={() => setPopup(null)}
                accessTerritory={() => {
                  enterTerritory(feature);
                  setPopup(null);
                }}
              />
            );
          } else {
            setPopup(null);
            pullTerritory();
          }
        } else if (feature) {
          enterTerritory(feature);

          /* const map = mapRef.current.getMap();

          const [minLng, minLat, maxLng, maxLat] = bbox(feature);

          map.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: 40, duration: 1000 }
          ); */
        } else {
          pullTerritory();
        }
      }
    });
  }, [grouping]);

  useEffect(() => {
    setOnHover(() => (event) => {
      if (event) {
        const { features } = event;
        const feature = features && features.find((f) => f.layer.id === "data");

        if (feature) {
          if (!isTouchDevice()) {
            setInfoContent(feature);
          }
        } else {
          setInfoContent(null);
        }
      }
    });
  }, [grouping]);

  /**
   * A layer used to make a focus on map.
   */
  useEffect(() => {
    if (mapLoaded) {
      const map = mapRef.current.getMap();
      if (map.getLayer("vector-layer-focus")) {
        map.removeLayer("vector-layer-focus");
      }
      if (map.getSource("vector_focus")) {
        map.removeSource("vector_focus");
      }

      map.addSource("vector_focus", {
        type: "vector",
        tiles: [
          `${process.env.REACT_APP_API_URL}territories/shape/tiles/${territory.type}/${territory.code}/${grouping}/{z}/{x}/{y}.mvt`,
        ],
      });

      let paintProperties = {
        "fill-opacity": 1,
        "fill-color": theme.background.primary,
        "fill-outline-color": "transparent",
      };

      if (transparentBackground) {
        paintProperties = {
          "fill-opacity":
            baseMap === "default"
              ? theme.mode === "light"
                ? 0.8
                : 0.7
              : theme.mode === "light"
              ? 0.4
              : 0.6,
          "fill-color": baseMap === "default" ? theme.map.water : "black",
          "fill-outline-color": "transparent",
        };
      }

      map.addLayer(
        {
          id: "vector-layer-focus",
          source: "vector_focus",
          type: "fill",
          "source-layer": "default",
          minzoom: 0,
          maxzoom: 22,
          paint: paintProperties,
        }
        // "road-label"
      );
    }
  }, [mapLoaded, territory, grouping, theme, baseMap, transparentBackground]);

  /**
   * A layer used to create ice pins.
   */
  useEffect(() => {
    if (mapLoaded) {
      const map = mapRef.current.getMap();
      if (map.getLayer("ice-pins")) {
        map.removeLayer("ice-pins");
      }
      if (map.getLayer("ice-pins-overline")) {
        map.removeLayer("ice-pins-overline");
      }
      if (map.getSource("ice_pins_source")) {
        map.removeSource("ice_pins_source");
      }

      map.addSource("ice_pins_source", {
        type: "geojson",
        data: glacierPinsData,
      });
      map.addLayer({
        id: "ice-pins-overline",
        source: "ice_pins_source",
        type: "circle",
        paint: {
          "circle-opacity": [
            "interpolate",
            ["exponential", 0.5],
            ["zoom"],
            7,
            1,
            12,
            0,
          ],
          "circle-radius": 2.5,
          "circle-color": baseMap === "default" ? "black" : "black",
        },
      });
      map.addLayer({
        id: "ice-pins",
        source: "ice_pins_source",
        type: "circle",
        paint: {
          "circle-opacity": [
            "interpolate",
            ["exponential", 0.5],
            ["zoom"],
            7,
            1,
            12,
            0,
          ],
          "circle-radius": 2,
          "circle-color": baseMap === "default" ? theme.primary : theme.primary,
        },
      });
    }
  }, [mapLoaded, territory, grouping, theme, baseMap]);

  /**
   * A layer to create feature lines.
   */
  useEffect(() => {
    if (mapLoaded) {
      const map = mapRef.current.getMap();
      if (map.getLayer("conflict-layer-shapes")) {
        map.removeLayer("conflict-layer-shapes");
      }
      if (map.getSource("conflict_shapes")) {
        map.removeSource("conflict_shapes");
      }
      map.addSource("conflict_shapes", {
        type: "geojson",
        data: conflictsData,
      });
      map.addLayer(
        {
          id: "conflict-layer-shapes",
          source: "conflict_shapes",
          type: "line",
          minzoom: 0,
          maxzoom: 22,
          layout: {
            "line-join": "round",
            "line-cap": "butt",
          },
          paint: {
            "line-color": baseMap === "default" ? theme.map.border : "black",
            "line-opacity":
              baseMap === "default" ? (theme.mode === "light" ? 1 : 0.5) : 1,
            "line-width": baseMap === "default" ? 1 : 1.3,
            "line-dasharray": baseMap === "default" ? [2, 4] : [1, 2],
          },
        },
        map.getLayer("vector-layer-focus") ? "vector-layer-focus" : "road-label"
      );

      if (map.getLayer("vector-layer-shapes")) {
        map.removeLayer("vector-layer-shapes");
      }
      if (map.getSource("vector_shapes")) {
        map.removeSource("vector_shapes");
      }

      map.addSource("vector_shapes", {
        type: "vector",
        tiles: [
          `${process.env.REACT_APP_API_URL}territories/grouping/tiles/${territory.type}/${territory.code}/${grouping}/{z}/{x}/{y}.mvt`,
        ],
      });
      map.addLayer(
        {
          id: "vector-layer-shapes",
          source: "vector_shapes",
          type: "line",
          "source-layer": "default",
          minzoom: 0,
          maxzoom: 22,
          layout: {
            "line-join": "round",
            "line-cap": "butt",
          },
          paint: {
            "line-color": baseMap === "default" ? theme.map.border : "black",
            "line-opacity":
              baseMap === "default" ? (theme.mode === "light" ? 1 : 0.5) : 1,
            "line-width": baseMap === "default" ? 1 : 1.3,
            "line-dasharray": baseMap === "default" ? [2, 4] : [1, 2],
          },
        },
        "road-label"
      );
    }
  }, [mapLoaded, territory, grouping, theme, baseMap]);

  /**
   * A layer to create features events.
   */
  useEffect(() => {
    if (mapLoaded) {
      const map = mapRef.current.getMap();
      if (map.getLayer("data")) {
        map.removeLayer("data");
      }
      if (map.getSource("features")) {
        map.removeSource("features");
      }

      map.addSource("features", {
        type: "vector",
        tiles: [
          `${process.env.REACT_APP_API_URL}territories/grouping/tiles/${territory.type}/${territory.code}/${grouping}/{z}/{x}/{y}.mvt`,
        ],
      });
      map.addLayer({
        id: "data",
        source: "features",
        type: "fill",
        paint: {
          "fill-opacity": 0,
        },
        "source-layer": "default",
      });
    }
  }, [mapLoaded, territory, grouping]);

  /*
    First load
  */
  useEffect(() => {
    let isSubscribed = true;

    if (mapLoaded) {
      api
        .get(`/territories/bounds/${territory.type}/${territory.code}`)
        .then(({ data }) => {
          if (isSubscribed) {
            const [minLng, minLat, maxLng, maxLat] = bbox(data);
            const vp = new WebMercatorViewport(viewport);

            const { longitude, latitude, zoom } = vp.fitBounds(
              [
                [minLng, minLat],
                [maxLng, maxLat],
              ],
              {
                padding: {
                  top: 100,
                  bottom: 130,
                  left: !isTouchDevice() && statisticsOpened ? 0 : 100,
                  right: !isTouchDevice() && statisticsOpened ? 250 : 100,
                },
                duration: 300,
              }
            );

            if (isSubscribed && !animation) {
              setViewport((v) => ({
                ...v,
                latitude,
                longitude,
                zoom,
                transitionInterpolator: new FlyToInterpolator(),
                transitionDuration: 300,
                transitionEasing: easeCubic,
              }));
            }
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [mapLoaded, territory, setViewport]);

  useEffect(() => {
    let isSubscribed = true;
    const map = mapRef?.current?.getMap();

    async function fetchItems() {
      const items = {};
      const promisesToAwait = [];

      if (fetchedInititiveTerritoriesRaster) {
        Object.keys(fetchedInititiveTerritoriesRaster).forEach((key) => {
          if (map?.getLayer(`initiative-territory-layer-${key}`)) {
            map?.removeLayer(`initiative-territory-layer-${key}`);
          }
          if (map?.getSource(`initiative-territory-source-${key}`)) {
            map?.removeSource(`initiative-territory-source-${key}`);
          }
        });
      }

      Object.keys(layers.initiative).forEach((key) => {
        if (layers.initiative[key].enabled) {
          promisesToAwait.push(
            api
              .get(`/territories/${key}/map`, {
                params: {
                  color: theme.map.selection.stroke,
                },
              })
              .then(({ data }) => {
                items[key] = data;
              })
          );
        }
      });

      await Promise.all(promisesToAwait);
      if (isSubscribed) setFetchedInititiveTerritoriesRaster(items);
    }

    if (layers.initiative) {
      fetchItems();
    }

    return () => {
      isSubscribed = false;
    };
  }, [layers.initiative, theme]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();

    function addLayer() {
      if (fetchedInititiveTerritoriesRaster) {
        Object.keys(fetchedInititiveTerritoriesRaster).forEach((key) => {
          if (map?.getLayer(`initiative-territory-layer-${key}`)) {
            map?.removeLayer(`initiative-territory-layer-${key}`);
          }
          if (map?.getSource(`initiative-territory-source-${key}`)) {
            map?.removeSource(`initiative-territory-source-${key}`);
          }

          map?.addSource(`initiative-territory-source-${key}`, {
            type: "raster",
            tiles: [fetchedInititiveTerritoriesRaster[key].url],
            tileSize: 256,
          });

          map?.addLayer(
            {
              id: `initiative-territory-layer-${key}`,
              source: `initiative-territory-source-${key}`,
              type: "raster",
              paint: {
                "raster-opacity": opacity / 100,
              },
            },
            "road-street"
          );
        });
      }
    }

    if (map) {
      addLayer();
      map.on("style.load", addLayer);
    }

    return () => {
      if (map) {
        map.off("style.load", addLayer);
      }
    };
  }, [fetchedInititiveTerritoriesRaster, opacity]);

  return <>{popup}</>;
}

export default Map;
