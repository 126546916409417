/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Button, Grow, Slider } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import useStylesParent from "../styles";
import SvgCalendar from "../../../Icons/Calendar";
import FiltersContext from "../../../../contexts/filtering";
import CustomTypography from "../../../CustomTypography";
import SvgDownArrow from "../../../Icons/DownArrow";
import TimeSelection from "./TimeSelection";
import Thumb from "./Thumb";
import CustomModal from "../../Modal";

/**
 * This function renders a timeline component to map.
 * @returns The timeline component.
 */
function Timeline({ values, availableTimeOptions }) {
  Timeline.defaultProps = {
    values: [],
  };

  Timeline.propTypes = {
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape(),
        PropTypes.string,
      ])
    ),
    availableTimeOptions: PropTypes.string.isRequired,
  };

  const classesParent = useStylesParent();
  const {
    options: { timeOptions, analysisOptions },
    values: { timeMode, initialTime, endTime, analysis },
    setters: { setInitialTime, setEndTime },
    loading: { filtersUrlLoaded },
  } = useContext(FiltersContext);

  const [value, setValue] = useState([0, values.length - 1]);
  const { t } = useTranslation();
  const [marks, setMarks] = useState([]);
  const [firstOpen, setFirstOpen] = useState(false);
  const [secondOpen, setSecondOpen] = useState(false);
  const classes = useStyles();
  const [blockUpdate, setBlockUpdate] = useState(false);
  const [betaMonthAlert, setBetaMonthAlert] = useState(false);

  /**
   * Generate slider marks.
   */
  useEffect(() => {
    const marksAux = [];

    values.forEach((v, index) => {
      if (timeMode === "annual") {
        marksAux.push({ value: index });
      } else if (index % 12 === 0) {
        marksAux.push({ value: index });
      }
    });

    setMarks(marksAux);
  }, [values, timeMode]);

  /**
   * Get initial values.
   */
  useEffect(() => {
    if (filtersUrlLoaded) {
      const initialIndex = values.findIndex((element) => {
        if (timeMode === "annual") {
          return element === parseInt(initialTime, 10);
        }

        return element === initialTime;
      });

      const endIndex = values.findIndex((element) => {
        if (timeMode === "annual") {
          return element === parseInt(endTime, 10);
        }

        return element === endTime;
      });

      if (initialIndex > -1 && endIndex > -1)
        setValue([initialIndex, endIndex]);
    }
  }, [filtersUrlLoaded, initialTime, endTime]);

  /**
   * Handle the first calendar button opening.
   */
  function handleFirstOpen() {
    setFirstOpen(!firstOpen);
    setSecondOpen(false);
  }

  /**
   * Handle the second calendar button opening.
   */
  function handleSecondOpen() {
    setSecondOpen(!secondOpen);
    setFirstOpen(false);
  }

  /**
   * Close the first and second calendar buttons.
   */
  function handleClose() {
    setSecondOpen(false);
    setFirstOpen(false);
  }

  /**
   * Updates the time value.
   */
  function updateTime(newValue) {
    if (blockUpdate) {
      setBlockUpdate(false);
    } else {
      setInitialTime(values[newValue[0]].toString());
      setEndTime(values[newValue[1]].toString());
    }
  }

  /**
   * Handle the slider changing value.
   */
  function handleValue(newValue) {
    if (
      analysis === analysisOptions.waterClassification?.key ||
      timeMode !== "annual"
    ) {
      if (value[0] !== newValue[0]) {
        setValue([newValue[0], newValue[0]]);
      } else {
        setValue([newValue[1], newValue[1]]);
      }
    } else if (analysis === analysisOptions.surface?.key) {
      setValue(newValue);
    } else if (analysis === analysisOptions.transition?.key) {
      if (newValue[0] !== value[0] && newValue[0] !== value[1]) {
        setValue(newValue);
      } else if (newValue[1] !== value[0] && newValue[1] !== value[1]) {
        setValue(newValue);
      }
    } else if (analysis === analysisOptions.trend?.key) {
      if (timeMode === "annual") {
        if (newValue[1] - newValue[0] >= 4) {
          setValue(newValue);
        } else {
          setBlockUpdate(true);
        }
      } else if (newValue[1] - newValue[0] >= 59) {
        setValue(newValue);
      } else {
        setBlockUpdate(true);
      }
    }
  }

  /**
   * This function handles the initial time changing.
   * When the user selects a initial time bigger than the last time
   * the end time is changed too.
   */
  function handleInitialTime(newTime) {
    if (analysis === analysisOptions.surface.key) {
      if (newTime.includes("-")) {
        const initialYear = newTime.substring(2).replace("-", "");
        const initialMonth = newTime.substring(0, 2).replace("-", "");

        // Commented sections are the logic of multiple months
        /* 
        let endYear = endTime;
        let endMonth = 1;

        if (endTime.includes("-")) {
          endYear = endTime.substring(2).replace("-", "");
          endMonth = endTime.substring(0, 2).replace("-", "");
        }

        if (initialYear > endYear) {
          endYear = initialYear;
        }
        if (initialMonth > endMonth) {
          endMonth = initialMonth;
        }

        setInitialTime(`${initialMonth}-${initialYear}`);
        setEndTime(`${endMonth}-${endYear}`); 
        */
        setInitialTime(`${initialMonth}-${initialYear}`);
        setEndTime(`${initialMonth}-${initialYear}`);
      } else {
        const initialYear = newTime;
        let endYear = endTime;

        if (endTime.includes("-")) {
          endYear = endTime.substring(2).replace("-", "");
        }

        if (!timeOptions.years.find((element) => endYear === element)) {
          endYear = initialYear;
        }

        if (initialYear > endYear) {
          endYear = initialYear;
        }

        setInitialTime(initialYear);
        setEndTime(endYear);
      }
    } else if (analysis === analysisOptions.transition?.key) {
      if (newTime.includes("-")) {
        const initialYear = newTime.substring(2).replace("-", "");
        const initialMonth = newTime.substring(0, 2).replace("-", "");
        let endYear = endTime;
        let endMonth = 1;

        if (endTime.includes("-")) {
          endYear = endTime.substring(2).replace("-", "");
          endMonth = endTime.substring(0, 2).replace("-", "");
        }

        if (initialYear > endYear) {
          endYear = initialYear;
        }
        if (initialMonth > endMonth) {
          endMonth = initialMonth;
        }

        setInitialTime(`${initialMonth}-${initialYear}`);
        setEndTime(`${endMonth}-${endYear}`);
      } else {
        const initialYear = newTime;
        let endYear = endTime;

        if (endTime.includes("-")) {
          endYear = endTime.substring(2).replace("-", "");
        }

        if (initialYear >= endYear) {
          endYear = parseInt(initialYear, 10) + 1;
        }

        setInitialTime(initialYear.toString());
        setEndTime(endYear.toString());
      }
    } else if (analysis === analysisOptions.trend?.key) {
      if (newTime.includes("-")) {
        /** No implemented */
      } else {
        const initialYear = newTime;
        let endYear = endTime;

        if (endTime.includes("-")) {
          endYear = endTime.substring(2).replace("-", "");
        }

        if (endYear - initialYear < 4) {
          endYear = parseInt(initialYear, 10) + 4;
        }

        setInitialTime(initialYear.toString());
        setEndTime(endYear.toString());
      }
    } else if (analysis === analysisOptions.waterClassification?.key) {
      setInitialTime(newTime);
      setEndTime(newTime);
    }
  }

  /**
   * This function handles the end time changing.
   * When the user selects a last time minor than the initial time
   * the initial time is changed too.
   */
  function handleEndTime(newTime) {
    if (analysis === analysisOptions.surface?.key) {
      if (newTime.includes("-")) {
        // Commented sections are the logic of multiple months
        /* 
        const initialYear = initialTime;
        const initialMonth = 1;
        
        if (initialTime.includes("-")) {
          initialYear = initialTime.substring(2).replace("-", "");
          initialMonth = initialTime.substring(0, 2).replace("-", "");
        }
        */

        const endYear = newTime.substring(2).replace("-", "");
        const endMonth = newTime.substring(0, 2).replace("-", "");

        /*
        if (endYear < initialYear) {
          initialYear = endYear;
        }
        if (endMonth < initialMonth) {
          initialMonth = endMonth;
        }

        setInitialTime(`${initialMonth}-${initialYear}`);
        setEndTime(`${endMonth}-${endYear}`); 
        */

        setInitialTime(`${endMonth}-${endYear}`);
        setEndTime(`${endMonth}-${endYear}`);
      } else {
        let initialYear = initialTime;
        const endYear = newTime;

        if (initialTime.includes("-")) {
          initialYear = initialTime.substring(2).replace("-", "");
        }

        if (endYear < initialYear) {
          initialYear = endYear;
        }

        setInitialTime(initialYear);
        setEndTime(endYear);
      }
    } else if (analysis === analysisOptions.transition?.key) {
      if (newTime.includes("-")) {
        let initialYear = initialTime;
        let initialMonth = 1;

        if (initialTime.includes("-")) {
          initialYear = initialTime.substring(2).replace("-", "");
          initialMonth = initialTime.substring(0, 2).replace("-", "");
        }

        const endYear = newTime.substring(2).replace("-", "");
        const endMonth = newTime.substring(0, 2).replace("-", "");

        if (endYear < initialYear) {
          initialYear = endYear;
        }
        if (endMonth < initialMonth) {
          initialMonth = endMonth;
        }

        setInitialTime(`${initialMonth}-${initialYear}`);
        setEndTime(`${endMonth}-${endYear}`);
      } else {
        let initialYear = initialTime;
        const endYear = newTime;

        if (initialTime.includes("-")) {
          initialYear = initialTime.substring(2).replace("-", "");
        }

        if (endYear <= initialYear) {
          initialYear = parseInt(endYear, 10) - 1;
        }

        setInitialTime(initialYear.toString());
        setEndTime(endYear.toString());
      }
    } else if (analysis === analysisOptions.trend?.key) {
      if (newTime.includes("-")) {
        /** No implemented */
      } else {
        let initialYear = initialTime;
        const endYear = newTime;

        if (initialTime.includes("-")) {
          initialYear = initialTime.substring(2).replace("-", "");
        }

        if (endYear - initialYear < 4) {
          initialYear = parseInt(endYear, 10) - 4;
        }

        setInitialTime(initialYear.toString());
        setEndTime(endYear.toString());
      }
    } else if (analysis === analysisOptions.waterClassification?.key) {
      setInitialTime(newTime);
      setEndTime(newTime);
    }
  }

  useEffect(() => {
    if (timeMode === "monthly") {
      setBetaMonthAlert(true);
    }
  }, [timeMode]);

  return (
    <div
      className={classNames(classesParent.navigationWrapper, classes.container)}
    >
      {(firstOpen || secondOpen) && (
        <div
          role="button"
          tabIndex={-1}
          onClick={handleClose}
          onKeyDown={handleClose}
          className={classes.closeContainer}
        />
      )}
      <Grow in={firstOpen} unmountOnExit>
        <TimeSelection
          onClose={handleClose}
          time={initialTime}
          setTime={handleInitialTime}
          not={endTime}
          isFirst
          availableOptions={availableTimeOptions}
        />
      </Grow>
      <Grow in={secondOpen} unmountOnExit>
        <TimeSelection
          onClose={handleClose}
          time={endTime}
          setTime={handleEndTime}
          not={initialTime}
          availableOptions={availableTimeOptions}
        />
      </Grow>
      <div className={classes.calendarContainer}>
        <SvgCalendar className={classes.calendarIcon} />
        <Button
          className={
            timeMode === "annual"
              ? classes.calendarButtonAnnual
              : classes.calendarButtonMonthly
          }
          onClick={handleFirstOpen}
        >
          <CustomTypography
            variant="body"
            weight="bold"
            style={{ textTransform: "capitalize" }}
          >
            {timeMode === "annual"
              ? timeOptions.years[value[0]]
              : t("dashboard.filters.timeline.monthlyDate", {
                  date: new Date(
                    timeOptions.months[value[0]].substring(2).replace("-", ""),
                    parseInt(
                      timeOptions.months[value[0]]
                        .substring(0, 2)
                        .replace("-", ""),
                      10
                    ) - 1,
                    1
                  ), // Just the month is important here.
                }).replace(".", "")}
          </CustomTypography>
          <SvgDownArrow className={classes.downIcon} />
        </Button>
        {analysis !== analysisOptions.waterClassification?.key &&
          timeMode === "annual" && (
            <>
              <span className={classes.calendarSeparator}> - </span>
              <Button
                className={
                  timeMode === "annual"
                    ? classes.calendarButtonAnnual
                    : classes.calendarButtonMonthly
                }
                onClick={handleSecondOpen}
              >
                <CustomTypography
                  variant="body"
                  weight="bold"
                  style={{ textTransform: "capitalize" }}
                >
                  {timeMode === "annual"
                    ? timeOptions.years[value[1]]
                    : t("dashboard.filters.timeline.monthlyDate", {
                        date: new Date(
                          timeOptions.months[value[1]]
                            .substring(2)
                            .replace("-", ""),
                          parseInt(
                            timeOptions.months[value[1]]
                              .substring(0, 2)
                              .replace("-", ""),
                            10
                          ) - 1,
                          1
                        ), // Just the month is important here.
                      }).replace(".", "")}
                </CustomTypography>
                <SvgDownArrow className={classes.downIcon} />
              </Button>
            </>
          )}
      </div>
      <div className={classes.separator} />
      <div className={classes.slider}>
        <Slider
          value={value}
          onChange={(e, newValue) => handleValue(newValue)}
          step={1}
          min={0}
          max={values.length - 1}
          classes={{ root: classes.sliderRoot }}
          onChangeCommitted={(e, newValue) => updateTime(newValue)}
          marks={marks}
          ThumbComponent={Thumb}
        />
      </div>
      {betaMonthAlert && (
        <CustomModal
          close={() => setBetaMonthAlert(false)}
          buttonText="OK"
          title={t("dashboard.filters.timeline.monthlyAlertTitle")}
        >
          {t("dashboard.filters.timeline.monthlyAlertText")}
        </CustomModal>
      )}
    </div>
  );
}

export default Timeline;
